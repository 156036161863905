import { createSlice } from "@reduxjs/toolkit";
import "./initialStateTypes";

/**
 * @typedef {object} InitialState
 * @property {BusinessInfoState} info
 * @property {boolean} dataFetched
 */

/**
 * @type {InitialState}
 */

const initialState = {
  info: {
    companyName: "",
    tagLine: "",
    termsLink: "",
    privacyPolicyLink: "",
  },
  dataFetched: false,
};

const buinessInfoSlice = createSlice({
  name: "businessInfo",
  initialState,
  reducers: {
    setBusinessInfo: (state, action) => {
      state.info = action.payload;
    },
    setDataFetched: (state, action) => {
      state.dataFetched = action.payload;
    },
  },
});

export default buinessInfoSlice.reducer;
export const { setBusinessInfo, setDataFetched } = buinessInfoSlice.actions;
