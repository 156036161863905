import { useCallback, useEffect, useRef, useState } from "react";
import { setUserVerified } from "../../features/userVerifiedSlice";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { checkUserVerified } from "../../controller/AuthController";
import { useLocation, useNavigate } from "react-router";


/**
 * 
 * @returns {boolean} checkingUserVerification
 *
 * - This hooks checks if user is verified and check if the user is logged in
 */

export default function useCheckUserVerified() {
  const dispatch = useDispatch();
  const [checkingUserVerification, setCheckingUserVerification] =
    useState(false);
  const userVerified = useSelector((state) => state.userVerified.userVerified);
  const navigate = useNavigate();
  const location = useLocation();

  const userVerificationHandler = useCallback(async () => {
    setCheckingUserVerification(true);
    const res = await checkUserVerified();
    setCheckingUserVerification(false);
    if (typeof res === "boolean") {
      dispatch(setUserVerified(res));
    } else {
      localStorage.setItem("redirectPath", location.pathname);
      navigate("/login");
    }

  }, [dispatch, navigate]);



  useEffect(() => {
    if (userVerified === null) {
      userVerificationHandler();
    }


  }, [userVerificationHandler, userVerified, navigate]);

  return checkingUserVerification;
}
