/**
 * @typedef {object} ServeyQuestionModel
 * @property {number} id
 * @property {string} question
 * @property {string} description
 * @property {"BoolField" | "NumberField" | "RadioField" | "ChoiceField" | "ImageField" | "FileField"| "TextField"| "VideoField"} questionType
 * @property {string[]} options
 * @property {string | null} answer
 */

export function createQuestionObj(data) {
    /**
     * @type {ServeyQuestionModel}
     */
    const result = {
        id: data.id,
        question: data.question,
        description: data.description,
        questionType: data.question_type,
        options: data.options !== null ? data.options.split(",") : [],
        answer: data.answer
    };
    return result;
}
