import { Delete, Preview } from "@mui/icons-material";
import { Box, Button, TextField } from "@mui/material";
import { message } from "antd";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import {
    removeServeyAnswer,
    saveServeyAnswer,
} from "../../controller/serveyController";
import { handleControllerResultException } from "../../utils/dataFetchHelper";
import { HTTPStatus } from "../../utils/helperObj";
import { checkFileType } from "../../utils/validation";

/**
 * @typedef {object} Props
 * @property {import('../../model/ServeyModel').ServeyQuestionModel} question
 */

/**
 * @param {Props} props
 */
export default function QuestionFileField({ question }) {
    const componentMounted = useRef(false);
    const [file, setFile] = useState();
    const timeoutRef = useRef(null)
    const [fileUrl, setFileUrl] = useState(
        question.answer !== null ? question.answer : ""
    );
    const navigate = useNavigate();

    const handleFileUpload = useCallback(async (file) => {
        if (file) {

            const res = await saveServeyAnswer(
                question.id,
                file,
                question.questionType
            );

            if (res.statusCode !== HTTPStatus.CREATED) {
                setFileUrl("")
                setFile();
                handleControllerResultException(res, navigate);
            }
        }
    }, []);

    useEffect(() => {
        if (timeoutRef.current !== null) {
            clearTimeout(timeoutRef.current)
        }
        if (componentMounted.current) {
            timeoutRef.current = setTimeout(() => {
                handleFileUpload(file);
            }, "700");
        } else {
            componentMounted.current = true;
        }

        return () => {
            clearTimeout(timeoutRef.current);
        };
    }, [file]);

    function handleFileChanges(file) {
        if (file) {
            if (checkFileType(file.type.split("/")[1], question.questionType)) {
                setFileUrl(URL.createObjectURL(file));
                setFile(file);
            } else {
                message.error("Please provide correct file");
            }
        } else {
            setFile(file);
        }
    }

    async function handleRemoveFile() {

        const res = await removeServeyAnswer(question.id);

        if (res.statusCode === HTTPStatus.SUCCESS) {
            message.success(res.successMsg);
            setFile()
            setFileUrl("");
        } else {
            handleControllerResultException(res, navigate)
        }
    }

    return (
        <Box>
            <TextField
                type="file"
                onChange={(e) => handleFileChanges(e.target.files[0])}
                fullWidth

            />

            {fileUrl !== "" && question.questionType === "ImageField" ? (
                <Box sx={{ padding: "5px", display: "inline-block", marginTop: "10px" }}>
                    <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                        <button onClick={handleRemoveFile} style={{ color: "red", transform: "scale(1.5)", margin: "0px 10px" }}>
                            &times;
                        </button>
                    </Box>
                    <img
                        src={fileUrl}
                        alt="file"
                        height={"300px"}
                        width={"300px"}
                        style={{
                            objectFit: "contain",

                            borderRadius: "5px",
                        }}
                    />
                </Box>
            ) : (
                <></>
            )}
            {fileUrl !== "" &&
                (question.questionType === "FileField" ||
                    question.questionType === "VideoField") ? (
                <Box display={"flex"} alignItems={"center"} marginTop={"10px"}>
                    <a href={fileUrl} target="_blank">
                        <Button variant="contained" target="_blank">
                            <Preview fontSize="small" sx={{ marginRight: "5px" }} />
                            View file
                        </Button>
                    </a>
                    <Button color="error" onClick={handleRemoveFile} >
                        <Delete fontSize="small" />
                    </Button>
                </Box>
            ) : (
                <></>
            )}
        </Box>
    );
}
