import { DoneAll } from '@mui/icons-material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { AppBar, Box, Button, FormControl, FormControlLabel, IconButton, Menu, MenuItem, Radio, RadioGroup, Toolbar } from "@mui/material";
import TextField from '@mui/material/TextField';
import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { toast } from 'react-toastify';
import { Navigation, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from 'swiper/react';
import Spinner from '../../components/Spinner';
import logo2 from "./Images/apple-icon-120x120.png";
import "./style.css";

function InterviewReview() {

    const SlideRef = useRef();
    const [data, setData] = useState([]);
    const [info, setInfo] = useState("");
    const [email, setEmail] = useState("");
    const [isLoading, setIsLoading] = useState(true);

    const [sendData, setSendData] = useState("")
    const [sendId, setSendId] = useState("")
    const [count, setCount] = useState(0)
    const [hide, setHide] = useState(false)

    const navigate = useNavigate()


    const { id } = useParams();

    function getData() {
        axios.get("survey/interview-form-detail/" + id)
            .then((res) => {
                setInfo(res.data)
                setIsLoading(false)
            }).catch((err) => {
                if (err?.response?.status === 404) {
                    navigate("/pages/not-found")
                }

            })
    }


    useEffect(() => {
        getData();

    }, [])

    const handleNext = () => {

        SlideRef.current.swiper.slideNext();

    }


    function sendAns(e) {
        e.preventDefault()
        if (email === "") {
            return toast.warning("Enter your email")
        } else if (sendData === "") {
            return toast.warning("Please give inputs to submit answers or use skip")
        }
        let formField = new FormData()

        // formField.append("answers",JSON.stringify(answers));
        formField.append("question", sendId)
        formField.append("email", email)
        formField.append(typeof sendData === "string" ? "answer" : "answer_media", sendData)
        axios.post("survey/interview-review-create", formField)
            .then((res) => {
                setSendData("")
                setSendId("")
                toast.info("Answer is submited")
                handleNext();

            }).catch((err) => {
                toast.warn(("Email " + err?.response.data?.errors?.email[0]) || err.message)

            })
    }


    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [anchorElUser, setAnchorElUser] = React.useState(null);

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };


    return (
        <div className=''>
            <div className='my-[10vh] bg-slate-100'>
                <Box sx={{ flexGrow: 1 }}>
                    <AppBar position="fixed" className="bg-white rounded-b-xl py-2 shadow" elevation={0}>
                        <Toolbar className="rounded-b-xl" >
                            {data.length > 0 ? data.map((info) => (
                                <div key={info} className="flex">
                                    <img src={info.logo} className="h-8 lg:h-10" alt="" />
                                    <div className="flex-grow">
                                        <p className="font-semibold text-base md:text-xl lg:text-2xl  ml-4 text-[#1E66B1]">
                                            {info.name}
                                        </p>
                                        <p className="text-[0rem] md:text-sm md:w-48 ml-4 text-[#72C9B9]">
                                            @MyBizmo (Biz Landing Page)
                                        </p>
                                    </div>
                                </div>
                            )) :

                                <div className="flex">
                                    <div>
                                        <img src={logo2} className="h-8 lg:h-10 w-8 lg:w-10 rounded-full" alt="" />
                                    </div>
                                    <p className="font-semibold text-base md:text-xl lg:text-2xl  ml-4 text-[#1E66B1]">
                                        HR Intelligence
                                    </p>
                                </div>
                            }

                            <div className="text-[#1E66B1]  font-semibold ml-auto">

                                <Box sx={{ flexGrow: 1, display: { xs: 'flex', sm: 'none' } }}>
                                    <IconButton
                                        size="large"
                                        aria-label="account of current user"
                                        aria-controls="menu-appbar"
                                        aria-haspopup="true"
                                        onClick={handleOpenNavMenu}
                                        color="inherit"
                                    >
                                        <KeyboardArrowDownIcon />
                                    </IconButton>
                                    <Menu
                                        id="menu-appbar"
                                        anchorEl={anchorElNav}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'left',
                                        }}
                                        keepMounted
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'left',
                                        }}
                                        open={Boolean(anchorElNav)}
                                        onClose={handleCloseNavMenu}
                                        sx={{
                                            display: { xs: 'block', sm: 'none' },
                                        }}
                                    >
                                        {/* <a href="#home"><MenuItem onClick={handleCloseNavMenu}>Home</MenuItem></a>
                                    <a href="#services"><MenuItem
                                        onClick={handleCloseNavMenu}>Services</MenuItem></a> */}
                                        <a href="/"><MenuItem onClick={handleCloseNavMenu}>Go To Home</MenuItem></a>
                                        <a href="/login"><MenuItem onClick={handleCloseNavMenu}>Sign In/ Sign Up</MenuItem></a>
                                    </Menu>
                                </Box>

                                <Box sx={{ flexGrow: 1, display: { xs: 'none', sm: 'flex' } }}>
                                    {/* <a href="#home" className="mt-2 mr-4 lg:mr-5 md:text-base lg:text-xl">
                                    Home
                                </a>
                                <a href="#services" className="mt-2 mr-4 lg:mr-5 md:text-base lg:text-xl">
                                    Services
                                </a>
                                <a href="#jobs" className="mt-2 mr-4 lg:mr-5 md:text-base lg:text-xl">
                                    Jobs
                                </a>
                                <a href="#about_hri" className="mt-2 mr-4 lg:mr-5 md:text-base lg:text-xl">
                                    About HRI
                                </a> */}
                                    <span
                                        className="text-[#1E66B1] bg-[#F2F2F2] px-2 py-1 lg:px-5 lg:py-2 md:text-base lg:text-xl rounded-full cursor-pointer hover:bg-neutral-200 md:mr-4"
                                        onClick={() => navigate(-1)}
                                    >
                                        Go to Home
                                    </span>
                                    <span
                                        className="text-[#1E66B1] bg-[#F2F2F2] px-2 py-1 lg:px-5 lg:py-2 md:text-base lg:text-xl rounded-full cursor-pointer hover:bg-neutral-200"
                                        onClick={() => navigate("/login")}
                                    >
                                        Sign In/ Sign Up
                                    </span>
                                </Box>
                            </div>
                        </Toolbar>

                    </AppBar>
                </Box>
            </div>
            <div className="col-span-1 pb-[3rem] pt-[1rem] md:py-10  bg-slate-100 px-[1rem] md:px-10">
                {isLoading ? <div><Spinner /></div>
                    :
                    <div className='bg-white rounded-xl p-[1.5rem] md:p-10'>
                        <form>

                            <div className='md:text-4xl text-2xl font-bold underline underline-offset-4'><h1>Title : {info.name}</h1></div>
                            <div className='md:text-3xl text-xl font-bold m-2'><h1>Description : {info.description}</h1></div>
                            <div className='max-w-[40rem]'>
                                {hide ? null :
                                    <><h1 className='text-xl font-bold'>Enter Your Email</h1>
                                        <TextField fullWidth label="Enter your email" onChange={(e) => setEmail(e.target.value)} />
                                    </>}
                            </div>

                            <div className='md:w-1/2'>
                                <Swiper
                                    spaceBetween={10}
                                    slidesPerView={1}
                                    // pagination={{
                                    //     type: "fraction",
                                    // }}
                                    navigation={false}
                                    // onSwiper={(swiper) => }
                                    modules={[Pagination, Navigation]}
                                    className="mySwiper h-50"
                                    ref={SlideRef}
                                    allowTouchMove={false}

                                >
                                    {
                                        info.questions && info.questions.map((data) => (
                                            <SwiperSlide>
                                                <div key={data.id} className="text-xl md:text-2xl font-bold">
                                                    <div className='text-xl font-semibold'><p>{data.description}</p></div>
                                                    {

                                                        data.question_type === "BoolField" ?
                                                            <div>
                                                                <h1>{data.question}</h1>
                                                                <FormControl   >
                                                                    {/* <FormLabel id="demo-row-radio-buttons-group-label">Select One</FormLabel> */}
                                                                    <RadioGroup
                                                                        row
                                                                        // aria-labelledby="demo-row-radio-buttons-group-label"
                                                                        name="row-radio-buttons-group"
                                                                        value={sendData}
                                                                        onChange={(e) => { setSendId(data.id); setSendData(e.target.value) }}

                                                                    >
                                                                        <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                                                                        <FormControlLabel value="No" control={<Radio />} label="No" />

                                                                    </RadioGroup>
                                                                </FormControl>
                                                            </div>
                                                            : data.question_type === "ChoiceField" ?
                                                                <div>
                                                                    <h1>{data.question}</h1>
                                                                    <div className='flex gap-4'>
                                                                        <RadioGroup
                                                                            row
                                                                            name="row-radio-buttons-group"
                                                                            value={sendData}
                                                                            onChange={(e) => { setSendId(data.id); setSendData(e.target.value) }}
                                                                        >
                                                                            {data.options.split(",").map((data) => (
                                                                                <div id={data} className=" flex">
                                                                                    <FormControlLabel aria-multiselectable value={data} control={<Radio />} label={data} />

                                                                                </div>
                                                                            ))
                                                                            }
                                                                        </RadioGroup>

                                                                    </div>


                                                                </div>
                                                                : data.question_type === "FileField" ?
                                                                    <div>
                                                                        <h1>{data.question}</h1>
                                                                        <TextField onChange={(e) => { setSendId(data.id); setSendData(e.target.files[0]) }} type="file" title='Select File' label={" "} />
                                                                    </div>
                                                                    : data.question_type === "ImageField" ?
                                                                        <div>
                                                                            <h1>{data.question}</h1>

                                                                            <TextField onChange={(e) => { setSendId(data.id); setSendData(e.target.files[0]) }} type="file" variant="outlined" label={"."} />
                                                                        </div>
                                                                        : <div className='max-w-[40rem]'>
                                                                            <h1>{data.question}</h1>
                                                                            <TextField fullWidth label="Enter your Answer" onChange={(e) => { setSendId(data.id); setSendData(e.target.value) }} />
                                                                        </div>
                                                    }
                                                </div>

                                                <div className='mt-4 flex z-50 gap-4 justify-center md:w-1/2'>
                                                    <Button variant='contained' color='warning' onClick={() => SlideRef.current.swiper.slidePrev()}>Back</Button>
                                                    <Button variant='contained' color='success' onClick={sendAns} >Submit</Button>
                                                    <Button variant='contained' color='info' onClick={handleNext}>Skip</Button>
                                                </div>
                                            </SwiperSlide>
                                        ))

                                    }
                                    <SwiperSlide

                                    >
                                        <div className='flex h-full items-center justify-center'>
                                            <DoneAll /><div onClick={() => setHide(true)} className='my-10'>Congratulation All are Submited Sucessfully</div>

                                        </div>
                                    </SwiperSlide>
                                </Swiper>


                            </div>

                        </form>

                    </div>
                }
            </div >

        </div >
    )
}

export default InterviewReview
