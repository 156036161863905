import { useCallback, useEffect, useState } from "react";
import { setBusinessInfo, setDataFetched } from "../features/BusinessInfoSlice";
import { useDispatch, useSelector } from "react-redux";
import { getBusinessInformation } from "../controller/BaseControllers";
import { ObjType } from "../utils/helperObj";
import "../features/initialStateTypes";
import { toastError } from "../utils/Notification";

export default function useBusinessInfo() {
  const [fetchingBuisnessInfo, setFetchingBuisnessInfo] = useState(false);
  const dispatch = useDispatch();
  const { dataFetched } = useSelector((state) => state.businessInfo);

  const getDetails = useCallback(async () => {
    setFetchingBuisnessInfo(true);
    const res = await getBusinessInformation();
    setFetchingBuisnessInfo(false);
    if (res.type === ObjType.businessData) {
      /**
       * @type {BusinessInfoState}
       */
      const dataObj = {
        companyName: res.companyName,
        tagLine: res.tagLine,
        termsLink: res.termsLink,
        privacyPolicyLink: res.privacyPolicyLink,
      };
      dispatch(setBusinessInfo(dataObj));
      dispatch(setDataFetched(true));
    } else {
      toastError(res.errorMsg);
    }
  }, [dispatch]);

  useEffect(() => {
    if (!dataFetched) {
      getDetails();
    }
  }, [getDetails, dataFetched]);

  return fetchingBuisnessInfo;
}
