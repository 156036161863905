import {Link, useNavigate} from "react-router-dom";
import SendIcon from "@mui/icons-material/Send";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import moment from "moment";
import {selectHeader} from '../../features/HeaderSlice';
import {useSelector} from 'react-redux';
// import Header from "../NavBar-Sidebar/Header"
import Header from "../../components/Header";
import React, {useEffect, useState} from 'react'
import axios from "axios";
import Cookies from "universal-cookie";
import Sidebar from "../../components/Sidebar";
import {toast} from "react-toastify";
import {Button, FormControl, InputLabel, MenuItem, Select, TextField} from "@mui/material";


const cookies = new Cookies();


const SendMessage = () => {

    const currentState = useSelector(selectHeader);
    const navigate = useNavigate();

    const date = new Date();
    const newDate = moment(date).format("YYYY-MM-DD");
    const [data, setData] = useState([]);
    const [header, setHeader] = useState("");
    const [describe, setDescribe] = useState("");
    const [userId, setUserId] = useState("");
    const headers = {
        Authorization: "Token " + cookies.get("token"),
    };

    const GetDetails = () => {
        axios
            .get("profile/adminemail", {
                headers: {
                    Authorization: "Token " + cookies.get("token"),
                },
            })
            .then((res) => {
                setData(res.data);
                
            })
            .catch((err) => {
                
            });
    }

    useEffect(() => {
        if (cookies.get("token")) {
            GetDetails();
        } else {
            navigate("/")
        }
    }, []);


    async function send(e) {
        e.preventDefault();

        axios
            .post(
                "profile/message/send",
                {
                    header: header,
                    message: describe,
                    read: false,
                    sender_delete: false,
                    receiver_delete: false,
                    receiver: userId,
                },
                {
                    headers: headers,
                }
            )
            .then((resp) => {
                
                navigate({
                    state: {token: resp.data.token},
                });
                navigate("/message");
                toast.success("Message sent successfully", {
                    position: toast.POSITION.TOP_CENTER,
                    setTimeout: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    style: {backgroundColor: "#1ab394", color: "white"},
                    icon: false,
                    progressStyle: {backgroundColor: "#BAE8DE"},
                });
            })
            .catch((err) => {
                
                if (userId === "") {
                    toast.error("Please fill the Recipient",{
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    style: {backgroundColor: "#FF6347", color: "white"},
                    icon: false,
                    progressStyle: {backgroundColor: "#FFB1A3"},
                });
                }else if(header === "" ){
                    toast.error("Please fill message title", {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    style: {backgroundColor: "#FF6347", color: "white"},
                    icon: false,
                    progressStyle: {backgroundColor: "#FFB1A3"},
                });
                }
                else{
                    toast.error("Please fill the message", {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    style: {backgroundColor: "#FF6347", color: "white"},
                    icon: false,
                    progressStyle: {backgroundColor: "#FFB1A3"},
                });
                }
            });
    }

    const addChange = (e) => {
        setUserId(e.target.value);
    };


    return (
        <div className="bg-default-background h-screen overflow-auto">
            <Header/>
            <Sidebar/>
            <div>
                <div className="bg-white py-4 m-4 rounded-md z-10 px-6 md:px-5">

                    <div className="flex items-center justify-between  pb-2 rounded-md z-10">
                        <Link to={"/message"}>
                            <div
                                className="text-blue-700 text-lg font-semibold flex items-center space-x-1 button_effect hover:bg-default-gray px-3 py-2 rounded-lg cursor-pointer">
                                <ArrowBackIosNewIcon style={{height: 20, fontSize: "medium"}}/>
                                <span>Back</span>
                            </div>
                        </Link>
                        <div
                            className="text-red-500 flex items-center space-x-1 button_effect hover:bg-default-gray px-3 py-2 rounded-lg cursor-pointer"
                            onClick={() => navigate("/message")}
                        >
                            <RemoveCircleOutlineIcon style={{height: 20}}/>
                            <span>Discard</span>
                        </div>
                    </div>

                    <div className="flex flex-col ml-0 md:ml-5 space-y-4 md:flex-row md:space-y-0 justify-between">

                        <div className="">
                            <div className="ml-2 font-semibold text-lg">Select Admin</div>


                            <FormControl className="mt-3">
                                <InputLabel id="demo-simple-select-label">Tag Admin to send message</InputLabel>
                                <Select
                                    className="w-56 md:w-64"
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={userId}
                                    label="Tag Admin to send message"
                                    onChange={addChange}
                                    required
                                >
                                    {data.map((user) => (
                                        <MenuItem key={user.id} value={user.id}>
                                            {user.email}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            {/*<select*/}
                            {/*    className="form-select bg-slate-100 mt-2 ml-1  appearance-none block w-60 px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"*/}
                            {/*    aria-label="Default select example"*/}
                            {/*    onChange={addChange}*/}
                            {/*>*/}
                            {/*    <option className="w-40" selected>*/}
                            {/*        Tag Admin to send message*/}
                            {/*    </option>*/}
                            {/*    {data.map((user) => (*/}
                            {/*        <option className="h-20" key={user.id} value={user.id}>*/}
                            {/*            {user.email}*/}
                            {/*        </option>*/}
                            {/*    ))}*/}
                            {/*</select>*/}
                        </div>


                        <div className="flex items-center">
                            <p className="font-semibold text-gray-500">
                                Date: <span className="text-blue-700">{newDate}</span>
                            </p>
                        </div>
                    </div>

                    <div className="space-y-5 my-4 md:px-4">

                        <div className="space-y-2 flex flex-col">
                            <span className="text-default-blue font-semibold text-lg">
                                Message
                            </span>
                            <TextField
                                    fullWidth
                                    className="bg-slate-50"
                                    label="Subject of the message"
                                    onChange={(e) => setHeader(e.target.value)}
                                />
                        </div>

                        <div className="space-y-2 flex flex-col">
                            <TextField
                                    fullWidth
                                    multiline
                                    minRows={5}
                                    label="Describe anything in message"
                                    className="bg-slate-50"
                                    onChange={(e) => setDescribe(e.target.value)}
                                />
                        </div>

                        <div className="ml-6">
                            <Button
                                variant="contained"
                                endIcon={<SendIcon/>}
                                style={{background:"#0865B6", color:"white"}}
                                onClick={send}
                            >
                                Send
                            </Button>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default SendMessage;