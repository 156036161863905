import { message } from "antd";
import HttpResponse from "../model/HttpResponseModel";
import { HTTPStatus } from "./helperObj";


export function handleExceptionMessage(err) {
  const data = err.response.data;
  if (typeof data === "object") {
    if (data["errors"]) {
      return data["errors"][Object.keys(data["errors"])[0]][0];
    } else if (data["detail"]) {
      return data["detail"]
    } else if (data["message"]) {
      return data["message"]
    } else {
      return err.response.statusText

    }
  } else {
    return err.response.statusText
  }
}


export function handleException(err) {
  switch (err.response.status) {
    case HTTPStatus.BAD_REQUEST:
      return new HttpResponse({
        statusCode: HTTPStatus.BAD_REQUEST,
        errorMsg: handleExceptionMessage(err),
      });
    case HTTPStatus.FORBIDDEN:
      return new HttpResponse({
        statusCode: HTTPStatus.FORBIDDEN,
        errorMsg: handleExceptionMessage(err)
      });
    default:
      return new HttpResponse({
        statusCode: err.response.status,
        errorMsg: handleExceptionMessage(err),
      });
  }
}



/**
 * @param {HttpResponse} exception
 * @param {any} navigate
 */
export function handleControllerResultException(exception, navigate) {
  if (exception.statusCode === 401) {
    localStorage.removeItem("userDetails");
    navigate("/login");
  } else {
    message.error(exception.errorMsg);
  }
}
