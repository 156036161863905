import { Link, useNavigate } from "react-router-dom";
import SendIcon from "@mui/icons-material/Send";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import moment from "moment";
import { selectHeader } from '../../features/HeaderSlice';
import { useSelector } from 'react-redux';
// import Header from "../NavBar-Sidebar/Header"
import Header from "../../components/Header";
import React, { useEffect, useState } from 'react'
import axios from "axios";
import Cookies from "universal-cookie";
// import {IoPersonCircleOutline} from "react-icons/io5"
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Sidebar from "../../components/Sidebar";
import { toast } from "react-toastify";
import { Button, TextField } from "@mui/material";

const cookies = new Cookies();


const SendReply = () => {

    const currentState = useSelector(selectHeader);
    const navigate = useNavigate();


    const GetDetails = () => {
        axios
            .get("hri_company/adminemail", {
                headers: {
                    Authorization: "Token " + cookies.get("token"),
                },
            })
            .then((resp) => {
                setData(resp.data);
            })
            .catch((err) => {



            });
    }

    useEffect(() => {
        if (cookies.get("token")) {
            GetDetails();
        } else {
            navigate("/")
        }
    }, []);

    const date = new Date();
    const newDate = moment(date).format("YYYY-MM-DD");
    const [header, setHeader] = useState("");
    const [data, setData] = useState([]);
    const [describe, setDescribe] = useState("");
    const headers = {
        Authorization: "Token " + cookies.get("token"),
    };

    const send = (e) => {
        e.preventDefault();

        axios
            .post(
                "profile/message/send",
                {
                    header: header,
                    message: describe,
                    read: false,
                    sender_delete: false,
                    receiver_delete: false,
                    receiver: cookies.get("id"),
                },
                {
                    headers: headers,
                }
            )
            .then((resp) => {
                navigate("/message");

                toast.success("Message sent successfully", {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    style: { backgroundColor: "#1ab394", color: "white" },
                    icon: false,
                    progressStyle: { backgroundColor: "#BAE8DE" },
                });
                // navigate({
                //     state: {token: resp.data.token},
                //     // path: "/message"
                // });
            })
            .catch((err) => {

                toast.error("Error Sending message", {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    style: { backgroundColor: "#FF6347", color: "white" },
                    icon: false,
                    progressStyle: { backgroundColor: "#FFB1A3" },
                });
            });
    }


    return (
        <div className="bg-default-background h-screen overflow-auto">
            <Header />
            <Sidebar />
            <div className=' ease-in  duration-300  ml-0 '>
                <div>

                    <div className="bg-white px-5 py-3 m-4 rounded-md z-10">

                        <div className="flex items-center justify-between pb-4 rounded-md">
                            <Link to={"/message"}>
                                <div
                                    className="text-blue-700 text-lg font-semibold flex items-center space-x-1 button_effect hover:bg-default-gray px-3 py-2 rounded-lg cursor-pointer">
                                    <ArrowBackIosNewIcon style={{ height: 20 }} />
                                    <span>Back</span>
                                </div>
                            </Link>
                            <div
                                className="text-red-500 flex items-center space-x-1 button_effect hover:bg-default-gray px-3 py-2 rounded-lg cursor-pointer"
                                onClick={() => navigate("/message")}
                            >
                                <RemoveCircleOutlineIcon style={{ height: 20 }} />
                                <span>Discard</span>
                            </div>
                        </div>

                        <div className="flex justify-between gap-3 flex-col md:flex-row">

                            <div className="ml-3">
                                <div className="ml-2 font-semibold text-lg text-blue-600">Reply To :</div>

                                <div className="flex mt-4">
                                    <AccountCircleIcon sx={{ fontSize: "50px" }} className="mr-2 text-gray-500" />
                                    <div
                                        className="mt-1 font-semmibold text-xl font-bold mt-2">{cookies.get("senderName")}</div>
                                </div>
                            </div>


                            <div className="flex items-center">
                                <p className="ml-5 font-semibold text-gray-500">
                                    Date: <span className="text-blue-700">{newDate}</span>
                                </p>
                            </div>
                        </div>

                        <div className="space-y-5 my-4">


                            <div className="px-4 space-y-2 flex flex-col">
                                <span className="text-default-blue font-semibold text-lg">
                                    Message
                                </span>
                                <TextField
                                    fullWidth
                                    className="bg-slate-50"
                                    label="Subject of the message"
                                    onChange={(e) => setHeader(e.target.value)}
                                />
                            </div>

                            <div className="px-4 space-y-2 flex flex-col">
                                <TextField
                                    fullWidth
                                    multiline
                                    minRows={5}
                                    label="Describe anything in message"
                                    className="bg-slate-50"
                                    onChange={(e) => setDescribe(e.target.value)}
                                />
                            </div>

                            <div className="ml-6">
                                <Button
                                    variant="contained"
                                    endIcon={<SendIcon />}
                                    style={{ background: "#0865B6", color: "white" }}
                                    onClick={send}
                                >
                                    Send
                                </Button>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default SendReply;