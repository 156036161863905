import axios from "axios";
import React, { useState } from "react";
import { base_url } from "../../../components/consts";

import DoneAllIcon from "@mui/icons-material/DoneAll";
import EditIcon from "@mui/icons-material/Edit";
import { LocalizationProvider, MobileDatePicker } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { Avatar, Button, Container, TextField } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import moment from "moment";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import Cookies from "universal-cookie";
import { MobileNumberInput } from "../../../components/Input/MobileNumberInput";
import { isValidPersonalData } from "../../../helpers/validate_personal";

const cookies = new Cookies();
const AddInformation = () => {
  const navigate = useNavigate();
  const [active, setActive] = useState("btn3");
  const [newInfo, setNewInfo] = useState([]);
  const [profile_pic, setProfile_pic] = useState(null);
  const [profilePicPreview, setProfilePicPreview] = useState("");
  const [first_name, setFirst_name] = useState("");
  const [last_name, setLast_name] = useState("");
  const [designation, setDesignation] = useState("");
  const [user_email, setUser_email] = useState("");
  const [marital_status, setMarital_status] = useState("");
  const [mobile_no, setMobile_no] = useState("");
  const [gender, setGender] = useState("");
  const [address, setAddress] = useState("");
  const [dob, setDob] = useState(null);
  const [current_address, setcurrent_address] = useState("");
  const [language, setLanguage] = useState("");
  const [pin, setPin] = useState(0);
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [about_you, setAbout_you] = useState("");
  // const [cllg_name, setCllg_name] = useState('')
  // const [year_pass, setYear_pass] = useState('')
  const [linkedIn, setLinkedIn] = useState("");
  const [github, setGithub] = useState("");
  const [aadhar_card, setAadhar_card] = useState("");
  const [pan_card, setPan_card] = useState("");
  const [guardian_name, setGuardian_name] = useState("");
  const [guardian_number, setGuardian_number] = useState("");
  const [total_exp, setTotal_exp] = useState("0");

  const AddInfo = () => {
    let formField = new FormData();

    const data = {
      first_name,
      last_name,
      mobile_no,
      designation,
      marital_status: marital_status,
      gender,
      address,
      dob,
      current_address,
      language,
      pin,
      city,
      state,
      country,
      about_you,
      linkedin_id: linkedIn,
      github_id: github,
      aadhaar_card: aadhar_card,
      pan_card,
      guardian_name,
      guardian_phn_no: guardian_number,
      total_exp: total_exp,
    };

    const error = isValidPersonalData(data);
    if (error) return toast.error(error);

    // Convert the data object into the FormData
    Object.entries(data).forEach(([key, value]) => {
      formField.append(key, value);
    });

    if (profile_pic !== null) {
      formField.append("profile_pic", profile_pic);
    }
    axios
      .post(`${base_url}/profile/personal/add`, formField, {
        headers: {
          Authorization: "Token " + cookies.get("token"),
        },
      })
      .then((res) => {
        setNewInfo(res.data);
        setActive("btn1");
        navigate("/profile");
        navigate("/profile/personal");
      })
      .catch((err) => {
        // toast.error(
        //   "Please fill First Name, Last Name, Permanent Address, Contact and Gender if you haven't "
        // );
        toast.error("Something went wrong while uploading");
      });
  };
  return (
    <Container maxWidth="md" sx={{ marginTop: "4rem", marginBottom: "4rem" }}>
      <div>
        {active === "btn3" ? (
          <div>
            <div className="grid grid-cols-6 space-y-4 md:space-y-0 md:grid-cols-12 mb-4">
              <p
                className="font-semibold text-sky-700 pl-4 col-span-9
                                                    md:col-span-10
                                                    lg:col-span-11 lg:text-2xl"
              >
                Personal Details
              </p>
              <div align="right">
                <Button
                  className="w-20"
                  onClick={() => {
                    AddInfo();
                  }}
                  style={{
                    backgroundColor: "#F1F1F1",
                    borderRadius: "10px",
                    color: "rgb(8, 101, 182)",
                  }}
                >
                  Save <DoneAllIcon style={{ fontSize: "medium" }} />
                </Button>
              </div>
            </div>
            <div className="grid grid-cols-12 m-1 md:m-4">
              <div className="col-span-10 md:text-center lg:col-span-2">
                <TextField
                  type="file"
                  id="profile_pic_id"
                  style={{ display: "none" }}
                  src={profile_pic}
                  onChange={(e) => {
                    setProfilePicPreview(
                      URL.createObjectURL(e.target.files[0])
                    );
                    setProfile_pic(e.target.files[0]);
                  }}
                />
                <Button className="hover:text-sky-700 text-sky-700">
                  <label htmlFor="profile_pic_id">
                    {profilePicPreview ? (
                      <img
                        src={profilePicPreview}
                        className="h-32 w-32  rounded-full"
                        alt=""
                      />
                    ) : (
                      <Avatar style={{ width: "8rem", height: "8rem" }} />
                    )}
                    <EditIcon className="m-2 profile_pic_edit_icon" />
                  </label>
                </Button>
              </div>

              <div className="col-span-10 space-y-5">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 p-2">
                  <div className="text-neutral-400">
                    <TextField
                      fullWidth
                      label="First Name"
                      variant="outlined"
                      type="text"
                      name="first_name"
                      value={first_name}
                      onChange={(e) => setFirst_name(e.target.value)}
                      required
                    />
                  </div>
                  <div className="text-neutral-400">
                    <TextField
                      fullWidth
                      label="Last Name"
                      variant="outlined"
                      type="text"
                      name="last_name"
                      value={last_name}
                      onChange={(e) => setLast_name(e.target.value)}
                      required
                    />
                  </div>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 p-2">
                  {/*<div className="col text-neutral-400">*/}
                  {/*    <p>Email ID</p>*/}
                  {/*    <p className="text-neutral-900">*/}
                  {/*        {user_email}*/}
                  {/*    </p>*/}
                  {/*</div>*/}
                  <div className="text-neutral-400">
                    <TextField
                      fullWidth
                      label="Designation"
                      variant="outlined"
                      type="text"
                      name="designation"
                      value={designation}
                      onChange={(e) => setDesignation(e.target.value)}
                    />
                  </div>
                  <div className="text-neutral-400">
                    {/* <TextField
                      fullWidth
                      label="Contact"
                      variant="outlined"
                      type="number"
                      name="Contact"
                      value={mobile_no}
                      onChange={(e) => setMobile_no(e.target.value)}
                      required
                    /> */}
                    <MobileNumberInput
                      initVal={mobile_no}
                      onChange={(num) => setMobile_no(num)}
                    />
                  </div>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 p-2">
                  <div className="text-neutral-400">
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Marital Status
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={marital_status}
                        label="Marital Status"
                        onChange={(e) => setMarital_status(e.target.value)}
                      >
                        <MenuItem value={"Single"}>Single</MenuItem>
                        <MenuItem value={"Married"}>Married</MenuItem>
                        <MenuItem value={"Widow"}>Widow</MenuItem>
                        <MenuItem value={"Divorced"}>Divorced</MenuItem>
                        <MenuItem value={"Other"}>Other</MenuItem>
                      </Select>
                    </FormControl>
                  </div>

                  <div className="text-neutral-400">
                    <FormControl fullWidth required>
                      <InputLabel id="demo-simple-select-label">
                        Gender
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={gender}
                        label="Gender"
                        onChange={(e) => setGender(e.target.value)}
                      >
                        <MenuItem value={"Female"}>Female</MenuItem>
                        <MenuItem value={"Male"}>Male</MenuItem>
                        <MenuItem value={"Not Specified"}>
                          Not Specified
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 p-2">
                  <div className="text-neutral-400 flex flex-col">
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <MobileDatePicker
                        fullWidth
                        disableFuture
                        openTo="day"
                        views={["year", "month", "day"]}
                        label="Date Of Birth"
                        value={dob}
                        required
                        // onChange={(e) => setDob(e.target.value)}
                        onChange={(newValue) => {
                          setDob(moment(newValue).format("YYYY-MM-DD"));
                        }}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </LocalizationProvider>
                  </div>
                  <div className="text-neutral-400">
                    <FormControl fullWidth required>
                      <InputLabel id="demo-simple-select-label">
                        Total Experience
                      </InputLabel>
                      <Select
                        fullWidth
                        id="demo-simple-select-label"
                        label="Total Experience"
                        variant="outlined"
                        type="textarea"
                        name="total_exp"
                        value={total_exp}
                        onChange={(e) => setTotal_exp(e.target.value)}
                        required
                      >
                        <MenuItem value="0 to 1">0 to 1 Years</MenuItem>
                        <MenuItem value="1 to 2">1 to 2 Years</MenuItem>
                        <MenuItem value="3 to 5">3 to 5 Years</MenuItem>
                        <MenuItem value="5+">5+ Years</MenuItem>
                        <MenuItem value="10+">10+ Years</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 p-2">
                  <div className="text-neutral-400">
                    <TextField
                      fullWidth
                      label="Aadhar Card Number"
                      variant="outlined"
                      type="text"
                      name="aadhar_card"
                      error={aadhar_card.length > 16}
                      value={aadhar_card}
                      onChange={(e) => setAadhar_card(e.target.value)}
                    />
                  </div>
                  <div className="text-neutral-400">
                    <TextField
                      fullWidth
                      label="Pan Card Number"
                      variant="outlined"
                      type="text"
                      name="pan_card"
                      value={pan_card}
                      onChange={(e) => setPan_card(e.target.value)}
                    />
                  </div>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 p-2">
                  <div className="text-neutral-400">
                    <TextField
                      fullWidth
                      label="Current Address"
                      variant="outlined"
                      type="text"
                      name="address"
                      value={current_address}
                      required
                      onChange={(e) => setcurrent_address(e.target.value)}
                    />
                  </div>
                  {/* <div className="text-neutral-400">
                    <TextField
                      fullWidth
                      label="Permanent Address"
                      variant="outlined"
                      type="textarea"
                      name="permanent"
                      value={address}
                      onChange={(e) => setAddress(e.target.value)}
                    />
                  </div> */}
                </div>

                <div className="grid grid-cols-3 md:grid-cols-6 lg:grid-cols-12 gap-4 p-2">
                  <div className="col-span-3 text-neutral-400">
                    <TextField
                      fullWidth
                      label="Pin"
                      variant="outlined"
                      type="number"
                      error={pin.length > 6}
                      name="Pin"
                      value={pin}
                      onChange={(e) => setPin(e.target.value)}
                    />
                  </div>
                  <div className="col-span-3 text-neutral-400">
                    <TextField
                      fullWidth
                      label="City"
                      variant="outlined"
                      type="text"
                      name="City"
                      value={city}
                      onChange={(e) => setCity(e.target.value)}
                    />
                  </div>
                  <div className="col-span-3 text-neutral-400">
                    <TextField
                      fullWidth
                      label="State"
                      variant="outlined"
                      type="text"
                      name="State"
                      value={state}
                      onChange={(e) => setState(e.target.value)}
                    />
                  </div>
                  <div className="col-span-3 text-neutral-400">
                    <TextField
                      fullWidth
                      label="Country"
                      variant="outlined"
                      type="text"
                      name="Country"
                      value={country}
                      onChange={(e) => setCountry(e.target.value)}
                    />
                  </div>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 p-2">
                  <div className="text-neutral-400t">
                    <TextField
                      label="About You"
                      className="form-control"
                      name="about_you"
                      value={about_you}
                      onChange={(e) => setAbout_you(e.target.value)}
                    />
                  </div>
                  <div className="text-neutral-400">
                    <TextField
                      fullWidth
                      label="Languages"
                      variant="outlined"
                      type="text"
                      name="languages"
                      value={language}
                      onChange={(e) => setLanguage(e.target.value)}
                    />
                  </div>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 p-2">
                  <div className="text-neutral-400">
                    <TextField
                      fullWidth
                      label="Guardian Name"
                      variant="outlined"
                      type="text"
                      name="guardian_name"
                      value={guardian_name}
                      onChange={(e) => setGuardian_name(e.target.value)}
                    />
                  </div>
                  <div className="text-neutral-400">
                    <TextField
                      fullWidth
                      label="Guardian Number"
                      variant="outlined"
                      type="number"
                      name="guardian_number"
                      value={guardian_number}
                      onChange={(e) => setGuardian_number(e.target.value)}
                    />
                  </div>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 p-2 mt-2">
                  <div className="text-neutral-400">
                    <TextField
                      fullWidth
                      label="LinkedIn"
                      variant="outlined"
                      type="url"
                      name="LinkedIn"
                      value={linkedIn}
                      onChange={(e) => setLinkedIn(e.target.value)}
                    />
                  </div>
                  <div className="text-neutral-400">
                    <TextField
                      fullWidth
                      label="Github"
                      variant="outlined"
                      type="url"
                      name="Github"
                      value={github}
                      onChange={(e) => setGithub(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null}

        {/* {active === "btn1" ? <PersonalDetails/>:null} */}
      </div>
    </Container>
  );
};

export default AddInformation;
