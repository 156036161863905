import { DoneAll } from "@mui/icons-material";
import { Box, CircularProgress, Typography } from "@mui/material";
import { green } from "@mui/material/colors";
import React, { useEffect, useState } from "react";

/**
 * @typedef {object} Props
 * @property {boolean} loading
 */

/**
 * @param {Props} props
 */
export default function LoadSuccess({ loading }) {
    const [showSuccess, setShowSuccess] = useState(false);

    useEffect(() => {
        let timeoutId = 0;
        if (showSuccess) {
            timeoutId = setTimeout(() => {
                setShowSuccess(false);
            }, [1000]);
        }
        return () => {
            clearTimeout(timeoutId);
        };
    }, [showSuccess]);

    useEffect(() => {
        if (loading === false) {
            setShowSuccess(true);
        }
    }, [loading]);
    return (
        <Box sx={{ position: "fixed", top: "90%", left: "90%" }}>
            {showSuccess && (
                <Typography fontSize={"16px"} color={green[600]}>
                    <DoneAll fontSize="small" sx={{ marginRight: "5px" }} />
                    Saved
                </Typography>
            )}
            {loading && <CircularProgress size={"25px"} />}
        </Box>
    );
}
