import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { store } from './app/store';
import { Provider } from 'react-redux';
// import * as serviceWorker from './serviceWorker';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import axios from "axios";
import 'tw-elements';
import "antd/dist/antd.css";

axios.defaults.baseURL = 'https://ps.prod.hribe.karmaalab.com';

export const User_Fe_url = "https://user.pshire.plausibilitysolutions.com/"


ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <App />
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
);
serviceWorkerRegistration.register();