import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { AppBar, Box, Button, Card, CardContent, IconButton, Menu, MenuItem, Toolbar } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import JobList from "../../components/jobs/JobList";
import HRI_image from "../Login/HRI-home.png";
import facebook from "./Images/Facebook.png";
import logo from './Images/HRI-Logo.png';
import image1 from "./Images/Image1.png";
import image10 from "./Images/Image10.png";
import image2 from "./Images/Image2.png";
import image3 from "./Images/Image3.png";
import image4 from "./Images/Image4.png";
import image5 from "./Images/Image5.png";
import image6 from "./Images/Image6.png";
import image7 from "./Images/Image7.png";
import image8 from "./Images/Image8.png";
import image9 from "./Images/Image9.png";
import mail from "./Images/Mail.png";
import phone from "./Images/Phone.png";
import twitter from "./Images/Twitter.png";
import landingpage_img from "./Images/User Landingpage.gif";
import mouseImage from "./Images/Vector.png";
import logo2 from "./Images/apple-icon-120x120.png";



const LandingPage = () => {

    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [jobs, setJobs] = useState([]);
    // const [date, setDate] = useState("")


    // const [anchorEl, setAnchorEl] = React.useState(null);
    // // const open = Boolean(anchorEl);
    // // const handleClick = (event) => {
    // //     setAnchorEl(event.currentTarget);
    // // };
    // // const handleClose = () => {
    // //     setAnchorEl(null);
    // // };

    const [anchorElNav, setAnchorElNav] = React.useState(null);
    // const [anchorElUser, setAnchorElUser] = React.useState(null);

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };



    const GetDetails = () => {
        // hri_admin/newposition-public/<int:id>
        // 
        axios
            .get(
                `/profile/landing-page`
            )
            .then((res) => {
                // setData(res.data.business_details)
                setJobs(res.data.jobs_list)
                setData(res.data?.business_details)

            })
            .catch((err) => {

            })
    };


    useEffect(() => {
        GetDetails();

        // caches.keys().then( async (name)=>{
        //     
        //    const clean = await name.map(data=>{
        //         caches.delete(data)
        //     })
        //     if(clean.length){
        //         
        //         return window.location.reload(true);
        //     } else {
        //         return 
        //     }

        // }).catch(err=>

    }, []);

    const ApplyForJob = (item) => {
        navigate(`/applications/job-detail/${item.id}`);
        localStorage.setItem("JobId", item.id);
    };


    return (
        <div>

            <Box sx={{ flexGrow: 1 }}>
                <AppBar position="fixed" className="bg-white rounded-b-xl py-2 shadow" elevation={0}>
                    <Toolbar className="rounded-b-xl" >
                        {data.length > 0 ? data.map((info) => (
                            <div key={info} className="flex">
                                <img src={info.logo} className="h-8 lg:h-10" alt="" />
                                <div className="">
                                    <div className="font-semibold text-base md:text-xl lg:text-2xl  ml-4 text-[#1E66B1]">
                                        {info.name}
                                    </div>
                                    <div className="text-[0rem] md:text-sm ml-4 text-[#72C9B9]">
                                        {info.tag_line}
                                    </div>
                                </div>
                            </div>
                        )) :

                            <div className="flex">
                                <div>
                                    <img src={logo2} className="h-8 lg:h-10 w-8 lg:w-10 rounded-full" alt="" />
                                </div>
                                <p className="font-semibold text-base md:text-xl lg:text-2xl  ml-4 text-[#1E66B1]">
                                    HR Intelligence
                                </p>
                            </div>
                        }

                        <div className="text-[#1E66B1]  font-semibold ml-auto">

                            <Box sx={{ flexGrow: 1, display: { xs: 'flex', sm: 'none' } }}>
                                <IconButton
                                    size="large"
                                    aria-label="account of current user"
                                    aria-controls="menu-appbar"
                                    aria-haspopup="true"
                                    onClick={handleOpenNavMenu}
                                    color="inherit"
                                >
                                    <KeyboardArrowDownIcon />
                                </IconButton>
                                <Menu
                                    id="menu-appbar"
                                    anchorEl={anchorElNav}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left',
                                    }}
                                    open={Boolean(anchorElNav)}
                                    onClose={handleCloseNavMenu}
                                    sx={{
                                        display: { xs: 'block', sm: 'none' },
                                    }}
                                >
                                    <a href="#home"><MenuItem onClick={handleCloseNavMenu}>Home</MenuItem></a>
                                    <a href="#services"><MenuItem
                                        onClick={handleCloseNavMenu}>Services</MenuItem></a>
                                    <a href="#jobs"><MenuItem onClick={handleCloseNavMenu}>Jobs</MenuItem></a>
                                    <a href="#about_hri"><MenuItem onClick={handleCloseNavMenu}>About HRI</MenuItem></a>
                                    <a href="#signIn"><MenuItem onClick={() => navigate("/login")}>Sign In/ Sign Up</MenuItem></a>
                                </Menu>
                            </Box>

                            <Box sx={{ flexGrow: 1, display: { xs: 'none', sm: 'flex' } }}>
                                <a href="#home" className="mt-2 mr-4 lg:mr-5 md:text-base lg:text-xl">
                                    Home
                                </a>
                                <a href="#services" className="mt-2 mr-4 lg:mr-5 md:text-base lg:text-xl">
                                    Services
                                </a>
                                <a href="#jobs" className="mt-2 mr-4 lg:mr-5 md:text-base lg:text-xl">
                                    Jobs
                                </a>
                                <a href="#about_hri" className="mt-2 mr-4 lg:mr-5 md:text-base lg:text-xl">
                                    About HRI
                                </a>
                                <span
                                    className="text-[#1E66B1] bg-[#F2F2F2] px-2 py-1 lg:px-5 lg:py-2 md:text-base lg:text-xl rounded-full cursor-pointer hover:bg-neutral-200"
                                    onClick={() => navigate("/login")}
                                >
                                    Sign In/ Sign Up
                                </span>
                            </Box>
                        </div>
                    </Toolbar>

                </AppBar>
            </Box>

            <Card id="home" className="bg-white" elevation={0}>
                <CardContent>
                    <div className="grid grid-cols-5 md:grid-cols-10">
                        <div className="col-span-5 space-y-5  order-1  px-4 md:pt-44">
                            <p className="text-[#1E66B1] text-[1rem]
                            md:text-[1.6rem]
                            lg:text-[1.9rem]
                            xl:text-[2.2rem] font-[600]">
                                You are in the right place HRI will help you to find jobs across world.
                            </p>

                            <p className="text-[#1E66B1] text-[0.8rem] md:text-[1.4rem] lg:text-[1.7rem] xl:text-[2rem] font-[600]">
                                Then what are you waiting for
                                <br />
                                Sign Up Now
                                and keep your hands busy.
                            </p>
                            <div>
                                <button
                                    className="text-white bg-[#1E66B1] hover:bg-sky-800 rounded-full text-xs px-2 py-1 md:px-4 md:py-2 md:text-base lg:text-xl lg:px-4 lg:py-2"
                                    onClick={() => {
                                        navigate("/login")
                                    }}
                                // sx={{borderRadius: "25px", background: "#1E66B1"}}
                                >
                                    Find Jobs
                                </button>
                            </div>

                        </div>
                        <div className="col-span-5 md:order-1 pt-10 lg:pt-0" align="center">
                            <img className="h-[15rem] md:h-[27rem] lg:h-[30rem] xl:h-[35rem]" src={landingpage_img}
                                alt="" />
                        </div>
                    </div>

                    <div align="center">
                        <img className="h-4 mt-4 md:h-8" src={mouseImage} alt="" />
                    </div>
                </CardContent>
            </Card>

            <Card className="mt-10 py-5" sx={{ backgroundColor: "#F3F3F3" }} elevation={0}>
                <CardContent>
                    <div className="space-y-10">
                        <div align="center">
                            <img className="h-56 md:h-72" src={HRI_image} alt="" />
                        </div>

                        <div align="center" className="bg-white mx-6 md:mx-20 py-4 rounded-xl">
                            <div className="grid grid-cols-12 px-4" align="ecnter">
                                <div className="col-span-12 md:col-span-2">
                                    <img className="h-20" src={image1} alt="" />
                                </div>

                                <div className="col-span-12 md:col-span-10 text-left flex self-center px-3">
                                    <p className="text-base md:text-2xl lg:text-3xl font-[600]">
                                        Here in HRI we{" "}
                                        <span className="text-[#0865B6]">
                                            find {" "}
                                        </span>
                                        a right job {" "}
                                        <span className="text-[#0865B6]">
                                            for you
                                        </span>
                                        , until you get your {" "}
                                        <span className="text-[#0865B6]">
                                            dream job
                                        </span>
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div align="center" className="bg-white mx-6 md:mx-20 py-4 rounded-xl">
                            <div className="grid grid-cols-12 px-4" align="center">
                                <div className="col-span-12 md:col-span-2">
                                    <img className="h-20" src={image2} alt="" />
                                </div>

                                <div className="col-span-12 md:col-span-10 text-left flex self-center px-3">
                                    <p className="text-base md:text-2xl lg:text-3xl font-[600]">
                                        The{" "}
                                        <span className="text-[#0865B6]">
                                            Response time {" "}
                                        </span>
                                        of your {" "}
                                        <span className="text-[#0865B6]">
                                            application{" "}
                                        </span>
                                        with HRI is less then making {" "}
                                        <span className="text-[#0865B6]">
                                            instant noodles
                                        </span>
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div align="center" className="bg-white mx-6 md:mx-20 py-4 rounded-xl">
                            <div className="grid grid-cols-12 px-4">
                                <div className="col-span-12 md:col-span-2">
                                    <img className="h-20" src={image3} alt="" />
                                </div>

                                <div className="col-span-12 md:col-span-10 text-left flex self-center px-3">
                                    <p className="text-base md:text-2xl lg:text-3xl font-[600]">
                                        HRI Team will{" "}
                                        <span className="text-[#0865B6]">
                                            prepare your resume{" "}
                                        </span>
                                        according to Job Description and {" "}
                                        <span className="text-[#0865B6]">
                                            send{" "}
                                        </span>
                                        your resumes {" "}
                                        <span className="text-[#0865B6]">
                                            to{" "}
                                        </span>
                                        different{" "}
                                        <span className="text-[#0865B6]">
                                            companies.....{" "}
                                        </span>
                                        Nice
                                    </p>
                                </div>
                            </div>
                        </div>


                    </div>
                </CardContent>
            </Card>

            <Card id="services" className="py-24" elevation={0}>
                <CardContent>
                    <div align="center">
                        <p className="font-bold text-2xl md:text-3xl lg:text-4xl">
                            Features
                        </p>
                    </div>
                    <div className="m-5" align="center">
                        <div
                            className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-16 place-content-center">
                            <Card className="shadow-lg" sx={{ borderRadius: "10px" }} elevation={0}>
                                <CardContent>
                                    <img className="h-20" src={image4} alt="" />
                                    <p className="text-[#0865B6] font-semibold text-base md:text-xl p-2">
                                        Instant status <br /> on application
                                    </p>
                                </CardContent>
                            </Card>
                            <Card className="shadow-lg" sx={{ borderRadius: "10px" }} elevation={0}>
                                <CardContent>
                                    <img className="h-20" src={image5} alt="" />
                                    <p className="text-[#0865B6] font-semibold text-base md:text-xl p-2">
                                        Profile <br /> Recommendation
                                    </p>
                                </CardContent>
                            </Card>
                            <Card className="shadow-lg" sx={{ borderRadius: "10px" }} elevation={0}>
                                <CardContent>
                                    <img className="h-20" src={image6} alt="" />
                                    <p className="text-[#0865B6] font-semibold text-base md:text-xl p-2">
                                        Schedule <br /> Meetings
                                    </p>
                                </CardContent>
                            </Card>
                            <Card className="shadow-lg" sx={{ borderRadius: "10px" }} elevation={0}>
                                <CardContent>
                                    <img className="h-20" src={image7} alt="" />
                                    <p className="text-[#0865B6] font-semibold text-base md:text-xl p-2">
                                        Upload All Your <br /> Professional Details
                                    </p>
                                </CardContent>
                            </Card>
                            <Card className="shadow-lg" sx={{ borderRadius: "10px" }} elevation={0}>
                                <CardContent>
                                    <img className="h-20" src={image8} alt="" />
                                    <p className="text-[#0865B6] font-semibold text-base md:text-xl p-2">
                                        Customizing <br /> Resumes
                                    </p>
                                </CardContent>
                            </Card>
                            <Card className="shadow-lg" sx={{ borderRadius: "10px" }} elevation={0}>
                                <CardContent>
                                    <img className="h-20" src={image9} alt="" />
                                    <p className="text-[#0865B6] font-semibold text-base md:text-xl p-2">
                                        Filter Profiles <br /> with A.I
                                    </p>
                                </CardContent>
                            </Card>
                            <Card className="shadow-lg" sx={{ borderRadius: "10px" }} elevation={0}>
                                <CardContent>
                                    <img className="h-20" src={image10} alt="" />
                                    <p className="text-[#0865B6] font-semibold text-base md:text-xl p-2">
                                        Contact <br /> Support
                                    </p>
                                </CardContent>
                            </Card>
                        </div>

                        <div>
                            <p className="text-[#0865B6] text-xl md:text-2xl lg:text-3xl mt-[3rem] font-semibold">
                                And Many More...
                            </p>
                        </div>

                    </div>
                </CardContent>
            </Card>

            <Card id="jobs" className="py-24" sx={{ backgroundColor: "#F3F3F3" }} elevation={0}>
                <CardContent>

                    <div className="grid grid-cols-6 lg:grid-cols-12">
                        <div
                            className="py-2 text-center col-span-6 font-bold text-2xl md:text-3xl lg:text-4xl lg:col-span-3 lg:col-start-6">
                            JOBS IN LIST
                        </div>
                        <div className="text-center col-span-6 lg:col-start-11 lg:col-span-2">
                            <Button variant="contained" className="px-4 py-2 mt-2"
                                sx={{ borderRadius: "25px", background: "" }}
                            >
                                Find More
                            </Button>
                        </div>
                    </div>
                    <div className="m-1 md:m-2 lg:m-3">
                        <JobList
                            jobList={jobs}
                            performAction={true}
                            actionButtonText={"Apply Now"}
                            actionButtonFunc={ApplyForJob}
                            performBookMark={false}
                        />

                    </div>

                    <div align="center">
                        <p className="text-[#0865B6] text-xl md:text-2xl lg:text-3xl mt-[3rem] font-semibold">
                            And Many More...
                        </p>
                    </div>

                </CardContent>
            </Card>

            <Card id="about_hri" className="pb-10 py-24" elevation={0}>
                <CardContent>
                    {data && data.map((info) => (
                        <div align="center" className="px-10 pb-10" key={info}>
                            <p className="font-bold text-2xl md:text-3xl lg:text-4xl pb-5">
                                About Us
                            </p>
                            <Card elevation={0} className="shadow" sx={{ borderRadius: "20px" }}>
                                <CardContent>
                                    <div>
                                        <p
                                            className="font-semibold pt-3 px-[1rem]
                                            md:text-[1.7rem] md:px-[5rem]
                                            lg:text-[2rem] lg:px-[6rem]"
                                        >
                                            {info.about_us}
                                            {/*HRI is a part of mybizmo we are product based company that creates*/}
                                            {/*new featured products that help business and people to update there lifes.*/}
                                        </p>
                                    </div>
                                </CardContent>
                            </Card>

                            <p className="font-bold text-2xl md:text-3xl lg:text-4xl py-5">
                                Contact Us
                            </p>
                            <div className="flex justify-center">
                                <div
                                    className="grid grid-cols-1
                                    md:grid-cols-2
                                    lg:grid-cols-3 gap-4 "
                                >

                                    {info.ofc_twitter ?
                                        <Card className="shadow-lg" elevation={0}>
                                            <CardContent>
                                                <a className="hover:text-[#1E66B1]" href={info.ofc_twitter}>
                                                    <img src={twitter} className="h-[2rem] md:h-[3rem] lg:h-[4rem] mt-2"
                                                        alt="" />
                                                    <p className="text-base md:text-2xl lg:text-3xl font-bold pt-4">
                                                        HRI-Mybizmo
                                                    </p>
                                                </a>
                                            </CardContent>
                                        </Card> : <></>}


                                    {info.ofc_fb ?
                                        <Card className="shadow-lg" elevation={0}>
                                            <CardContent>
                                                <a className="hover:text-[#1E66B1]" href={info.ofc_fb}>
                                                    <img src={facebook} className="h-[2rem] md:h-[3rem] lg:h-[4rem] mt-2 p-1"
                                                        alt="" />
                                                    <p className="text-base md:text-xl lg:text-2xl font-bold pt-4">
                                                        HRI-Mybizmo
                                                    </p>
                                                </a>
                                            </CardContent>
                                        </Card> : <></>}


                                    <Card className="shadow-lg" elevation={0}>
                                        <CardContent>
                                            <img src={mail} className="h-[2rem] md:h-[3rem] lg:h-[4rem] mt-2" alt="" />
                                            <p className="text-base md:text-lg lg:text-xl font-bold pt-4">
                                                {info.ofc_email}
                                            </p>
                                        </CardContent>
                                    </Card>

                                    <Card className="shadow-lg" elevation={0}>
                                        <CardContent>
                                            <img src={phone} className="h-[2rem] md:h-[3rem] lg:h-[4rem] mt-2 p-1 md:p-2"
                                                alt="" />
                                            <p className="text-base md:text-xl lg:text-2xl font-bold pt-4">
                                                {info.ofc_phone}
                                            </p>
                                        </CardContent>
                                    </Card>

                                </div>
                            </div>

                        </div>
                    ))}
                </CardContent>
            </Card>

            <div className="bottom-0 right-0 left-0 bg-[#1E66B1] pl-3 md:pl-10 grid grid-cols-12 items-center">
                <img className="py-3 col-span-2 md:col-span-1 h-16 md:h-20" src={logo} alt="" />
                <div className="flex-col ml-2 col-span-5 md:col-span-4">
                    <p className="text-white font-[500] text-lg md:text-xl lg:text-2xl">
                        HRI @Mybizmo
                    </p>
                    <p className="text-white text-xs md:text-base">
                        © 2021 HRI All Right Reserved
                    </p>
                </div>

                {data && data.map((info) => (
                    <div
                        className="text-white col-span-4 col-start-9 text-lg md:text-xl md:col-span-3 md:col-start-9 lg:col-start-10 lg:text-2xl"
                        key={info}>
                        <a href={info.terms}>
                            T&C
                        </a>
                        <a className="ml-5 md:ml-10" href={info.privacy_policy}>
                            Privacy Policy
                        </a>
                    </div>
                ))}

            </div>

        </div>
    )
}

export default LandingPage;