import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useNavigate } from 'react-router';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
export default function JobMenu() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const navigate = useNavigate()
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  function handleMenuClick(path) {
    handleClose()
    navigate(path)
  }



  function renderText() {
    const path = window.location.pathname;
    switch (path) {
      case "/applications/recommended-jobs":
        return "Recommended"
      case "/applications/applied-jobs":
        return "Applied"
      case "/applications/saved":
        return "Saved"
      default:
        return "Jobs"
    }
  }


  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <ArrowDropDownIcon sx={{ marginRight: "2px" }} />
        {renderText()}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={() => handleMenuClick("jobs")}>Jobs</MenuItem>
        <MenuItem onClick={() => handleMenuClick("recommended-jobs")}>Recommended</MenuItem>
        <MenuItem onClick={() => handleMenuClick("Applied")}>Applied</MenuItem>
        <MenuItem onClick={() => handleMenuClick("saved")}>Saved</MenuItem>
      </Menu>
    </div>
  );
}