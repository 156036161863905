import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import { saveServeyAnswer } from "../../controller/serveyController";
import { handleControllerResultException } from "../../utils/dataFetchHelper";
import { HTTPStatus } from "../../utils/helperObj";

/**
 * @typedef {object} Props
 * @property {import('../../model/ServeyModel').ServeyQuestionModel} question
 */

/**
 * @param {Props} props
 */
export default function QuestionBooleanField({
    question,
}) {
    const componentMounted = useRef(false)
    const timeoutRef = useRef(null)
    const [booleanFieldValue, setBooleanFiledValue] = useState(question.answer !== null ? question.answer : "");
    const navigate = useNavigate()

    const handleAnswer = useCallback(async (booleanFieldValue) => {

        const res = await saveServeyAnswer(
            question.id,
            booleanFieldValue,
            question.questionType
        );

        if (res.statusCode !== HTTPStatus.CREATED) {
            handleControllerResultException(res, navigate);
        }
    }, [navigate, question.id, question.questionType]);

    useEffect(() => {
        if (timeoutRef.current !== null) {
            clearTimeout(timeoutRef.current)
        }
        if (componentMounted.current) {
            timeoutRef.current = setTimeout(() => {
                handleAnswer(booleanFieldValue);
            }, [700]);
        } else {
            componentMounted.current = true;
        }
        return () => {
            clearTimeout(timeoutRef.current);
        };
    }, [booleanFieldValue, handleAnswer]);

    return (
        <RadioGroup
            value={booleanFieldValue}
            onChange={(e) => setBooleanFiledValue(e.target.value)}
        >
            <FormControlLabel
                value={"Yes"}
                control={<Radio />}
                label={"Yes"}
            />
            <FormControlLabel value={"No"} control={<Radio />} label={"No"} />
        </RadioGroup>
    );
}
