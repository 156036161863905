import React, { useEffect, useState } from "react";
import { Button, Card, CardContent, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import axios from "axios";
import { toast } from "react-toastify";

import Cookies from "universal-cookie";
import { base_url } from "../../../../components/consts";
import { isValidEducation } from "../../../../helpers/validate_education";

const cookies = new Cookies();

const EducationUpdate = () => {
  const navigate = useNavigate();

  const [title, setTitle] = useState("");
  const [course, setCourse] = useState("");
  const [specialization, setSpecialization] = useState("");
  const [university, setUniversity] = useState("others");
  const [courseType, setCourseType] = useState("");
  const [complete, setComplete] = useState("");
  const [passYear, setPassYear] = useState("");
  const [ins, setIns] = useState([]);
  const [institute, setInstitute] = useState("");
  const [ed_list, setEd_list] = useState(["BE", "Btech", "commerce"]);

  const getData = () => {
    axios
      .get(`${base_url}/profile/education-list`, {
        headers: {
          Authorization: "Token " + cookies.get("token"),
        },
      })
      .then((res) => {
        setTitle(res.data[0].title);
        setCourse(res.data[0].course);
        setSpecialization(res.data[0].specialization);
        let institute = "others";
        ins.forEach((data) => {
          if (data.institute_name === res.data[0].institute_name) {
            institute = data.institute_name;
          }
        });
        setUniversity(institute);
        setInstitute(res.data[0].institute_name);
        setCourseType(res.data[0].course_type);
        setComplete(res.data[0].is_complete);
        setPassYear(res.data[0].pass_year);
      })
      .catch((err) => {
        
      });
  };

  const updateData = () => {
    const data = {
      title: title,
      course: course,
      specialization: specialization,
      institute_name: university === "others" ? institute : university,
      course_type: courseType,
      is_complete: complete,
      pass_year: passYear,
    };

    const error = isValidEducation(data);
    if (error) return toast.error(error);

    axios
      .put(`profile/education-update`, data, {
        headers: {
          Authorization: "Token " + cookies.get("token"),
        },
      })
      .then((res) => {
        
        toast.success("Updated Successfully", {
          position: toast.POSITION.TOP_CENTER,
          setTimeout: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          style: { backgroundColor: "#1ab394", color: "white" },
          icon: false,
          progressStyle: { backgroundColor: "#BAE8DE" },
        });
        navigate("/profile/professional-details/education");
      })
      .catch((err) => {
        
        if (title.length > 50) {
          toast.error("Education Title should be less than 50 characters", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            style: { backgroundColor: "#FF6347", color: "white" },
            icon: false,
            progressStyle: { backgroundColor: "#FFB1A3" },
          });
        } else {
          toast.error("Error Updating", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            style: { backgroundColor: "#FF6347", color: "white" },
            icon: false,
            progressStyle: { backgroundColor: "#FFB1A3" },
          });
        }
      });
  };
  useEffect(() => {
    if (ins.length === 0) {
      axios
        .get("profile/institute-name-list", {
          headers: {
            Authorization: "Token " + cookies.get("token"),
          },
        })
        .then((res) => {
          setIns(res.data);
        });
    }
    if (ins.length > 0) {
      axios
        .get("hri_admin/education-list")
        .then((dat) => {
          
          setEd_list(dat.data.education_list);
        })
        .catch((err) => {
          
        });
      getData();
    }
  }, [ins.length]);

  return (
    <div>
      <Card className="my-3" elevation={0} sx={{ borderRadius: "8px" }}>
        <CardContent className="space-y-4 m-2">
          <div className="flex flex-row">
            <div className="flex-grow">
              <p className="text-sky-700 text-xl px-2 md:px-4 md:text-2xl lg:text-2xl font-bold">
                Education Edit
              </p>
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-12">
            <div className="col-span-12">
              <div className="grid col-span-1 md:grid-cols-2 gap-4 mt-4">
              <div>
              <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Name
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={title}
                      label="Course"
                      onChange={(e) => setTitle(e.target.value)}
                    >
                        <MenuItem value="10+">10+</MenuItem>
                        <MenuItem value="12+">12+</MenuItem>
                        <MenuItem value="Under Graduation">Under Graduation</MenuItem>
                        <MenuItem value="Graduation">Graduation</MenuItem>
                        <MenuItem value="Post Graduation">Post Graduation</MenuItem>
                        <MenuItem value="Masters">Masters</MenuItem>
                        <MenuItem value="PHD">PHD</MenuItem>
                    </Select>
                  </FormControl>
              </div>
                <div>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Course
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={course}
                      label="Course"
                      onChange={(e) => setCourse(e.target.value)}
                    >
                      {ed_list.map((dat) => (
                        <MenuItem value={dat}>{dat}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>

                <div>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Specialization
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={specialization}
                      label="Specialization"
                      onChange={(e) => setSpecialization(e.target.value)}
                    >
                      <MenuItem value={"Agriculture"}>Agriculture</MenuItem>
                      <MenuItem value={"Automobile"}>Automobile</MenuItem>
                      <MenuItem value={"Aviation"}>Aviation</MenuItem>
                      <MenuItem value={"Bio-Chemistry/Bio-Technology"}>
                        Bio-Chemistry/Bio-Technology
                      </MenuItem>
                      <MenuItem value={"Biomedical"}>Biomedical</MenuItem>
                      <MenuItem value={"Ceramics"}>Ceramics</MenuItem>
                      <MenuItem value={"Chemical"}>Chemical</MenuItem>
                      <MenuItem value={"Civil"}>Civil</MenuItem>
                      <MenuItem value={"Computers"}>Computers</MenuItem>
                      <MenuItem value={"Electrical"}>Electrical</MenuItem>
                      <MenuItem value={"Electronics/Telecommunication"}>
                        Electronics/Telecommunication
                      </MenuItem>
                      <MenuItem value={"Energy"}>Energy</MenuItem>
                      <MenuItem value={"Environmental"}>Environmental</MenuItem>
                      <MenuItem value={"Instrumentation"}>
                        Instrumentation
                      </MenuItem>
                      <MenuItem value={"Marine"}>Marine</MenuItem>
                      <MenuItem value={"Mechanical"}>Mechanical</MenuItem>
                      <MenuItem value={"Metallurgy"}>Metallurgy</MenuItem>
                      <MenuItem value={"Mineral"}>Mineral</MenuItem>
                      <MenuItem value={"Mining"}>Mining</MenuItem>
                      <MenuItem value={"Nuclear"}>Nuclear</MenuItem>
                      <MenuItem value={"Paint/Oil"}>Paint/Oil</MenuItem>
                      <MenuItem value={"Petroleum"}>Petroleum</MenuItem>
                      <MenuItem value={"Plastics"}>Plastics</MenuItem>
                      <MenuItem value={"Production/Industrial"}>
                        Production/Industrial
                      </MenuItem>
                      <MenuItem value={"Textile"}>Textile</MenuItem>
                      <MenuItem value={"Other Specialization"}>
                        Other Specialization
                      </MenuItem>
                    </Select>
                  </FormControl>
                </div>

                {ins.length > 0 && (
                  <FormControl fullWidth>
                    <InputLabel id="demo">Institute name</InputLabel>
                    <Select
                      fullWidth
                      id="demo"
                      label="Institute name"
                      value={university}
                      onChange={(option) => setUniversity(option.target.value)}
                    >
                      {ins.map((option) => (
                        <MenuItem value={option.institute_name}>
                          {option.institute_name}
                        </MenuItem>
                      ))}
                      <MenuItem value="others">Others</MenuItem>
                    </Select>
                  </FormControl>
                )}
                {university === "others" && (
                  <TextField
                    fullWidth
                    className="mt-[1rem] md:mt-0"
                    label="Enter institute name"
                    value={institute}
                    onChange={(e) => setInstitute(e.target.value)}
                  />
                )}

                <div>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Course Type
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={courseType}
                      label="Course Type"
                      onChange={(e) => setCourseType(e.target.value)}
                    >
                      <MenuItem value={"full-time"}>Full-Time</MenuItem>
                      <MenuItem value={"part-time"}>Part-Time</MenuItem>
                    </Select>
                  </FormControl>
                </div>

                <div>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Degree Status
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={complete}
                      label="Degree Status"
                      onChange={(e) => setComplete(e.target.value)}
                    >
                      <MenuItem value={true}>Completed</MenuItem>
                      <MenuItem value={false}>On-Going</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                {complete === true ? (
                  <div className="flex">
                    <TextField
                      fullWidth
                      label="Pass Out Year"
                      type="number"
                      value={passYear}
                      onChange={(e) => setPassYear(e.target.value)}
                    />
                  </div>
                ) : (
                  <div className="flex">
                    <TextField
                      fullWidth
                      label="Probable Pass Out"
                      type="number"
                      value={passYear}
                      onChange={(e) => setPassYear(e.target.value)}
                    />
                  </div>
                )}
              </div>

              <div className="flex justify-center mt-4 space-x-4">
                <Button
                  disableElevation
                  variant="contained"
                  sx={{
                    background: "#F1F1F1",
                    color: "#0865B6",
                    borderRadius: "8px",
                    ":hover": { background: "#E4E4E4" },
                  }}
                  onClick={updateData}
                >
                  Save
                </Button>
                <Button
                  disableElevation
                  variant="contained"
                  sx={{
                    background: "#F1F1F1",
                    color: "#0865B6",
                    borderRadius: "8px",
                    ":hover": { background: "#E4E4E4" },
                  }}
                  onClick={() =>
                    navigate("/profile/professional-details/education")
                  }
                >
                  Cancel
                </Button>
              </div>
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default EducationUpdate;
