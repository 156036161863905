import axios from "axios";
import { handleException } from "../utils/dataFetchHelper";
import { API } from "../utils/helperObj";
import Cookies from "universal-cookie";
import { createQuestionObj } from "../model/ServeyModel";
import HttpResponse from "../model/HttpResponseModel";

const cookies = new Cookies();

/**
 *
 * @param {string} assignmentId
 * @returns {Promise<[{questions:import("../model/ServeyModel").ServeyQuestionModel[], jobId:number} | null , import("../model/HttpResponseModel").default | null]>}
 */
export async function getAssignmentDetails(assignmentId) {
    const result = [null, null];
    try {
        const res = await axios.get(`${API.servey.getAssignment}/${assignmentId}`, {
            headers: {
                Authorization: "Token " + cookies.get("token"),
            },
        });
        const questionArr = [];
        for (let x of res.data.questions) {
            questionArr.push(createQuestionObj(x));
        }
        result[0] = {
            questions: questionArr,
            jobId: res.data.job_id
        };
        return result;
    } catch (err) {
        result[1] = handleException(err);
        return result;
    }
}

/**
 *
 * @param {number} questionId
 * @param {string | File} answer
 * @param {"BoolField" | "NumberField" | "RadioField" | "ChoiceField" | "ImageField" | "FileField"| "TextField"| "VideoField"} answerType
 */
export async function saveServeyAnswer(questionId, answer, answerType) {
    try {
        const formData = new FormData();
        formData.append("question", questionId);
        if (
            answerType === "TextField" ||
            answerType === "BoolField" ||
            answerType === "RadioField" ||
            answerType === "ChoiceField"
        ) {
            formData.append("answer", answer);
        } else {
            answer
                ? formData.append("answer_media", answer)
                : formData.append("answer_media", null);
        }
        const res = await axios.post(API.servey.saveAnswer, formData, {
            headers: {
                Authorization: "Token " + cookies.get("token"),
            },
        });
        return new HttpResponse({ statusCode: res.status, successMsg: "Saved" });
    } catch (err) {
        return handleException(err);
    }
}

export async function removeServeyAnswer(answerId) {
    try {
        const res = await axios.delete(`${API.servey.removeAnswer}/${answerId}`, {
            headers: {
                Authorization: "Token " + cookies.get("token"),
            },
        });
        return new HttpResponse({
            statusCode: res.status,
            successMsg: "Removed Successful",
        });
    } catch (err) {
        return handleException(err);
    }
}
