import axios from "axios";
import { API, HTTPStatus } from "../utils/helperObj";
import HttpResponse from "../model/HttpResponseModel";
import Cookies from "universal-cookie";
import { LINKEDIN } from "../constants/socialLogin";

const cookies = new Cookies();

export async function socialLogin(type, access_token) {
  let API_URL = null;
  if (type === "google") API_URL = API.googleLogin;
  if (type === "facebook") API_URL = API.facebookLogin;
  if (type === "linkedin") API_URL = API.linkedinLogin;

  if (API_URL === null) {
    return new HttpResponse({
      statusCode: HTTPStatus.BAD_REQUEST,
      errorMsg: `Bad Login Type - ${type}`,
    });
  }

  let res;
  if (type !== "linkedin") {
    res = await axios.post(API_URL, { access_token });
  } else {
    res = await axios.post(API_URL, {
      code: access_token,
      redirect_uri: LINKEDIN.REDIRECT_URI,
    });
  }

  if (res.status !== HTTPStatus.SUCCESS) {
    return new HttpResponse({
      data: res.data,
      statusCode: res.status,
      errorMsg: `Unable to login with ${type}`,
    });
  } else {
    const token = res.data?.token;
    if (!token) {
      return new HttpResponse({
        data: res.data,
        statusCode: res.status,
        errorMsg: `No Token found - ${type} login`,
      });
    } else {
      cookies.set("token", JSON.stringify(token));
      return new HttpResponse({ data: res.data, statusCode: res.status });
    }
  }
}
