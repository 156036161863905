import CloseIcon from "@mui/icons-material/Close";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { Button, InputLabel } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import axios from "axios";
import moment from "moment";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { base_url } from "../../../../components/consts";
import pdf_img from "../Resume/Pdf.png";

import { MobileDatePicker } from "@mui/lab";
import { toast } from "react-toastify";
import Cookies from "universal-cookie";
import { isValidCertificate } from "../../../../helpers/validate_certificate";

const cookies = new Cookies();

const CertificateAdd = () => {
  let navigate = useNavigate();

  const [variable, setVariable] = useState({
    course_name: "",
    college_name: "",
    degree_status: "",
    education_mode: "",
    start_year: null,
    end_year: null,
    city: "",
    country: "",
    certificate: "",
    certificate_url: "",
  });

  const startDate = moment(variable.start_year).format("YYYY-MM-DD");
  const endDate = moment(variable.end_year).format("YYYY-MM-DD");

  const addCertificate = () => {
    let formField = new FormData();

    const data = {
      degree_name: variable.course_name,
      university_name: variable.college_name,
      degree_status: variable.degree_status,
      start_year: startDate,
      end_year: endDate,
      city: variable.city,
      country: variable.country,
      is_completed: variable.degree_status === "Completed" ? "true" : "false",
      education_mode: variable.education_mode,
      certificate_url: variable.certificate_url,
      certificate: variable.certificate,
    };
    const error = isValidCertificate(data);
    if (error) return toast.error(error);

    // Convert the data object into the FormData
    Object.entries(data).forEach(([key, value]) => {
      formField.append(key, value);
    });

    if (startDate >= endDate) {
      toast.error("End date cannot be before Start date", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        style: { backgroundColor: "#FF6347", color: "white" },
        icon: false,
        progressStyle: { backgroundColor: "#FFB1A3" },
      });
    } else if (variable.certificate === "") {
      toast.error("Please select Certificate to upload", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        style: { backgroundColor: "#FF6347", color: "white" },
        icon: false,
        progressStyle: { backgroundColor: "#FFB1A3" },
      });
    } else {
      axios
        .post(`${base_url}/profile/certificate/add`, formField, {
          headers: {
            Authorization: "Token " + cookies.get("token"),
          },
        })
        .then((res) => {

          navigate("/profile/professional-details/certificate");

          toast.success("Sucessfully added Certificate", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            style: { backgroundColor: "#1ab394", color: "white" },
            icon: false,
            progressStyle: { backgroundColor: "#BAE8DE" },
          });
        })
        .catch(function (error) {

          if (error.response) {

            toast.error(`Error: ${error.response.data.errors.certificate}`, {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              style: { backgroundColor: "#FF6347", color: "white" },
              icon: false,
              progressStyle: { backgroundColor: "#FFB1A3" },
            });
          }
        });
    }
  };

  return (
    <div>
      <div className="card text-dark bg-white my-4 border-none rounded-lg">
        <div className="card-body">
          <div className="grid grid-cols-1 md:grid-cols-2 space-y-3">
            <h1 className="text-lg font-bold text-[#0865B6]">
              Add Certifications
            </h1>
            {/*<div>*/}
            {/*    <button*/}
            {/*        className="text-base bg-[#F1F1F1] rounded-lg px-3 py-1 font-semibold text-[#0865B6]"*/}
            {/*        onClick={() => navigate(-1)}*/}
            {/*    >*/}
            {/*        /!* <span>*/}
            {/*          <DeleteIcon />*/}
            {/*        </span>{" "} *!/*/}
            {/*        Discard*/}
            {/*    </button>*/}
            {/*</div>*/}
          </div>

          <div className="m-2 pt-4 space-y-4">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
              <div>
                <TextField
                  fullWidth
                  label="Your Degree/ Course Name"
                  value={variable.course_name}
                  onChange={(e) =>
                    setVariable({ ...variable, course_name: e.target.value })
                  }
                  type="text"
                // placeholder="Enter your Course Name"
                // className="outline-none border-gray-500 border-[1px] rounded-md p-2"
                />
              </div>
              <div>
                <TextField
                  fullWidth
                  label="University/ College/ School Name"
                  value={variable.college_name}
                  onChange={(e) =>
                    setVariable({ ...variable, college_name: e.target.value })
                  }
                  type="text"
                // placeholder="Enter Your University/College/School Name"
                // className="outline-none border-gray-500 border-[1px] rounded-md p-2"
                />
              </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
              <div>
                <FormControl fullWidth>
                  <InputLabel id="demo-customized-select-label">
                    Degree/ Course Status
                  </InputLabel>
                  <Select
                    label="Degree/ Course Status"
                    labelId="demo-customized-select-label"
                    id="demo-customized-select"
                    value={variable.degree_status}
                    onChange={(e) =>
                      setVariable({
                        ...variable,
                        degree_status: e.target.value,
                      })
                    }
                  // input={<BootstrapInput/>}
                  >
                    <MenuItem value={"On-Going"}>On-Going</MenuItem>
                    <MenuItem value={"Completed"}>Completed</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div>
                <FormControl fullWidth>
                  <InputLabel id="demo-customized-select-label">
                    Education Mode
                  </InputLabel>
                  <Select
                    label="Education Mode"
                    labelId="demo-customized-select-label"
                    // id="demo-customized-select"
                    value={variable.education_mode}
                    onChange={(e) =>
                      setVariable({
                        ...variable,
                        education_mode: e.target.value,
                      })
                    }
                  >
                    <MenuItem value={"Online"}>Online</MenuItem>
                    <MenuItem value={"Offline"}>Offline</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
              <div>
                <TextField
                  fullWidth
                  label="City"
                  value={variable.city}
                  onChange={(e) =>
                    setVariable({ ...variable, city: e.target.value })
                  }
                  type="text"
                // placeholder="Please select your country"
                // className="outline-none border-gray-500 border-[1px] rounded-md p-2"
                />
              </div>
              <div>
                <TextField
                  fullWidth
                  label="Country"
                  value={variable.country}
                  onChange={(e) =>
                    setVariable({ ...variable, country: e.target.value })
                  }
                // type="text"
                // placeholder="Please select your country"
                // className="outline-none border-gray-500 border-[1px] rounded-md p-2"
                />
              </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
              <div>
                <TextField
                  fullWidth
                  label="Certificate URL"
                  value={variable.certificate_url}
                  onChange={(e) =>
                    setVariable({
                      ...variable,
                      certificate_url: e.target.value,
                    })
                  }
                  type="text"
                // className="outline-none border-gray-500 border-[1px] rounded-md p-2"
                // placeholder="Link should be in public mode"
                />
              </div>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <MobileDatePicker
                  label="Start Date"
                  value={variable.start_year}
                  // onChange={(newValue) => {
                  //   setValue(newValue);
                  // }}
                  onChange={(newValue) =>
                    setVariable({ ...variable, start_year: newValue })
                  }
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
              <div className="col-span-1 flex flex-col">
                {variable.degree_status === "Completed" && (
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <MobileDatePicker
                      label="End Date"
                      value={variable.end_year}
                      // onChange={(newValue) => {
                      //   setValue(newValue);
                      // }}
                      onChange={(newValue) =>
                        setVariable({ ...variable, end_year: newValue })
                      }
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                )}
              </div>
            </div>

            <div className="flex flex-col space-y-1">
              <span className="font-semibold text-slate-500">
                Upload Certificate Document
              </span>
              <div className="items-center space-x-5 space-y-3 grid grid-cols-1 md:space-y-0 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5">
                <div
                  className="card border-none w-48 md:w-60"
                  style={{ backgroundColor: "#F3F3F3" }}
                  align="center"
                >
                  <div className="cardbody">
                    <div className="cardtext">
                      <div className="grid grid-cols-4">
                        <div className="col-span-3" align="center">
                          <img
                            className="p-2 w-24 md:w-28"
                            src={pdf_img}
                            alt=""
                          />
                        </div>
                        {/*<div*/}
                        {/*    className="col"*/}
                        {/*    style={{color: "rgb(8, 101, 182)"}}*/}
                        {/*>*/}
                        {/*    <DeleteIcon*/}
                        {/*        className="m-2"*/}
                        {/*        fontSize="small"*/}
                        {/*        style={{color: "#157EFB"}}*/}
                        {/*    />*/}
                        {/*</div>*/}
                      </div>
                      <div
                        className="bg-[#e5e5e5] text-[#157EFB] text-sm md:text-base"
                        id="PDF_filename_div"
                      >
                        File name:{" "}
                        {variable.certificate.name
                          ? variable.certificate.name
                          : "please select a file"}
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <TextField
                    type="file"
                    id="PDF_input"
                    style={{ display: "none" }}
                    value={variable.certificate ? "" : ""}
                    onChange={(e) =>
                      setVariable({
                        ...variable,
                        certificate: e.target.files[0],
                      })
                    }
                  />
                  <label
                    htmlFor="PDF_input"
                    className="text-[#157EFB] font-bold text-base md:text-lg bg-[#F1F1F1] p-2 rounded-lg cursor-pointer"
                  >
                    <DriveFolderUploadIcon className="mr-2" />
                    Upload
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="my-4 space-x-5">
            <Button
              className={`text-lg rounded-lg px-4 py-2 duration-300 ${variable.degree_name === "" ||
                  variable.university_name === "" ||
                  variable.degree_status === "" ||
                  variable.city === "" ||
                  variable.country === "" ||
                  variable.start_year === "" ||
                  variable.education_mode === ""
                  ? "opacity-50"
                  : "opacity-100"
                }`}
              disabled={
                variable.degree_name === "" ||
                  variable.university_name === "" ||
                  variable.degree_status === "" ||
                  variable.city === "" ||
                  variable.country === "" ||
                  variable.start_year === "" ||
                  variable.education_mode === ""
                  ? true
                  : false
              }
              onClick={() => {
                addCertificate();

              }}
              style={{
                background: "#F1F1F1",
                color: "#0865B6",
                fontWeight: "bold",
                borderRadius: "0.5rem",
                width: "7rem",
              }}
            >
              <span className="mr-1">
                <DoneAllIcon style={{ fontSize: "large" }} />
              </span>
              Save
            </Button>
            <Button
              className={`px-4 py-2 duration-300`}
              style={{
                background: "#F1F1F1",
                color: "#0865B6",
                fontWeight: "bold",
                borderRadius: "0.5rem",
                width: "7rem",
              }}
              onClick={() =>
                navigate("/profile/professional-details/certificate")
              }
            >
              <span className="mr-1">
                <CloseIcon style={{ fontSize: "large" }} />
              </span>
              Cancel
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CertificateAdd;
