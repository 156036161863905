import React, { useEffect, useRef, useState } from "react";
import pdf_img from "./Pdf.png";
import axios from "axios";
import { base_url } from "../../../../components/consts";
import Cookies from "universal-cookie";
import { toast } from "react-toastify";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import {
    Box,
    Button,
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Modal,
    Tooltip
} from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import ProfileDetails from "./ProfileDetails";
import { useNavigate } from "react-router-dom";
import PrintIcon from '@mui/icons-material/Print';
import Template1 from "./Template1";
import Template2 from "./Template2";
import Template3 from "./Template3";
import Template4 from "./Template4";
import { VideoFile } from "@mui/icons-material";
import { Input } from "antd";

const cookies = new Cookies();
toast.configure();

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    borderRadius: 2,
    border: "none",
    boxShadow: 24,
    p: 4,
};

const Resume = () => {

    const navigate = useNavigate();

    //Uploading Resume file
    const [url, setUrl] = useState("");
    const [resume, setResume] = useState([]);
    const [resume1, setResume1] = useState([]);

    const [videoResume, setVideoResume] = useState([]);
    const [addVideoResume, setAddVideoResume] = useState([]);
    const [videoName, setVideoName] = useState("");
    const [data, setData] = useState([]);

    const [template, setTemplate] = useState("template1");
    const [templateId, setTemplateId] = useState("TemplateID1")

    const lastIndex = data[0]?.resume?.lastIndexOf("/");
    

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [open2, setOpen2] = React.useState(false);
    const handleOpen2 = () => setOpen2(true);
    const handleClose2 = () => setOpen2(false);

    const [open3, setOpen3] = React.useState(false);
    const handleOpen3 = () => setOpen3(true);
    const handleClose3 = () => setOpen3(false);

    const [open4, setOpen4] = React.useState(false);
    const handleOpen4 = () => setOpen4(true);
    const handleClose4 = () => setOpen4(false);

    const getData = () => {
        axios
            .get(
                `${base_url}/profile/professional`,
                {
                    headers: {
                        Authorization: "Token " + cookies.get("token"),
                    },
                })
            .then((res) => {
                
                setData(res.data);
            })
            .catch((err) => {
                
            })
    }


    const [openGeneratePDF, setOpenGeneratePDF] = useState(false);

    const handleClickOpenGeneratePDF = () => {
        setOpenGeneratePDF(true);
    };

    const handleCloseGeneratePDF = () => {
        setOpenGeneratePDF(false);
    };

    const descriptionElementRef = useRef(null);


    function printDiv(divName) {
        const printContents = document.getElementById(divName).innerHTML;
        const originalContents = document.body.innerHTML;

        document.body.innerHTML = printContents;
        window.print();
        document.body.innerHTML = originalContents;
        window.location.reload();
    }


    const AddResume = () => {

        let formField = new FormData();
        formField.append("resume", resume1);


        axios
            .post(
                `${base_url}/profile/professional-create-update`,
                formField,
                {
                    headers: {
                        Authorization: "Token " + cookies.get("token"),
                    },
                })
            .then((response) => {
                
                handleClose();
                handleClose3()
                window.location.reload();
            })
            .catch((err) => {
                
            });
    };

    const AddVideoResume = () => {

        let formField = new FormData();
        formField.append("vdo_cv", addVideoResume);

        axios
            .post(
                `${base_url}/profile/professional-create-update`,
                formField,
                {
                    headers: {
                        Authorization: "Token " + cookies.get("token"),
                    },
                })
            .then(() => {
                handleClose();
                handleClose3()
            })
            .catch((err) => {
                toast.error(`${err.response.data.errors.resume}`, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    style: { backgroundColor: "#FF6347", color: "white" },
                    icon: false,
                    progressStyle: { backgroundColor: "#FFB1A3" },
                });
            });
    };



    const UploadResume = () => {
        let formField = new FormData();

        if (resume !== null) {
            formField.append("resume", resume);
        }
        if (videoResume !== null) {
            formField.append("vdo_cv", videoResume);
        }

        axios
            .post(
                `${base_url}/profile/professional-create-update`,
                formField,
                {
                    headers: {
                        Authorization: "Token " + cookies.get("token"),
                    },
                })
            .then((response) => {
                
                handleClose();
                window.location.reload();
            })
            .catch((err) => {
                
                toast.error(`${err.response.data.errors.resume[0]}`, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    style: { backgroundColor: "#FF6347", color: "white" },
                    icon: false,
                    progressStyle: { backgroundColor: "#FFB1A3" },
                });
            });
    };


    useEffect(() => {

        if (cookies.get("token")) {
            getData();
            if (open) {
                const { current: descriptionElement } = descriptionElementRef;
                if (descriptionElement !== null) {
                    descriptionElement.focus();
                }
            }
        } else {
            navigate("/");
        }
    }, [open]);

    return (
        <div className="px-0 md:px-10 lg:px-16  cursor-default">

            <div className="card border-none rounded-lg bg-white profile_card">
                <div className="card-body">
                    <div className="px-2 md:px-6">
                        <div className="grid grid-cols-1 md:grid-cols-2 flex items-center justify-between">
                            <p className="text-xl  md:text-2xl lg:text-2xl font-bold text-[#0865B6]">
                                Resume
                            </p>

                            <div className="text-left md:text-right">
                                {(data.length === 0) ? <div>
                                    <TextField
                                        type="file"
                                        id="PDF_input1"
                                        style={{ display: "none" }}
                                        onChange={(e) => {
                                            handleOpen();
                                            setResume1(e.target.files[0]);
                                            setUrl(e.target.files[0].name);
                                            // AddResume()
                                        }}
                                    />
                                    <label
                                        htmlFor="PDF_input1"
                                        className="text-[#0865B6] font-bold text-lg bg-[#F1F1F1] p-2 rounded-lg cursor-pointer"
                                    >
                                        <DriveFolderUploadIcon className="mr-2" />
                                        Add
                                    </label>
                                </div> : <></>}

                                {data.length > 0 ? <div className="space-y-4 md:space-y-0 md:space-x-4">

                                    <Tooltip title="Print to PDF">
                                        <Button
                                            style={{
                                                color: "#0865B6",
                                                backgroundColor: "#F1F1F1",
                                                borderRadius: "5px",
                                                fontWeight: "bold"
                                            }}
                                            className="py-2 px-4"
                                            onClick={handleClickOpenGeneratePDF}
                                        >
                                            Generate PDF
                                        </Button>
                                    </Tooltip>

                                    <Dialog
                                        open={openGeneratePDF}
                                        fullWidth
                                        maxWidth="md"
                                        onClose={handleCloseGeneratePDF}
                                        aria-labelledby="scroll-dialog-title"
                                        aria-describedby="scroll-dialog-description"
                                    >
                                        <DialogTitle id="scroll-dialog-title">Resume</DialogTitle>
                                        <div className="px-4 pb-3 space-y-2 md:space-y-0 md:space-x-4">
                                            <Button
                                                disableElevation
                                                variant="contained"
                                                style={{
                                                    background: "#F1F1F1",
                                                    color: "#0865B6",
                                                }}
                                                onClick={() => {
                                                    setTemplate("template1");
                                                    setTemplateId("TemplateID1")
                                                }}
                                            >
                                                Template 1
                                            </Button>
                                            <Button
                                                disableElevation
                                                variant="contained"
                                                style={{
                                                    background: "#F1F1F1",
                                                    color: "#0865B6",
                                                }}
                                                onClick={() => {
                                                    setTemplate("template2");
                                                    setTemplateId("TemplateID2")
                                                }}
                                            >
                                                Template 2
                                            </Button>
                                            <Button
                                                disableElevation
                                                variant="contained"
                                                style={{
                                                    background: "#F1F1F1",
                                                    color: "#0865B6",
                                                }}
                                                onClick={() => {
                                                    setTemplate("template3");
                                                    setTemplateId("TemplateID3")
                                                }}
                                            >
                                                Template 3
                                            </Button>
                                            <Button
                                                disableElevation
                                                variant="contained"
                                                style={{
                                                    background: "#F1F1F1",
                                                    color: "#0865B6",
                                                }}
                                                onClick={() => {
                                                    setTemplate("template4");
                                                    setTemplateId("TemplateID4")
                                                }}
                                            >
                                                Template 4
                                            </Button>
                                        </div>
                                        <DialogContent dividers>
                                            <DialogContentText
                                                id="scroll-dialog-description"
                                                ref={descriptionElementRef}
                                                tabIndex={-1}
                                            >
                                                {/*<div className="py-4" id={"resume"}>*/}
                                                {/*    <ProfileDetails/>*/}
                                                {/*</div>*/}

                                                {template === "template1" ? <div id="TemplateID1">
                                                    <Template1 />
                                                </div> : <></>}

                                                {template === "template2" ? <div id="TemplateID2">
                                                    <Template2 />
                                                </div> : <></>}

                                                {template === "template3" ? <div id="TemplateID3">
                                                    <Template3 />
                                                </div> : <></>}

                                                {template === "template4" ? <div id="TemplateID4">
                                                    <Template4 />
                                                </div> : <></>}
                                            </DialogContentText>
                                        </DialogContent>

                                        <DialogActions>

                                            <Button
                                                endIcon={<PrintIcon />}
                                                variant="contained"
                                                onClick={() => printDiv(`${templateId}`)}>
                                                Print
                                            </Button>
                                            <Button onClick={handleCloseGeneratePDF} variant="outlined">
                                                Cancel
                                            </Button>
                                        </DialogActions>
                                    </Dialog>

                                    <TextField
                                        type="file"
                                        id="PDF_input"
                                        style={{ display: "none" }}
                                        onChange={(e) => {
                                            handleOpen2();
                                            setResume(e.target.files[0]);
                                            setUrl(e.target.files[0].name);
                                            
                                        }}
                                    />
                                    <label
                                        htmlFor="PDF_input"
                                        className="text-[#0865B6] font-bold text-lg bg-[#F1F1F1] p-1.5 rounded cursor-pointer"
                                    >
                                        <DriveFolderUploadIcon className="mr-2" />
                                        Upload
                                    </label>
                                </div> : <> </>}
                            </div>

                        </div>

                        <div
                            className="mt-4 w-52 md:w-60 card border-none"
                            style={{ backgroundColor: "#F3F3F3" }}
                            align="center"
                        >
                            {data.map((item) => (<div className="cardbody" key={item.id}>
                                <div className="cardtext">
                                    <div className="row">
                                        <div className="col-9" align="center">
                                            <a
                                                href={item.resume}
                                                download
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                <img className="p-2" src={pdf_img} alt="" />
                                            </a>
                                        </div>
                                    </div>
                                    <div
                                        className="bg-[#e5e5e5] text-[#0865B6]"
                                        id="PDF_filename_div"
                                    >
                                        <p className="font-semibold">File name: </p>
                                        {/*{item.resume.substring(lastIndex + 1)}*/}
                                        {item.resume_name}
                                    </div>
                                </div>
                            </div>))}
                        </div>
                    </div>

                </div>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={open}
                    onClose={handleClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{ timeout: 500, }}
                >
                    <Fade in={open}>
                        <Box sx={style}>
                            <Typography id="transition-modal-title" variant="h6" component="h2">
                                Want to save the resume??
                                <p>Please confirm</p>
                            </Typography>
                            <Typography id="transition-modal-title" variant="h6" component="h2">
                                <p className="text-base">
                                    Resume - <span className="text-gray-400">{url}</span>
                                </p>
                            </Typography>
                            <Typography className="space-x-4 mt-2">
                                <Button
                                    variant="contained"
                                    style={{ background: "#0865B6" }}
                                    className="bg-red-500 hover:bg-red-300 duration-300 text-white px-3 py-2 text-sm md:px-5 md:py-3 sm:text-sm md:text-base rounded-full"
                                    onClick={() => AddResume()}
                                >
                                    Save
                                </Button>
                                <Button
                                    variant="contained"
                                    style={{ background: "tomato" }}
                                    className="bg-red-500 hover:bg-red-300 duration-300 text-white px-3 py-2 text-sm md:px-5 md:py-3 sm:text-sm md:text-base rounded-full"
                                    onClick={() => window.location.reload()}
                                >
                                    Cancel
                                </Button>
                            </Typography>
                        </Box>
                    </Fade>
                </Modal>

                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={open2}
                    onClose={handleClose2}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{ timeout: 500, }}
                >
                    <Fade in={open2}>
                        <Box sx={style}>
                            <Typography id="transition-modal-title" variant="h6" component="h2">
                                Want to save the resume??
                                <p>Please confirm</p>
                            </Typography>
                            <Typography id="transition-modal-title" variant="h6" component="h2">
                                <p className="text-base">
                                    Resume - <span className="text-gray-400">{url}</span>
                                </p>
                            </Typography>
                            <Typography className="mt-2 space-x-4">
                                <Button
                                    variant="contained"
                                    style={{ background: "#0865B6" }}
                                    className="bg-red-500 hover:bg-red-300 duration-300 text-white px-3 py-2 text-sm md:px-5 md:py-3 sm:text-sm md:text-base rounded-full"
                                    onClick={() => UploadResume()}
                                >
                                    Save
                                </Button>
                                <Button
                                    variant="contained"
                                    style={{ background: "tomato" }}
                                    className="bg-red-500 hover:bg-red-300 duration-300 text-white px-3 py-2 text-sm md:px-5 md:py-3 sm:text-sm md:text-base rounded-full"
                                    onClick={() => window.location.reload()}
                                >
                                    Cancel
                                </Button>
                            </Typography>
                        </Box>
                    </Fade>
                </Modal>
            </div>

            <div className="card border-none rounded-lg bg-white profile_card">
                <div className="card-body">
                    <div className="px-2 md:px-6">
                        <div className="grid grid-cols-1 md:grid-cols-2 flex items-center justify-between">
                            <p className="text-xl  md:text-2xl lg:text-2xl font-bold text-[#0865B6]">
                                Video CV
                            </p>

                            <div className="text-left md:text-right">
                                {(data.length === 0) ? <div>
                                    <TextField
                                        type="file"
                                        id="PDF_input3"
                                        style={{ display: "none" }}
                                        onChange={(e) => {
                                            handleOpen3();
                                            setAddVideoResume(e.target.files[0]);
                                            setVideoName(e.target.files[0].name);
                                            // AddResume()
                                        }}
                                    />
                                    <label
                                        htmlFor="PDF_input3"
                                        className="text-[#0865B6] font-bold text-lg bg-[#F1F1F1] p-2 rounded-lg cursor-pointer"
                                    >
                                        <DriveFolderUploadIcon className="mr-2" />
                                        Add
                                    </label>
                                </div> : <></>}

                                {data.length > 0 ? <div>
                                    <input
                                        type="file"
                                        id="PDF_input4"
                                        style={{ display: "none" }}
                                        onChange={(e) => {
                                            if (e.target.files[0].size < (30 * 1024)) {
                                                setVideoResume(e.target.files[0])
                                                setVideoName(e.target.files[0].name);
                                                
                                                handleOpen4();
                                                AddVideoResume()
                                            } else {
                                                window.alert("File size is very large try with smaller file size")
                                            }

                                        }}
                                    />
                                    <button
                                        htmlFor="PDF_input4"
                                        onClick={() => handleOpen4()}
                                        className="text-[#0865B6] font-bold text-lg bg-[#F1F1F1] p-2 rounded-lg cursor-pointer"
                                    >
                                        <DriveFolderUploadIcon className="mr-2" />
                                        Upload
                                    </button>
                                </div> : <> </>}
                            </div>

                        </div>

                        <div
                            className="mt-4 w-52 md:w-60 card border-none"
                            style={{ backgroundColor: "#F3F3F3" }}
                            align="center"
                        >
                            {data.map((item) => (<div className="cardbody" key={item.id}>
                                <div className="cardtext">
                                    <div className="row">
                                        <div className="col-9" align="center">
                                            <a
                                                href={item.vdo_cv}
                                                download
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                <VideoFile sx={{ fontSize: 70 }} />
                                            </a>
                                        </div>
                                    </div>
                                    <div
                                        className="bg-[#e5e5e5] text-[#0865B6]"
                                        id="PDF_filename_div"
                                    >
                                        <p className="font-semibold">File name: </p>
                                        {/*{item.resume.substring(lastIndex + 1)}*/}
                                        {item.vdo_cv_name}
                                    </div>
                                </div>
                            </div>))}
                        </div>
                    </div>

                </div>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={open3}
                    onClose={handleClose3}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{ timeout: 500, }}
                >
                    <Fade in={open3}>
                        <Box sx={style}>
                            <Typography id="transition-modal-title" variant="h6" component="h2">
                                Want to save the Video Resume??
                                <p>Please confirm</p>
                            </Typography>
                            <Typography id="transition-modal-title" variant="h6" component="h2">
                                <p className="text-base">
                                    Video Resume - <span className="text-gray-400">{videoName}</span>
                                </p>
                            </Typography>
                            <Typography className="space-x-4 mt-2">
                                <Button
                                    variant="contained"
                                    style={{ background: "#0865B6" }}
                                    className="bg-red-500 hover:bg-red-300 duration-300 text-white px-3 py-2 text-sm md:px-5 md:py-3 sm:text-sm md:text-base rounded-full"
                                    onClick={() => AddVideoResume()}
                                >
                                    Save
                                </Button>
                                <Button
                                    variant="contained"
                                    style={{ background: "tomato" }}
                                    className="bg-red-500 hover:bg-red-300 duration-300 text-white px-3 py-2 text-sm md:px-5 md:py-3 sm:text-sm md:text-base rounded-full"
                                    onClick={() => window.location.reload()}
                                >
                                    Cancel
                                </Button>
                            </Typography>
                        </Box>
                    </Fade>
                </Modal>

                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={open4}
                    onClose={handleClose4}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{ timeout: 500, }}
                >
                    <Fade in={open4}>
                        <Box sx={style}>
                            {/* <Typography id="transition-modal-title" variant="h6" component="h2">
                                Video Resume
                            </Typography>
                            <Input placeholder="Paste the video link" className="h-12 placeholder:text-gray-900 mt-4" />
                            <Typography id="transition-modal-title" style={{textAlign:"center", marginTop:10 }} variant="h6" component="h2">
                                OR
                            </Typography> */}
                            <Input
                                type="file"
                                accept="video/*"
                                style={{ padding: 10, marginTop:10 }}
                                onChange={(e) => {
                                    if (e.target.files[0].size > (30 * 1024)) {
                                        setVideoResume(e.target.files[0])
                                        setVideoName(e.target.files[0].name);
                                        AddVideoResume()
                                    } else {
                                        window.alert("File size is very large try with smaller file size")
                                    }
                                }}
                            />
                            <span className="text-blue-500 text-xs mt-1">Upload video up to 30MB</span>
                            

                            <Typography style={{ marginTop:15 }} className="space-x-4">
                                <Button
                                    variant="contained"
                                    style={{ background: "#0865B6" }}
                                    className="bg-red-500 hover:bg-red-300 duration-300 text-white px-3 py-2 text-sm md:px-5 md:py-3 sm:text-sm md:text-base rounded-full"
                                    onClick={() => UploadResume()}
                                >
                                    Save
                                </Button>
                                <Button
                                    variant="contained"
                                    style={{ background: "tomato" }}
                                    className="bg-red-500 hover:bg-red-300 duration-300 text-white px-3 py-2 text-sm md:px-5 md:py-3 sm:text-sm md:text-base rounded-full"
                                    onClick={() => window.location.reload()}
                                >
                                    Cancel
                                </Button>
                            </Typography>
                        </Box>
                    </Fade>
                </Modal>
            </div>

        </div>
    );
};

export default Resume;
