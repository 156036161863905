import React, { useState } from "react";
import { HTTPStatus } from "../../utils/helperObj";
import { Button, CircularProgress } from "@mui/material";
import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
} from "@mui/material";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";
import { signUp } from "../../controller/AuthController";
import { useNavigate } from "react-router";
import AuthPageLayout from "../../components/Layout/AuthPageLayout";
import { toastError } from "../../utils/Notification";
import useGuest from "../../hooks/guard/useGuest";
import { isValidEmail } from "../../utils/validation";
export default function SignUp() {
  useGuest();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const signupHandler = async () => {
    if (!isValidEmail(email)) {
      return toastError("Please enter valid Email Id")
    }

    if (password !== confirmPassword) {
      return toastError("Confirm password does not match")
    }
    setLoading(true);
    const res = await signUp(email, password);
    setLoading(false);
    if (res.statusCode === HTTPStatus.SUCCESS) {
      navigate("/email-verification");
    } else {
      toastError(res.errorMsg);
    }
  };

  return (
    <AuthPageLayout heading="Register">
      <TextField
        fullWidth
        type="email"
        label="Email ID"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        style={{ borderColor: "#0865B6" }}
      />

      <FormControl variant="outlined" fullWidth>
        <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
        <OutlinedInput
          label="Password"
          id="Password"
          type={showPassword ? "text" : "password"}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                type={showPassword ? "text" : "password"}
                aria-label="toggle password visibility"
                onClick={() => setShowPassword(!showPassword)}
                edge="end"
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          }
          required
        />
      </FormControl>

      <FormControl variant="outlined" fullWidth>
        <InputLabel htmlFor="outlined-adornment-password">
          Confirm Password
        </InputLabel>
        <OutlinedInput
          label="Confirm Password"
          id="confirm_password"
          type={showConfirmPassword ? "text" : "password"}
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                type={showConfirmPassword ? "text" : "password"}
                aria-label="toggle password visibility"
                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                edge="end"
              >
                {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          }
          required
        />
      </FormControl>

      <div className="mt-4">
        <Button
          onClick={signupHandler}
          fullWidth
          style={{ backgroundColor: "#0865B6", color: "white" }}
          disabled={loading}
        >
          {loading ? (
            <CircularProgress sx={{ color: "white" }} size="1.6rem" />
          ) : (
            "Register"
          )}
        </Button>
      </div>

      <div className="text-center">
        <Button
          fullWidth
          variant="outlined"
          style={{
            color: "rgb(1,1,1,0.6)",
            backgroundColor: "white",
            borderColor: "gray",
          }}
          onClick={() => navigate("/login")}
        >
          Already have an account? Sign In
        </Button>
      </div>
    </AuthPageLayout>
  );
}
