import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import React, { useCallback, useRef } from "react";
import { useState } from "react";
import { saveServeyAnswer } from "../../controller/serveyController";
import { useEffect } from "react";
import { HTTPStatus } from "../../utils/helperObj";
import { useNavigate } from "react-router";
import { handleControllerResultException } from "../../utils/dataFetchHelper";

/**
 * @typedef {object} Props
 * @property {import('../../model/ServeyModel').ServeyQuestionModel} question
 */

/**
 * @param {Props} props
 */

export default function QuestionRadioGroup({ question }) {
    const componentMounted = useRef(false);
    const timeoutRef = useRef(null);
    const [radioValue, setRadioValue] = useState(question.answer !== null ? question.answer : "");
    const navigate = useNavigate();

    const handleAnswer = useCallback(async (radioValue) => {

        const res = await saveServeyAnswer(
            question.id,
            radioValue,
            question.questionType
        );

        if (res.statusCode !== HTTPStatus.CREATED) {
            handleControllerResultException(res, navigate);
        }
    }, [navigate, question.id, question.questionType]);

    useEffect(() => {
        if (timeoutRef.current !== null) {
            clearTimeout(timeoutRef.current);
        }
        if (componentMounted.current) {
            timeoutRef.current = setTimeout(() => {
                handleAnswer(radioValue);
            }, "700");
        } else {
            componentMounted.current = true;
        }
        return () => {
            clearTimeout(timeoutRef.current);
        };
    }, [radioValue, handleAnswer]);

    return (
        <RadioGroup
            value={radioValue}
            onChange={(e) => setRadioValue(e.target.value)}
        >
            {question.options.map((data, index) => (
                <FormControlLabel
                    value={data}
                    key={index}
                    control={<Radio />}
                    label={data}
                />
            ))}
        </RadioGroup>
    );
}
