import TabPanelUnstyled from "@mui/base/TabPanelUnstyled";
import TabsListUnstyled from "@mui/base/TabsListUnstyled";
import TabsUnstyled from "@mui/base/TabsUnstyled";
import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router";
import { useNavigate } from "react-router-dom";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import AppliedJobs from "./AppliedJobs";
import Jobs from "./Jobs";
import Saved from "./Saved";

import { Close } from "@mui/icons-material";
import { Box } from "@mui/material";
import Cookies from "universal-cookie";
import JobMenu from "../../components/JobMenu";
import VerifiedRoute from "../../components/VerifiedRoute";
import JobDetail from "./JobDetail";
import Recommended from "./Recommended";
import SurveyForm from "./SurveyForm";
const cookies = new Cookies();

const Applications = () => {
    const [variable, setVariable] = useState("");
    const navigate = useNavigate();
    const [input, setInput] = useState("")

    useEffect(() => {
        if (cookies.get("token")) {
        } else {
            navigate("/");
        }
    }, []);

    return (
        <div className="bg-default-background h-screen overflow-auto">
            <Header />


            <Box marginTop={"5rem"}>
                <Sidebar />
                {/*<Sidebar2/>*/}
                <TabsUnstyled defaultValue={0} className="m-2">
                    <TabsListUnstyled className="bg-white rounded-lg flex items-center justify-between text-neutral-400 px-4 py-2">
                        <div className="hidden md:flex items-center space-x-6">
                            <div
                                className={` text-xl font-semibold cursor-pointer
                            ${window.location.pathname === "/applications/jobs"
                                        ? " text-sky-700"
                                        : ""
                                    }
                            ${window.location.pathname ===
                                        "/applications/job-detail"
                                        ? " text-sky-700"
                                        : ""
                                    }
                            ${window.location.pathname ===
                                        "/applications/job-detail/survey-form"
                                        ? " text-sky-700"
                                        : ""
                                    }`}
                                onClick={() => {
                                    navigate("jobs");
                                }}
                            >
                                <div className="grid py-2 place-items-center">All</div>
                            </div>
                            <div
                                className={`text-xl font-semibold cursor-pointer
                            ${window.location.pathname ===
                                        "/applications/recommended-jobs"
                                        ? " text-sky-700"
                                        : ""
                                    } `}
                                onClick={() => {
                                    navigate("recommended-jobs");
                                }}
                            >
                                <div className="grid py-2 place-items-center">
                                    Recommended
                                </div>
                            </div>
                            <div
                                className={`text-xl font-semibold cursor-pointer
                            ${window.location.pathname ===
                                        "/applications/applied-jobs"
                                        ? " text-sky-700"
                                        : ""
                                    } `}
                                onClick={() => {
                                    navigate("applied-jobs");
                                }}
                            >
                                <div className="grid py-2 place-items-center">Applied</div>
                            </div>
                            <div
                                className={`text-xl font-semibold cursor-pointer
                            ${window.location.pathname === "/applications/saved"
                                        ? " text-sky-700"
                                        : ""
                                    } `}
                                onClick={() => {
                                    navigate("saved");
                                }}
                            >
                                <div className="grid py-2 place-items-center">Saved</div>
                            </div>
                        </div>
                        <div className="md:hidden block">
                            <JobMenu />
                        </div>
                        <div className=" relative w-2/4  md:w-full md:max-w-lg">
                            <input
                                type="text"
                                value={input}
                                className="h-10 border border-gray-400  rounded pl-4 w-full  text-lg text-black"
                                placeholder="Search By Job Title"
                                onChange={(e) => setInput(e.target.value)}
                            />
                            <button
                                onClick={() => setInput("")}
                                className={`absolute right-2 top-2 text-[#7a7878] ${input ? "block" : "hidden"
                                    }`}
                            >
                                <Close className={`text-[#7a7878]`} />
                            </button>
                        </div>

                    </TabsListUnstyled>


                    <TabPanelUnstyled
                        className="w-full"
                        value={0}
                        onClick={(e) => setVariable(e.currentTarget.id)}
                    ></TabPanelUnstyled>

                    <TabPanelUnstyled
                        className="w-full"
                        value={1}
                        onClick={(e) => setVariable(e.currentTarget.id)}
                    ></TabPanelUnstyled>

                    <TabPanelUnstyled
                        className="w-full"
                        value={2}
                        onClick={(e) => setVariable(e.currentTarget.id)}
                    ></TabPanelUnstyled>
                    <Routes>
                        <Route path="recommended-jobs" element={<Recommended input={input} />} />
                        <Route path="jobs" element={<Jobs input={input} />} />
                        <Route
                            path="job-detail/:jobId"
                            element={<VerifiedRoute Component={JobDetail} />}
                        />
                        <Route
                            exact
                            path="job-detail/survey-form"
                            element={<SurveyForm />}
                        />
                        <Route path="applied-jobs" element={<AppliedJobs input={input} />} />
                        <Route path="saved" element={<Saved input={input} />} />
                    </Routes>
                </TabsUnstyled>
            </Box>
        </div>
    );
};

export default Applications;
