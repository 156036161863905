import ArticleIcon from "@mui/icons-material/Article";
import BusinessIcon from '@mui/icons-material/Business';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { AppBar, Avatar, Box, Card, CardContent, IconButton, Menu, MenuItem, Toolbar } from "@mui/material";
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import Spinner from '../../components/Spinner';
import image11 from "./Images/Image11.png";
import logo2 from "./Images/apple-icon-120x120.png";

function JobDetail() {
    const [data, setData] = useState([]);
    const [info, setInfo] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();

    const { id } = useParams();

    function getData() {
        axios.get("hri_admin/newposition-public/" + id)
            .then((res) => {
                setInfo(res.data)
                setIsLoading(false)
            }).catch((err) => {
                if (err.response.status === 404) {
                    navigate("/pages/not-found")
                }

            })
    }


    useEffect(() => {
        getData();

    }, [])

    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [anchorElUser, setAnchorElUser] = React.useState(null);

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    return (
        <div className=''>
            <div className='my-[10vh] bg-slate-100'>
                <Box sx={{ flexGrow: 1 }}>
                    <AppBar position="fixed" className="bg-white rounded-b-xl py-2 shadow" elevation={0}>
                        <Toolbar className="rounded-b-xl" >
                            {data.length > 0 ? data.map((info) => (
                                <div key={info.id} className="flex">
                                    <img src={info.logo} className="h-8 lg:h-10" alt="" />
                                    <div className="flex-grow">
                                        <p className="font-semibold text-base md:text-xl lg:text-2xl  ml-4 text-[#1E66B1]">
                                            {info.name}
                                        </p>
                                        <p className="text-[0rem] md:text-sm md:w-48 ml-4 text-[#72C9B9]">
                                            @MyBizmo (Biz Landing Page)
                                        </p>
                                    </div>
                                </div>
                            )) :

                                <div className="flex">
                                    <div>
                                        <img src={logo2} className="h-8 lg:h-10 w-8 lg:w-10 rounded-full" alt="" />
                                    </div>
                                    <p className="font-semibold text-base md:text-xl lg:text-2xl  ml-4 text-[#1E66B1]">
                                        HR Intelligence
                                    </p>
                                </div>
                            }

                            <div className="text-[#1E66B1]  font-semibold ml-auto">

                                <Box sx={{ flexGrow: 1, display: { xs: 'flex', sm: 'none' } }}>
                                    <IconButton
                                        size="large"
                                        aria-label="account of current user"
                                        aria-controls="menu-appbar"
                                        aria-haspopup="true"
                                        onClick={handleOpenNavMenu}
                                        color="inherit"
                                    >
                                        <KeyboardArrowDownIcon />
                                    </IconButton>
                                    <Menu
                                        id="menu-appbar"
                                        anchorEl={anchorElNav}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'left',
                                        }}
                                        keepMounted
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'left',
                                        }}
                                        open={Boolean(anchorElNav)}
                                        onClose={handleCloseNavMenu}
                                        sx={{
                                            display: { xs: 'block', sm: 'none' },
                                        }}
                                    >
                                        {/* <a href="#home"><MenuItem onClick={handleCloseNavMenu}>Home</MenuItem></a>
                                    <a href="#services"><MenuItem
                                        onClick={handleCloseNavMenu}>Services</MenuItem></a> */}
                                        <a href="/"><MenuItem onClick={handleCloseNavMenu}>Go To Home</MenuItem></a>
                                        <a href="/login"><MenuItem onClick={handleCloseNavMenu}>Sign In/ Sign Up</MenuItem></a>
                                    </Menu>
                                </Box>

                                <Box sx={{ flexGrow: 1, display: { xs: 'none', sm: 'flex' } }}>
                                    {/* <a href="#home" className="mt-2 mr-4 lg:mr-5 md:text-base lg:text-xl">
                                    Home
                                </a>
                                <a href="#services" className="mt-2 mr-4 lg:mr-5 md:text-base lg:text-xl">
                                    Services
                                </a>
                                <a href="#jobs" className="mt-2 mr-4 lg:mr-5 md:text-base lg:text-xl">
                                    Jobs
                                </a>
                                <a href="#about_hri" className="mt-2 mr-4 lg:mr-5 md:text-base lg:text-xl">
                                    About HRI
                                </a> */}
                                    <span
                                        className="text-[#1E66B1] bg-[#F2F2F2] px-2 py-1 lg:px-5 lg:py-2 md:text-base lg:text-xl rounded-full cursor-pointer hover:bg-neutral-200 md:mr-4"
                                        onClick={() => navigate(-1)}
                                    >
                                        Go to Home
                                    </span>
                                    <span
                                        className="text-[#1E66B1] bg-[#F2F2F2] px-2 py-1 lg:px-5 lg:py-2 md:text-base lg:text-xl rounded-full cursor-pointer hover:bg-neutral-200"
                                        onClick={() => navigate("/login")}
                                    >
                                        Sign In/ Sign Up
                                    </span>
                                </Box>
                            </div>
                        </Toolbar>

                    </AppBar>
                </Box>
            </div>

            <div className="col-span-1 py-10  bg-slate-100 px-10">
                {isLoading ? <div><Spinner /></div>
                    :
                    <Card elevation={0} sx={{ borderRadius: "0.625rem" }}>
                        <CardContent>

                            <div className="flex flex-row px-3 gap-4">
                                <Avatar
                                    src={image11}
                                    sx={{ height: "60px", width: "60px" }}
                                />

                                <div className='flex flex-col justify-center'>
                                    <div className="font-bold text-2xl md:text-4xl">
                                        {info.position_name}
                                    </div>
                                    <div className='text-xl md:text-2xl'>
                                        {info.company_name}
                                    </div>
                                </div>
                            </div>

                            {/*md:grid-cols-10*/}

                            <div className="grid mt-3 px-10
                                        grid-cols-5 gap-3
                                        md:grid-cols-12">
                                <div className="col-span-5 md:col-span-3 text-xl md:text-2xl">
                                    <p>
                                        <BusinessCenterIcon className="text-[#A9A9A9] mb-1 mr-2 scale-150"
                                            fontSize="small" />
                                        {info.experience}
                                    </p>
                                </div>
                                <div className=" text-xl md:text-2xl col-span-5 md:col-span-5 lg:col-span-4">
                                    <p>
                                        <LocalAtmIcon className="text-[#A9A9A9] mb-1  scale-150 mr-2"
                                            fontSize="small" />
                                        {info.stipend}
                                    </p>
                                </div>
                                <div className=" text-xl md:text-2xl col-span-5 md:col-span-8 lg:col-span-5">
                                    <p>
                                        <BusinessIcon className="text-[#A9A9A9]  scale-150 mb-1 mr-2"
                                            fontSize="small" />
                                        {info.employment_type}
                                    </p>
                                </div>

                                <div className=" text-xl md:text-2xl col-span-5 md:col-span-8 lg:col-span-12">
                                    <p>
                                        <LocationOnIcon className="text-[#A9A9A9]  scale-150 mb-1 mr-2"
                                            fontSize="small" />
                                        {info.location}
                                    </p>
                                </div>

                                <div className=" text-xl md:text-2xl col-span-5 md:col-span-4">
                                    <ArticleIcon className="text-[#A9A9A9] mb-1  scale-150 mr-2" fontSize="small" />
                                    {info.criteria}
                                </div>
                                <div className=" text-xl md:text-2xl col-span-5 md:col-span-8">
                                    <p>
                                        <LeaderboardIcon className="text-[#A9A9A9]  scale-150 mb-1 mr-2"
                                            fontSize="small" />
                                        {info.skills}
                                    </p>
                                </div>
                            </div>

                            <div className="px-3 pt-4 grid grid-cols-2">

                                <p className="pt-2 text-sm md:text-lg text-[#A9A9A9]">
                                    {/* Updated at: {info.updated_at.slice(0, 10)} */}
                                </p>


                                <div align="right" className='md:mx-20 mx-[auto]'>
                                    <p
                                        className="md:text-2xl px-3 py-2 w-24 bg-[#0865B6] hover:bg-[#0a6bc0] shadow-lg hover:shadow-xl shadow-gray-500 text-white cursor-pointer
                                                         text-center rounded-md font-semibold md:w-36 md:px-6 md:py-3"
                                        onClick={() => navigate("/login")}
                                    >
                                        Apply Now
                                    </p>
                                </div>

                            </div>

                        </CardContent>
                    </Card>
                }
            </div>

        </div>
    )
}

export default JobDetail
