import FileUploadIcon from "@mui/icons-material/FileUpload";
import { Button, TextField } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import Cookies from "universal-cookie";
import { base_url } from "../../components/consts";

const cookies = new Cookies();

const SurveyForm = () => {
  const navigate = useNavigate();

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [questions, setQuestions] = useState([]);

  const [questionId, setQuestionId] = useState("");
  const [answer, setAnswer] = useState("");
  const [answerMedia, setAnswerMedia] = useState([]);

  const getQuestions = () => {
    axios
      .get(`survey/assignment-detail/${localStorage.getItem("AssessmentID")}`, {
        headers: {
          Authorization: "Token " + cookies.get("token"),
        },
      })
      .then((res) => {
        setTitle(res.data.name);
        setDescription(res.data.description);
        setQuestions(res.data.questions);
      })
      .catch((err) => { });
  };

  const postAnswer = () => {
    let formField = new FormData();

    formField.append("question", questionId);
    formField.append("answer", answer);
    if (answerMedia) {
      formField.append("answer_media", answerMedia);
    }

    axios
      .post(`survey/answer-create`, formField, {
        headers: {
          Authorization: "Token " + cookies.get("token"),
        },
      })
      .then((res) => {
        toast.success(`Answer Submitted`, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          style: { backgroundColor: "#1ab394", color: "white" },
          icon: false,
          progressStyle: { backgroundColor: "#BAE8DE" },
        });
        setQuestionId(null);
        setAnswer(null);
        setAnswerMedia(null);
      })
      .catch((err) => { });
  };

  const position = {
    position: localStorage.getItem("JobId"),
  };

  const ApplyForJob = () => {
    axios
      .post(`${base_url}/profile/apply`, position, {
        headers: {
          Authorization: "Token " + cookies.get("token"),
        },
      })
      .then((res) => {
        navigate("/applications/applied-jobs");
        toast.success("Applied For the Job", {
          position: toast.POSITION.TOP_CENTER,
          setTimeout: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          style: { backgroundColor: "#1ab394", color: "white" },
          icon: false,
          progressStyle: { backgroundColor: "#BAE8DE" },
        });
      })
      .catch((err) => { });
  };

  useEffect(() => {
    getQuestions();
  }, []);

  return (
    <div className="bg-white rounded-lg mx-2 mt-2 mb-5 p-4">
      <p className="text-[32px]">{title}</p>

      <p className="text-[#686868]">{description}</p>

      {questions.map((data) => {
        const questionType = data.question_type;

        return (
          <div key={data.id}>
            {questionType === "TextField" ? (
              <div>
                <div className="bg-[#F2F2F2] rounded-md mr-0 ml-0 mt-5 p-4 md:mr-12 md:ml-12 lg:mr-20 lg:ml-20">
                  <p className="text-lg">
                    Q{data.id}. {data.question}
                  </p>

                  <div className="mt-4 space-y-4">
                    <TextField
                      fullWidth
                      label={`Question ${data.id}`}
                      onChange={(e) => {
                        setAnswer(e.target.value);
                        setQuestionId(data.id);
                        setAnswerMedia(null);
                      }}
                    />
                  </div>
                  <div align="right">
                    <Button
                      variant="contained"
                      className="mt-4"
                      onClick={postAnswer}
                    >
                      Submit
                    </Button>
                  </div>
                </div>
              </div>
            ) : questionType === "ChoiceField" ? (
              <div>
                <div className="bg-[#F2F2F2] rounded-md mr-0 ml-0 mt-5 p-4 md:mr-12 md:ml-12 lg:mr-20 lg:ml-20">
                  <p className="text-lg">
                    Q{data.id}. {data.question}
                  </p>

                  <FormControl>
                    {/*<FormLabel id="demo-row-radio-buttons-group-label">Gender</FormLabel>*/}
                    <RadioGroup
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                    >
                      {data.options.split(",").map((item) => (
                        <div
                          key={item}
                          className="bg-white border text-lg mt-3 rounded-md px-3 py-1.5"
                        >
                          <FormControlLabel
                            value={item}
                            control={<Radio />}
                            label={item}
                            onClick={(e) => {
                              setAnswer(item);
                              setQuestionId(data.id);
                              setAnswerMedia(null);
                            }}
                          />
                        </div>
                      ))}
                    </RadioGroup>
                  </FormControl>
                  <div align="right">
                    <Button
                      variant="contained"
                      className="mt-4"
                      onClick={postAnswer}
                    >
                      Submit
                    </Button>
                  </div>
                </div>
              </div>
            ) : questionType === "FileField" ? (
              <div>
                <div className="bg-[#F2F2F2] rounded-md mr-0 ml-0 mt-5 p-4 md:mr-12 md:ml-12 lg:mr-20 lg:ml-20">
                  <p className="text-lg">
                    Q{data.id}. {data.question}
                  </p>

                  <div className="flex">
                    <div className="flex-grow">
                      <Button
                        variant="contained"
                        component="label"
                        startIcon={<FileUploadIcon />}
                      >
                        Upload File
                        <input
                          hidden
                          type="file"
                          accept="*"
                          onChange={(e) => {
                            setAnswerMedia(e.target.files[0]);
                            setQuestionId(data.id);
                            setAnswer(null);
                          }}
                        />
                      </Button>
                    </div>

                    <Button variant="contained" onClick={postAnswer}>
                      Submit
                    </Button>
                  </div>
                </div>
              </div>
            ) : questionType === "ImageField" ? (
              <div>
                <div className="bg-[#F2F2F2] rounded-md mr-0 ml-0 mt-5 p-4 md:mr-12 md:ml-12 lg:mr-20 lg:ml-20">
                  <p className="text-lg">
                    Q{data.id}. {data.question}
                  </p>

                  <div className="flex">
                    <div className="flex-grow">
                      <Button
                        variant="contained"
                        component="label"
                        startIcon={<FileUploadIcon />}
                      >
                        Upload Image
                        <input
                          hidden
                          accept="image/*"
                          type="file"
                          onChange={(e) => {
                            setAnswerMedia(e.target.files[0]);
                            setQuestionId(data.id);
                            setAnswer(null);
                          }}
                        />
                      </Button>
                    </div>

                    <Button variant="contained" onClick={postAnswer}>
                      Submit
                    </Button>
                  </div>
                </div>
              </div>
            ) : questionType === "VideoField" ? (
              <div>
                <div className="bg-[#F2F2F2] rounded-md mr-0 ml-0 mt-5 p-4 md:mr-12 md:ml-12 lg:mr-20 lg:ml-20">
                  <p className="text-lg">
                    Q{data.id}. {data.question}
                  </p>

                  <div className="flex">
                    <div className="flex-grow">
                      <Button
                        variant="contained"
                        component="label"
                        startIcon={<FileUploadIcon />}
                      >
                        Upload Video
                        <input
                          hidden
                          accept="video/mp4"
                          type="file"
                          onChange={(e) => {
                            setAnswerMedia(e.target.files[0]);
                            setQuestionId(data.id);
                            setAnswer(null);
                          }}
                        />
                      </Button>
                    </div>

                    <Button variant="contained" onClick={postAnswer}>
                      Submit
                    </Button>
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        );
      })}
      <div className="text-right my-4 mx-20">
        <Button variant="contained" onClick={ApplyForJob}>
          Apply
        </Button>
      </div>
    </div>
  );
};

export default SurveyForm;
