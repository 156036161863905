/* eslint-disable jsx-a11y/alt-text */
import { Button } from "@mui/material";
import { Link } from "react-router-dom";

export function EmptyState({ message, title, img, btn, btnLink }) {
  return (
    <div
      className="bg-white flex flex-col p-4"
      style={{
        justifyContent: "center",
        alignItems: "center",
        borderRadius: 15,
      }}
    >
      <img src={img} style={{ maxWidth: 180 }} />

      <h4 style={{ textAlign: "center" }}>{title}</h4>
      <p style={{ width: 350, textAlign: "center" }}>{message}</p>

      {btn && (
        <Link to={btnLink}>
          <Button
            style={{
              backgroundColor: "#3D9BFF",
              color: "#fff",
              borderRadius: 20,
              paddingInline: 10,
              fontSize: 12,
              marginTop: 20,
            }}
          >
            {btn}
          </Button>
        </Link>
      )}
    </div>
  );
}
