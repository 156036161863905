import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import {
    Button,
    Card,
    CardContent,
    FormControl,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    TextField
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { base_url } from "../../components/consts";

import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import WarningModal from '../../components/WarningModal';

const cookies = new Cookies();

const Settings = () => {

    const navigate = useNavigate();

    const [active, setActive] = useState("btn1")
    const [oldPassword, setOldPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [openModal, setOpenModal] = useState(false);


    const data = {
        old_password: oldPassword,
        new_password: newPassword
    }

    const ChangePassword = () => {
        axios
            .post(
                `${base_url}/auth/user/changepassword`,
                data,
                {
                    headers: {
                        Authorization: "Token " + cookies.get("token")
                    },
                },
            )
            .then((res) => {

                window.location.reload();
                setOpenModal(false)
                toast.success("Successfully Changed Password", {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    style: { backgroundColor: "#1ab394", color: "white" },
                    icon: false,
                    progressStyle: { backgroundColor: "#BAE8DE" },
                });
            })
            .catch((err) => {

                if (oldPassword === "") {
                    setOpenModal(false)
                    toast.error("Please enter the old password", {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        style: { backgroundColor: "#FF6347", color: "white" },
                        icon: false,
                        progressStyle: { backgroundColor: "#FFB1A3" },
                    });
                } else if (newPassword === "") {
                    toast.error("Please enter the new password", {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        style: { backgroundColor: "#FF6347", color: "white" },
                        icon: false,
                        progressStyle: { backgroundColor: "#FFB1A3" },
                    });
                } else {
                    toast.error("Error", {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        style: { backgroundColor: "#FF6347", color: "white" },
                        icon: false,
                        progressStyle: { backgroundColor: "#FFB1A3" },
                    });
                }
            });
    }


    const [values, setValues] = React.useState({
        amount: '',
        password: '',
        weight: '',
        weightRange: '',
        showPassword: false,
    });


    const handleClickShowPassword = () => {
        setValues({
            ...values,
            showPassword: !values.showPassword,
        });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleClickShowPassword2 = () => {
        setValues({
            ...values,
            showPassword2: !values.showPassword2,
        });
    };

    const handleMouseDownPassword2 = (event) => {
        event.preventDefault();
    };

    const handleClickShowPassword3 = () => {
        setValues({
            ...values,
            showPassword3: !values.showPassword3,
        });
    };

    const handleMouseDownPassword3 = (event) => {
        event.preventDefault();
    };

    const Checking_Password = function () {
        if (document.getElementById("new_password").value === document.getElementById("confirm_password").value) {

            ChangePassword();
        } else if ((document.getElementById("confirm_password").value) === "") {
            toast.error("Please enter confirm password", {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                style: { backgroundColor: "#FF6347", color: "white" },
                icon: false,
                progressStyle: { backgroundColor: "#FFB1A3" },
            });
        } else {
            toast.error("New Password does not match", {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                style: { backgroundColor: "#FF6347", color: "white" },
                icon: false,
                progressStyle: { backgroundColor: "#FFB1A3" },
            });
        }

    }

    //Forgot password
    const [userEmail, setUserEmail] = useState("");
    const [otp, setOtp] = useState("");
    const [newForgetPassword, setNewForgetPassword] = useState("");

    const dataToForgetPassword = {
        email: userEmail,
    };

    const ForgotPassword = (e) => {
        e.preventDefault();

        axios
            .post
            (
                `${base_url}/auth/user/forgot/password`,
                dataToForgetPassword,
                {
                    headers: {
                        Authorization: "Token " + cookies.get("token"),
                    },
                }
            )
            .then((res) => {

                setOtp(res.data.otp);
                setActive("btn3");
                toast.success(`Your OTP for forget password has been sent to your email id`, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    style: { backgroundColor: "#1ab394", color: "white" },
                    icon: false,
                    progressStyle: { backgroundColor: "#BAE8DE" },
                });
            })
            .catch((err) => {

                if (userEmail === "") {
                    toast.error("Please Enter Email id", {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        style: { backgroundColor: "#FF6347", color: "white" },
                        icon: false,
                        progressStyle: { backgroundColor: "#FFB1A3" },
                    });
                } else {
                    toast.error("Please Enter Correct Email", {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        style: { backgroundColor: "#FF6347", color: "white" },
                        icon: false,
                        progressStyle: { backgroundColor: "#FFB1A3" },
                    });
                }
            });
    };

    //Verify OTP
    const dataToVerifyOtp = {
        email: userEmail,
        otp: otp,
    };

    const VerifyOtp = (e) => {
        e.preventDefault();

        axios
            .post
            (
                `${base_url}/auth/user/forgot/password/verify`,
                dataToVerifyOtp,
                {
                    headers: {
                        Authorization: "Token " + cookies.get("token"),
                    },
                }
            )
            .then((res) => {

                setActive("btn4");
                toast.success(`${res.data.message}`, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    style: { backgroundColor: "#1ab394", color: "white" },
                    icon: false,
                    progressStyle: { backgroundColor: "#BAE8DE" },
                });
            })
            .catch((err) => {

                toast.error("Error verifying OTP", {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    style: { backgroundColor: "#FF6347", color: "white" },
                    icon: false,
                    progressStyle: { backgroundColor: "#FFB1A3" },
                });
            });
    };

    //Reset Password
    const dataToResetPassword = {
        password: newForgetPassword
    };

    const ResetPassword = (e) => {
        e.preventDefault();

        axios
            .post(
                `${base_url}/auth/user/reset/password`,
                dataToResetPassword,
                {
                    headers: {
                        Authorization: "Token " + cookies.get("token"),
                    },
                }
            )
            .then((res) => {

                setActive("btn1");
                toast.success(`Password changed Successfully`, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    style: { backgroundColor: "#1ab394", color: "white" },
                    icon: false,
                    progressStyle: { backgroundColor: "#BAE8DE" },
                });
            })
            .catch((err) => {

                toast.error("Error occurred while changing password", {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    style: { backgroundColor: "#FF6347", color: "white" },
                    icon: false,
                    progressStyle: { backgroundColor: "#FFB1A3" },
                });
            });
    };

    useEffect(() => {
        if (cookies.get("token")) {

        } else {
            navigate("/");
        }
    }, []);

    return (
        <div>

{active === "btn1" ? 
    <div className="flex justify-center items-center h-full">
        <div className="max-w-xl w-full">
            <div className="card rounded-lg bg-white shadow-md p-6 mt-11">
                <div className="text-center mb-6">
                    <h2 className="text-2xl font-semibold text-sky-700">Change Password</h2>
                </div>
                <div className="space-y-4">
                    <div>
                        <FormControl variant="outlined" fullWidth>
                            <InputLabel htmlFor="outlined-adornment-password">Current Password</InputLabel>
                            <OutlinedInput
                                label="Current Password"
                                type={values.showPassword ? 'text' : 'password'}
                                onChange={(e) => setOldPassword(e.target.value)}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                        >
                                            {values.showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                required
                            />
                        </FormControl>
                    </div>
                    <div>
                        <FormControl variant="outlined" fullWidth>
                            <InputLabel htmlFor="outlined-adornment-password">New Password</InputLabel>
                            <OutlinedInput
                                label="New Password"
                                type={values.showPassword2 ? 'text' : 'password'}
                                onChange={(e) => setNewPassword(e.target.value)}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={handleClickShowPassword2}
                                            onMouseDown={handleMouseDownPassword2}
                                            edge="end"
                                        >
                                            {values.showPassword2 ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                required
                            />
                        </FormControl>
                    </div>
                    <div>
                        <FormControl variant="outlined" fullWidth>
                            <InputLabel htmlFor="outlined-adornment-password">Confirm Password</InputLabel>
                            <OutlinedInput
                                label="Confirm Password"
                                type={values.showPassword3 ? 'text' : 'password'}
                                onChange={(e) => setNewPassword(e.target.value)}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={handleClickShowPassword3}
                                            onMouseDown={handleMouseDownPassword3}
                                            edge="end"
                                        >
                                            {values.showPassword3 ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                required
                            />
                        </FormControl>
                    </div>
                </div>
                <div className="flex justify-between mt-6">
                    <Button
                        className="mr-4"
                        onClick={() => setOpenModal(true)}
                        variant="contained"
                        color="primary"
                    >
                        Save
                    </Button>
                    <Button
                        onClick={() => setActive("btn2")}
                        variant="outlined"
                    >
                        Forgot Password
                    </Button>
                </div>
            </div>
        </div>
    </div> 
: <></>}


            {active === "btn2" ? <div>
                <Card elevation={0} className="m-3 mt-3" style={{ borderRadius: "8px" }}>
                    <CardContent className=" mx-4 mb-4 mt-2">
                        <div className="text-2xl font-semibold text-sky-700">Forget Password</div>

                        <div className="my-4 md:w-1/2 lg:w-2/6">
                            <TextField
                                fullWidth
                                label="Email ID"
                                type="email"
                                value={userEmail}
                                onChange={(e) => setUserEmail(e.target.value)}
                                helperText="Please enter your Email ID"
                            />
                        </div>
                        <div className="sm:space-x-2 md:space-x-4 lg:space-x-4">
                            <Button
                                className="py-2 px-4 m-2"
                                style={{
                                    backgroundColor: "#F1F1F1",
                                    color: "#0865B6",
                                    fontSize: "13px",
                                    borderRadius: "0.4rem"
                                }}
                                onClick={ForgotPassword}
                            >
                                Next
                            </Button>

                            <Button
                                className="py-2 px-4 m-2"
                                style={{
                                    backgroundColor: "#F1F1F1",
                                    color: "#0865B6",
                                    fontSize: "13px",
                                    borderRadius: "0.4rem"
                                }}
                                onClick={() => setActive("btn1")}
                            >
                                Cancel
                            </Button>
                        </div>

                    </CardContent>
                </Card>
            </div> : <></>}

            {active === "btn3" ? <div>
                <Card elevation={0} className="m-3 mt-3" style={{ borderRadius: "8px" }}>
                    <CardContent className=" mx-4 mb-4 mt-2">
                        <div className="text-2xl font-semibold text-sky-700">Verify OTP</div>

                        <div className="my-4 md:w-1/2 lg:w-2/6">
                            <TextField
                                fullWidth
                                label="OTP"
                                type="number"
                                helperText="Enter OTP here to verify"
                                // value={otp}
                                onChange={(e) => setOtp(e.target.value)}
                            />
                        </div>

                        <div className="sm:space-x-2 md:space-x-4 lg:space-x-4">
                            <Button
                                className="py-2 px-4 m-2"
                                style={{
                                    backgroundColor: "#F1F1F1",
                                    color: "#0865B6",
                                    fontSize: "13px",
                                    borderRadius: "0.4rem"
                                }}
                                onClick={VerifyOtp}
                            >
                                Verify
                            </Button>

                            <Button
                                className="py-2 px-4 m-2"
                                style={{
                                    backgroundColor: "#F1F1F1",
                                    color: "#0865B6",
                                    fontSize: "13px",
                                    borderRadius: "0.4rem"
                                }}
                                onClick={() => setActive("btn1")}
                            >
                                Cancel
                            </Button>
                        </div>
                    </CardContent>
                </Card>
            </div> : <></>}

            {active === "btn4" ? <div>
                <Card elevation={0} className="m-3 mt-3" style={{ borderRadius: "8px" }}>
                    <CardContent className=" mx-4 mb-4 mt-2">
                        <div className="text-2xl font-semibold text-sky-700">Enter New password</div>

                        <div className="my-4 md:w-1/2 lg:w-2/6">
                            <TextField
                                fullWidth
                                label="Enter Your New Password"
                                type="email"
                                value={newForgetPassword}
                                onChange={(e) => setNewForgetPassword(e.target.value)}
                            />
                        </div>
                        <div className="sm:space-x-2 md:space-x-4 lg:space-x-4">
                            <Button
                                className="py-2 px-4 m-2"
                                style={{
                                    backgroundColor: "#F1F1F1",
                                    color: "#0865B6",
                                    fontSize: "13px",
                                    borderRadius: "0.4rem"
                                }}
                                onClick={ResetPassword}
                            >
                                Reset
                            </Button>

                            <Button
                                className="py-2 px-4 m-2"
                                style={{
                                    backgroundColor: "#F1F1F1",
                                    color: "#0865B6",
                                    fontSize: "13px",
                                    borderRadius: "0.4rem"
                                }}
                                onClick={() => setActive("btn1")}
                            >
                                Cancel
                            </Button>
                        </div>
                    </CardContent>
                </Card>
            </div> : <></>}

        </div>
    )
}

export default Settings;