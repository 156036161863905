import { ArrowBackIos } from '@mui/icons-material'
import { Button } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router'


/**
 * @typedef {object} Props
 * @property {string} returnText
 */


/**
 * @param {Props} props
 */
export default function ReturnButton({ returnText = "Return" }) {
    const navigate = useNavigate()
    return (
        <Button onClick={() => navigate(-1)}>
            <ArrowBackIos fontSize='small' />
            {returnText}
        </Button>
    )
}
