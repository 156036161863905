import CloseIcon from "@mui/icons-material/Close";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import { MobileDatePicker } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { Button } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import axios from "axios";
import moment from "moment";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import Cookies from "universal-cookie";
import { isValidWorkExp } from "../../../../helpers/validate_workexp";
import { handleExceptionMessage } from "../../../../utils/dataFetchHelper";

const cookies = new Cookies();

const WorkExperienceAdd = () => {
  const navigate = useNavigate();

  const [variable, setVariable] = useState({
    position: "",
    company: "",
    city: "",
    country: "",
    start_date: null,
    end_date: null,
    status: "",
    responsibilities: "",
  });

  const startDate = moment(variable.start_date).format("YYYY-MM-DD");
  const endDate = moment(variable.end_date).format("YYYY-MM-DD");

  const data = {
    company_name: variable.company,
    position: variable.position,
    job_status: variable.status,
    city: variable.city,
    country: variable.country,
    start_date: startDate,
    is_completed: variable.status === "Completed" ? true : false,
    end_date: endDate,
    description: variable.responsibilities,
  };



  const addExperience = () => {

    const error = isValidWorkExp(data);
    if (error) return toast.error(error);

    if (startDate < endDate) {
      if (variable.status === "Completed" && endDate === "Invalid date") {
        return toast.error("Please Provide the End Date ", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          style: { backgroundColor: "#FF6347", color: "white" },
          icon: false,
          progressStyle: { backgroundColor: "#FFB1A3" },
        });
      }
      axios
        .post(`/profile/workExperience/add`, data, {
          headers: {
            Authorization: "Token " + cookies.get("token"),
          },
        })
        .then((res) => {
          navigate("/profile/professional-details/work-experience");
          toast.success("Sucessfully Added Work-Experience", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            style: { backgroundColor: "#1ab394", color: "white" },
            icon: false,
            progressStyle: { backgroundColor: "#BAE8DE" },
          });
        })
        .catch((err) => {
          toast.error(handleExceptionMessage(err), {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            style: { backgroundColor: "#FF6347", color: "white" },
            icon: false,
            progressStyle: { backgroundColor: "#FFB1A3" },
          });
        });
    }
    else {
      toast.error("End date cannot be before Start date", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        style: { backgroundColor: "#FF6347", color: "white" },
        icon: false,
        progressStyle: { backgroundColor: "#FFB1A3" },
      });
    }

  };

  return (
    <div>
      <div className="card text-dark bg-white my-4 border-none rounded-lg">
        <div className="card-body">
          <div className="flex items-center justify-between">
            <h1 className="text-lg font-bold text-[#0865B6]">
              Add Work Experience
            </h1>
            {/*<div>*/}
            {/*    <Button*/}
            {/*        variant="contained"*/}
            {/*        className="text-base bg-[#F1F1F1] px-3 py-1 font-semibold"*/}
            {/*        onClick={() => navigate(-1)}*/}
            {/*    >*/}
            {/*        Discard*/}
            {/*    </Button>*/}
            {/*</div>*/}
          </div>

          <div className="m-2 pt-4 space-y-3">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
              <div>
                <TextField
                  fullWidth
                  label="Job Position"
                  value={variable.position}
                  required
                  onChange={(e) =>
                    setVariable({ ...variable, position: e.target.value })
                  }
                  type="text"
                // placeholder="Enter your job role / position"
                // className="outline-none border-gray-500 border-[1px] rounded-md p-2"
                />
              </div>
              <div>
                <TextField
                  fullWidth
                  label="Company Name"
                  required
                  value={variable.company}
                  onChange={(e) =>
                    setVariable({ ...variable, company: e.target.value })
                  }
                  type="text"
                // placeholder="Enter the name of the company"
                // className="outline-none border-gray-500 border-[1px] rounded-md p-2"
                />
              </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
              <div>
                <TextField
                  fullWidth
                  label="City"
                  required
                  value={variable.city}
                  onChange={(e) =>
                    setVariable({ ...variable, city: e.target.value })
                  }
                  type="text"
                  placeholder="Enter your work city"
                // className="outline-none border-gray-500 border-[1px] rounded-md p-2"
                />
              </div>
              <div>
                <TextField
                  fullWidth
                  label="Country"
                  value={variable.country}
                  required
                  onChange={(e) =>
                    setVariable({ ...variable, country: e.target.value })
                  }
                  type="text"
                  placeholder="Please select your country"
                // className="outline-none border-gray-500 border-[1px] rounded-md p-2"
                />
              </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
              <div className="col-span-1">
                <FormControl fullWidth>
                  <InputLabel id="demo-customized-select-label">
                    Job Status
                  </InputLabel>
                  <Select
                    label="Job Status"
                    required
                    labelId="demo-customized-select-label"
                    id="demo-customized-select"
                    value={variable.status}
                    onChange={(e) =>
                      setVariable({ ...variable, status: e.target.value })
                    }
                  // input={<BootstrapInput/>}
                  >
                    <MenuItem value={"On-Going"}>On-Going</MenuItem>
                    <MenuItem value={"Completed"}>Completed</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className="flex flex-col">
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <MobileDatePicker
                    label="Start Date"
                    openTo="day"
                    required
                    views={["year", "month", "day"]}
                    disableFuture
                    value={variable.start_date}
                    onChange={(newValue) =>
                      setVariable({
                        ...variable,
                        start_date: moment(newValue).format("YYYY-MM-DD"),
                      })
                    }
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </div>
            </div>

            {variable.status === "Completed" && (
              <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
                <div className="flex flex-col col-span-1">
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <MobileDatePicker
                      label="End Date"
                      openTo="day"
                      required
                      views={["year", "month", "day"]}
                      disableFuture
                      value={variable.end_date}
                      onChange={(newValue) =>
                        setVariable({
                          ...variable,
                          end_date: moment(newValue).format("YYYY-MM-DD"),
                        })
                      }
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                </div>
              </div>
            )}

            <div className="flex flex-col space-y-1 col-span-2">
              <TextField
                label="Job Responsibilities"
                value={variable.responsibilities}
                required
                onChange={(e) =>
                  setVariable({
                    ...variable,
                    responsibilities: e.target.value,
                  })
                }
                rows="2"
                // cols="50"
                // className="outline-none border-gray-500 border-[1px] rounded-md p-2"
                multiline
              />
            </div>
          </div>

          <div className="my-4 space-x-5">
            <Button
              className={`text-lg rounded-lg px-4 py-2 font-semibold duration-300 ${variable.position === "" ||
                variable.country === "" ||
                variable.company === "" ||
                variable.city === "" ||
                variable.start_date === "" ||
                variable.end_date === "" ||
                variable.status === "" ||
                variable.responsibilities === ""
                ? "opacity-50"
                : "opacity-100"
                }`}
              disabled={
                variable.position === "" ||
                  variable.country === "" ||
                  variable.company === "" ||
                  variable.city === "" ||
                  variable.start_date === "" ||
                  variable.end_date === "" ||
                  variable.status === "" ||
                  variable.responsibilities === ""
                  ? true
                  : false
              }
              startIcon={<DoneAllIcon />}
              onClick={addExperience}
              style={{
                background: "#F1F1F1",
                borderRadius: "0.5rem",
                color: "#0865B6",
              }}
            >
              Save
            </Button>
            <Button
              onClick={() =>
                navigate("/profile/professional-details/work-experience")
              }
              className={`text-lg bg-[#F1F1F1] rounded-lg px-4 py-2 font-semibold text-[#0865B6] duration-300 `}
              style={{
                background: "#F1F1F1",
                borderRadius: "0.5rem",
                color: "#0865B6",
              }}
              startIcon={<CloseIcon />}
            // disabled={variable.position === "" || variable.country === "" || variable.company === "" || variable.city === "" || variable.start_date === "" || variable.end_date === "" || variable.status === "" || variable.responsibilities === "" ? true : false}
            >
              Cancel
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorkExperienceAdd;