import { Button, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";

import {
  resentEmailVerificationOtp,
  verifyEmail,
} from "../../controller/AuthController";
import { HTTPStatus } from "../../utils/helperObj";
import AuthPageLayout from "../../components/Layout/AuthPageLayout";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { setUserVerified } from "../../features/userVerifiedSlice";
import Cookies from "universal-cookie";
import EmailVerificationForm from "../../components/emailverification/EmailVerificationForm";
import { toastError, toastSuccess } from "../../utils/Notification";
import useCheckUserVerified from "../../hooks/guard/useCheckUserVerified";

const cookies = new Cookies();

export default function EmailVerification() {
  // useGuest();
  const checkingUserVerification = useCheckUserVerified();
  const userVerified = useSelector((state) => state.userVerified.userVerified);
  const [otp, setOtp] = useState("");
  const [tokenExist, setTokenExist] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (userVerified === true) {
      navigate("/profile/personal");
    }
  }, [navigate, userVerified]);
  useEffect(() => {
    const data = cookies.get("token");
    if (!data) {
      setTokenExist(false);
    }
  }, []);
  async function emailVerificationhandler() {
    const res = await verifyEmail(otp);
    if (res.statusCode === HTTPStatus.SUCCESS) {
      dispatch(setUserVerified(true));
      toastSuccess("Email is verified");
      navigate("/login");
    } else {
      toastError(res.errorMsg);
    }
  }

  async function resendOtpHandler() {
    const res = await resentEmailVerificationOtp();
    if (res.statusCode === HTTPStatus.SUCCESS) {
      toastSuccess(res.successMsg);
    } else {
      toastError(res.errorMsg);
    }
  }

  if (checkingUserVerification) {
    return <p>Loading...</p>;
  }

  return (
    <AuthPageLayout heading="Email verification">
      {tokenExist ? (
        <form action="" className="space-y-3">
          <TextField
            fullWidth
            type="number"
            label="OTP"
            placeholder="Enter OTP to verify"
            style={{ borderColor: "#0865B6" }}
            value={otp}
            onChange={(e) => setOtp(e.target.value)}
          />

          <div className="pt-10">
            <Button
              variant="contained"
              fullWidth
              style={{ background: "#0865B6" }}
              onClick={emailVerificationhandler}
            >
              Verify
            </Button>
            <Button
              onClick={resendOtpHandler}
              variant="contained"
              fullWidth
              style={{ background: "#0865B6" }}
              sx={{ marginTop: "8px" }}
            >
              Resend OTP
            </Button>
          </div>
        </form>
      ) : (
        <EmailVerificationForm setTokenExist={setTokenExist} />
      )}
    </AuthPageLayout>
  );
}
