import axios from "axios";
import { handleException } from "../utils/dataFetchHelper";
import { API, HTTPStatus } from "../utils/helperObj";
import Cookies from "universal-cookie";
import HttpResponse from "../model/HttpResponseModel";

const cookies = new Cookies();

export async function sendOtpToEmail(email) {
  try {
    const res = await axios.post(API.sendForgotPassOtpToEmail, { email });
    return new HttpResponse({
      statusCode: HTTPStatus.SUCCESS,
      successMsg: res.data.message,
    });
  } catch (err) {
    return handleException(err);
  }
}

/**
 * 
 * @returns {Promise<string|HttpResponse>} string is the token
 */
export async function verifyOtp(email, otp) {
  try {
    const res = await axios.post(
      API.verifyForgotPassOtp,
      {
        email,
        otp,
      }
    );
    return res.data.token
  } catch (err) {
    return handleException(err);
  }
}

export async function resetPasword(newPassword, token) {
  try {
    const res = await axios.post(
      API.resetPassword,
      {
        password: newPassword,
      },
      {
        headers: {
          Authorization: "Token " + token,
        },
      }
    );
    return new HttpResponse({
      statusCode: res.status,
      successMsg: "Password change successful",
    });
  } catch (err) {
    return handleException(err);
  }
}
