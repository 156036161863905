import {
  containsOnlyLetters,
  containsOnlyNumbers,
  isValidPhoneNumber,
} from "../utils/validation";

export function isValidPersonalData(data) {
  // if (!data.first_name || containsOnlyLetters(data.first_name) === false)
  //   return "Enter a valid First Name";

  // if (!data.last_name || containsOnlyLetters(data.last_name) === false)
  //   return "Enter a valid Last Name";

  if (!data.dob) return "Enter Select a Date of Birth";

  if (!data.mobile_no || isValidPhoneNumber(data.mobile_no) === false)
    return "Enter a valid Phone Number";

  if (data.aadhaar_card && containsOnlyNumbers(data.aadhaar_card) === false)
    return "Enter a valid Aadhar Card no";

  if (data.pin && containsOnlyNumbers(data.pin) === false)
    return "Enter a valid Pin no";

  if (!data.gender) return "Please select a Gender";
  // if (!data.current_address) return "Please enter an Address";
  if (!data.total_exp) return "Please Select a Total experience";

  if (
    data.guardian_number &&
    isValidPhoneNumber(data.guardian_number) === false
  )
    return "Enter a valid Gurdain Number";

  return null;
}
