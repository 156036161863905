export const FACEBOOK = {
  CLIENT_ID: "1282630792371821",
};
export const GOOGLE = {
  WEB_CLIENT_ID:
    "33359555321-92v3fuv487p0nf5nsjce73vqa970fje8.apps.googleusercontent.com",
  ANDROID_CLIENT_ID:
    "33359555321-9k41q1focqg7devv695mbr8fa2pbqs64.apps.googleusercontent.com",
};

export const LINKEDIN = {
  APP_ID: "86ulgpwy9j0syt",
  APP_SECRET: "q0FiiHFemtVsTb7S",
  REDIRECT_URI: `${window.location.origin}/login/linkedin`,
  SCOPE: "r_emailaddress r_liteprofile",
};
