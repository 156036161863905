import EditIcon from "@mui/icons-material/Edit";
import Timeline from "@mui/lab/Timeline";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import { Button } from "@mui/material";
import Typography from "@mui/material/Typography";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";

const WorkExperience = () => {
    const [timeline, setTimeline] = useState([]);
    const [loading, setLoading] = useState(false);
    let navigate = useNavigate();
    const cookies = new Cookies();

    const idRef = useRef("")

    useEffect(() => {
        axios
            .get(`/profile/workExperience`, {
                headers: {
                    Authorization: "Token " + cookies.get("token"),
                },
            })
            .then((res) => {

                setLoading(true);
                setTimeline(res.data);
            })
            .catch((err) => {

            });
    }, []);

    return (
        <div className="px-0 md:px-5 lg:px-16 py-6 cursor-default">
            <div className="card border-none rounded-lg bg-white mb-3 min-h-[68vh] overflow-auto">
                <div className="card-body">
                    <div className="card-text md:px-4 lg:px-6 py-3">
                        <div className="space-y-2 md:space-y-0 md:grid-cols-2 flex items-center justify-between">
                            <p className="text-xl px-2 md:text-2xl lg:text-2xl font-bold text-[#0865B6]">
                                Work Experience
                            </p>
                            <div className="md:text-right px-4">
                                <Button
                                    className="text-base px-3 py-1"
                                    style={{
                                        background: "#F1F1F1",
                                        color: "#0865B6",
                                        borderRadius: "0.5rem",
                                        fontWeight: "bold"
                                    }}
                                >
                                    <Link to="add-more">+ Add More</Link>
                                </Button>
                            </div>
                        </div>
                        <div
                            className="pt-4
                                md:pr-36
                                lg:pr-40 "
                        >
                            {timeline && timeline.map((row) => (
                                <Timeline position="right" key={row.id}>
                                    <TimelineItem className="before:hidden">
                                        {/*<TimelineOppositeContent*/}
                                        {/*    align="right"*/}
                                        {/*    variant="body2"*/}
                                        {/*    color="text.secondary"*/}
                                        {/*>*/}
                                        {/*    <Typography>*/}
                                        {/*        <span className="text-gray-400 font-bold text-sm">*/}
                                        {/*            {row.start_date.toString().slice(0, 4)} -*/}
                                        {/*        </span>*/}
                                        {/*    </Typography>*/}
                                        {/*    <Typography>*/}
                                        {/*    <span className="text-gray-400 font-bold text-sm">*/}
                                        {/*        {row.end_date !== "Invalid date"*/}
                                        {/*            ? row.end_date.toString().slice(0, 4)*/}
                                        {/*            : "On-Going"*/}
                                        {/*        }*/}
                                        {/*    </span>*/}
                                        {/*    </Typography>*/}
                                        {/*</TimelineOppositeContent>*/}
                                        <TimelineSeparator className="px-2 py-2">
                                            <TimelineDot sx={{ background: "#0865B6" }} />
                                            <TimelineConnector sx={{ background: "#0865B6" }} />
                                        </TimelineSeparator>
                                        <TimelineContent sx={{ py: "12px", px: 2 }}>
                                            <Typography
                                                variant="h6"
                                                component="span"
                                                className="flex"
                                            >
                                                <span className="text-black text-base md:text-lg lg:text-xl font-[600] mr-5">
                                                    {row.position}
                                                </span>
                                                <div
                                                    className="bg-blue-300 rounded-full flex flex-col items-center justify-center
                                                        hover:cursor-pointer hover:bg-blue-400 duration-300 max-h-6 max-w-8"
                                                    ref={idRef}
                                                    id={row.id}
                                                >
                                                    {/*<Link to="update">*/}
                                                    <EditIcon
                                                        className="mt-1"
                                                        style={{ height: 14, color: "black" }}
                                                        onClick={() => {
                                                            localStorage.setItem("WorkEx-ID", row.id);
                                                            // window.location.reload();
                                                            navigate("/profile/professional-details/work-experience/update");
                                                        }}
                                                    />
                                                    {/*</Link>*/}
                                                </div>
                                            </Typography>
                                            <Typography variant="subtitle1" className="font-bold">
                                                <p className="text-black font-[600] text-sm md:text-base">
                                                    {" "}
                                                    {row.company_name} -
                                                    <span className="text-gray-400 text-xs font-bold">
                                                        {" "}
                                                        {row.city}, {row.country}
                                                    </span>{" "}
                                                </p>
                                            </Typography>
                                            <Typography>
                                                <span className="text-gray-400 font-bold text-sm">
                                                    {row.start_date}
                                                    {
                                                        row.end_date !== "Invalid date" && row.end_date !== "null"
                                                            ? <h> to {row.end_date}</h>
                                                            : <h> to "Present"</h>
                                                    }
                                                </span>
                                            </Typography>
                                            <Typography variant="subtitle2">
                                                <span className="text-gray-400 break-all text-xs font-bold">
                                                    {row.description}
                                                </span>
                                            </Typography>
                                        </TimelineContent>
                                    </TimelineItem>
                                </Timeline>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            {/* <Project /> */}
        </div>
    );
};

export default WorkExperience;
