import { Button, TextField } from "@mui/material";
import React, { useState } from "react";
import { sendEmailVerificationOtp } from "../../controller/AuthController";
import { HTTPStatus } from "../../utils/helperObj";
import { toastError, toastSuccess } from "../../utils/Notification";

/**
 * @typedef {object} Props
 * @property {React.Dispatch<React.SetStateAction<boolean>>} setTokenExist
 */

/**
 * @param {Props} props
 */

export default function EmailVerificationForm({ setTokenExist }) {
  const [email, setEmail] = useState("");

  const handleSendOtp = async () => {
    const res = await sendEmailVerificationOtp(email);
    if (res.statusCode === HTTPStatus.SUCCESS) {
      toastSuccess(res.successMsg);
      setTokenExist(true);
    } else {
      toastError(res.errorMsg);
    }
  };

  return (
    <form action="" className="space-y-3">
      <TextField
        fullWidth
        type="text"
        label="Email"
        placeholder="Enter your email"
        style={{ borderColor: "#0865B6" }}
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />

      <div className="pt-10">
        <Button
          variant="contained"
          fullWidth
          style={{ background: "#0865B6" }}
          onClick={handleSendOtp}
        >
          Send
        </Button>
      </div>
    </form>
  );
}
