import { Box, Button, CircularProgress, Container, Typography } from "@mui/material";
import React, { useCallback, useRef } from "react";
import Header from "../../components/Header";
import { useNavigate, useParams } from "react-router";
import { getAssignmentDetails } from "../../controller/serveyController";
import { useEffect } from "react";
import { useState } from "react";
import { handleControllerResultException } from "../../utils/dataFetchHelper";
import QuestionCard from "./QuestionCard";
import LoadSuccess from "../../components/LoadSuccess";
import ReturnButton from "../../components/ReturnButton";
import { jobApply } from "../../controller/JobController";
import { HTTPStatus } from "../../utils/helperObj";
import { message } from "antd";

export default function Servey() {
    const { assignmentId } = useParams();
    const [questionList, setQuestionList] = useState(questionListType);
    const [savingAnswer, setSavingAnswer] = useState(false);
    const [fetchingQuestionDetails, setFetchingQuestionDetails] = useState(false);
    const [applyingJob, setApplyingJob] = useState(false);
    const jobId = useRef(0);

    const navigate = useNavigate();
    const handleAssignmentDetails = useCallback(
        async (id) => {
            setFetchingQuestionDetails(true)
            const res = await getAssignmentDetails(id);
            setFetchingQuestionDetails(false)
            if (res[0] !== null) {
                setQuestionList(res[0].questions);
                jobId.current = res[0].jobId;
            } else {
                handleControllerResultException(res[1], navigate);
            }
        },
        [navigate]
    );

    async function handleApplyJobs() {
        setApplyingJob(true)
        const res = await jobApply(jobId.current);
        setApplyingJob(false)
        if (res.statusCode === HTTPStatus.CREATED) {
            message.success(res.successMsg);
            navigate("/applications/jobs");
        } else {
            handleControllerResultException(res, navigate);
        }
    }

    useEffect(() => {
        if (assignmentId) {
            handleAssignmentDetails(assignmentId);
        }
    }, [assignmentId, handleAssignmentDetails]);


    if (fetchingQuestionDetails) {
        return <Box sx={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>
            <CircularProgress size={"30px"} />
        </Box>
    }



    return (
        <Box>
            <Header />
            <Container maxWidth="lg" sx={{ margin: "5rem auto" }}>
                <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                    <ReturnButton />
                    <Button disabled={applyingJob} sx={{ width: "150px", height: "40px" }} variant="contained" onClick={handleApplyJobs}>
                        {applyingJob ? <CircularProgress size={"20px"} /> : "Apply now"}
                    </Button>
                </Box>
                <LoadSuccess loading={savingAnswer} />
                {questionList.length > 0 &&
                    questionList.map((data) => (
                        <Box key={data.id}>
                            <QuestionCard
                                question={data}
                            />
                            <hr />
                        </Box>
                    ))}
            </Container>
        </Box>
    );
}

/**
 * @type {import('../../model/ServeyModel').ServeyQuestionModel[]}
 */
const questionListType = [];
