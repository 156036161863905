import React, { useState } from "react";
import {
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
} from "@mui/material";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";
import { HTTPStatus } from "../../utils/helperObj";
import { login } from "../../controller/AuthController";
import { useNavigate } from "react-router";
import AuthPageLayout from "../../components/Layout/AuthPageLayout";
import { useDispatch } from "react-redux";
import { resetUserVerificationStatus } from "../../features/userVerifiedSlice";
import { toastError } from "../../utils/Notification";
import useGuest from "../../hooks/guard/useGuest";
import { SocialLogins } from "../../components/social_login/SocialLogins";
import { toast } from "react-toastify";
import { isValidEmail } from "../../utils/validation";

export default function Login() {
  useGuest();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const loginHandler = async () => {
    if (!isValidEmail(email)) {
      return toastError("Please enter valid Email Id")
    }
    setLoading(true);
    const res = await login(email, password);
    setLoading(false)
    if (res.statusCode === HTTPStatus.SUCCESS) {

      dispatch(resetUserVerificationStatus());
      navigate(
        localStorage.getItem("redirectPath")
          ? localStorage.getItem("redirectPath")
          : "/dashboard"
      );
    } else {
      toastError(res.errorMsg);
    }
  };

  return (
    <>
      <AuthPageLayout heading="Login">
        <TextField
          fullWidth
          label="Email ID"
          type="email"
          placeholder="Enter your Email ID"
          style={{ borderColor: "#0865B6" }}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <FormControl variant="outlined" fullWidth>
          <InputLabel htmlFor="outlined-adornment-password">
            Password
          </InputLabel>
          <OutlinedInput
            label="Password"
            value={password}
            placeholder="Enter Your Password"
            type={showPassword ? "text" : "password"}
            style={{ borderColor: "#0865B6" }}
            onChange={(e) => setPassword(e.target.value)}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  type={showPassword ? "text" : "password"}
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword(!showPassword)}
                  edge="end"
                >
                  {showPassword ? <Visibility /> : <VisibilityOff/>}
                </IconButton>
              </InputAdornment>
            }
            required
          />
        </FormControl>
        <div
          align="end"
          style={{ color: "rgb(1,1,1,0.3)" }}
          role="button"
          onClick={() => navigate("/forgot-password")}
        >
          <p className="text-neutral-400 cursor-pointer">Forgot Password?</p>
        </div>
        <div>
          <Button
            onClick={loginHandler}
            fullWidth
            variant="contained"
            style={{ backgroundColor: "#0865B6", color: "white" }}
            disabled={loading}
          >
            {loading ? <CircularProgress sx={{ color: "white" }} size="1.6rem" /> : "Login"}
          </Button>
        </div>

        <div>
          <Button
            fullWidth
            variant="outlined"
            style={{ borderColor: "#0865B6" }}
            onClick={() => navigate("/signup")}
          >
            Not a Member? Sign Up
          </Button>
        </div>
        <p className="text-center text-lg">Or login with</p>
        <SocialLogins />
      </AuthPageLayout>
    </>
  );
}
