import axios from "axios";
import { API } from "../utils/helperObj";
import Cookies from "universal-cookie";
import { createWebinarObj } from "../model/WebinarModal";
import { handleException } from "../utils/dataFetchHelper";

const cookie = new Cookies();

/**
 *
 * @param {number} page
 *  @param {"previous"|"upcoming"} webinarType
 * @returns {Promise<[{results:import("../model/WebinarModal").WebinarModel[], count:number} | null, HttpResponse | null]>}
 */
export async function getWebinarList(page = 1, webinarType) {
    const result = [null, null];
    try {
        const res = await axios.get(API.webinarList, {
            params: {
                page,
                webinar_type: webinarType,
            },
            headers: {
                Authorization: `Token ${cookie.get("token")}`,
            },
        });
        const webinarArr = [];
        for (let x of res.data.results) {
            webinarArr.push(createWebinarObj(x));
        }
        result[0] = {
            results: webinarArr,
            count: res.data.count,
        };
        return result;
    } catch (err) {
        result[1] = handleException(err);
        return result;
    }
}



/**
 *
 * @param {number} webinarId
 * @returns {Promise<[import("../model/WebinarModal").WebinarModel|null, HttpResponse|null]>}
 */
export async function getWebinarDetail(webinarId) {
    const result = [null, null];
    try {
        const res = await axios.get(
            `${API.webinarDetails}/${webinarId}`,
            {
                headers: {
                    Authorization: `Token ${cookie.get("token")}`,
                },
            }
        );
        result[0] = createWebinarObj(res.data);
        return result;
    } catch (err) {
        result[1] = handleException(err);
        return result;
    }
}