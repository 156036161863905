import { configureStore } from "@reduxjs/toolkit";
import HeaderReducer from "../features/HeaderSlice";
import InfoReducer from "../features/InfoSlice";
import businessInfoReducer from "../features/BusinessInfoSlice";
import userVerifiedReducer from "../features/userVerifiedSlice";
import notificationReducer from "../features/NotificationSlice"
import headerDataReducer from "../features/HeaderDataSlice";
export const store = configureStore({
  reducer: {
    header: HeaderReducer,
    info: InfoReducer,
    businessInfo: businessInfoReducer,
    userVerified: userVerifiedReducer,
    notifications: notificationReducer,
    headerData: headerDataReducer,
  },
});
