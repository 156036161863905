import TabPanelUnstyled from "@mui/base/TabPanelUnstyled";
import TabsListUnstyled from "@mui/base/TabsListUnstyled";
import TabsUnstyled from "@mui/base/TabsUnstyled";
import React, { useEffect, useState } from "react";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import Header from "../../../components/Header";
import Sidebar from "../../../components/Sidebar";
import PersonalDetails from "../../My Profile/Personal Details/PersonalDetails";
import Settings from "../../Settings/Settings";
import ProfessionalDetails from "../Professional Details/ProfessionalDetails";
import "./Profile.scss";

import { Box } from "@mui/material";
import Cookies from "universal-cookie";
const cookies = new Cookies();

const Profile = () => {
    const [variable, setVariable] = useState("");
    const navigate = useNavigate();

    useEffect(() => {
        if (cookies.get("token")) {
        } else {
            navigate("/");
        }
    }, []);

    return (
        <div className="profile_main">
            <Header />
            <Box sx={{ marginTop: "5rem" }}>
                <Sidebar />

                <TabsUnstyled defaultValue={0} className="m-2">
                    <TabsListUnstyled className="bg-white rounded-lg flex text-neutral-400 items-center min-w-[220px]">
                        <div
                            className={`hover:bg-[#F1F1F1] hover:text-sky-700 rounded-[5px] font-bold text-base cursor-pointer
                m-1 py-1 px-2.5
                md:px-8 md:text-xl md:py-2 md:1.5
                lg:px-10 lg:text-xl lg:py-2 lg:m-1.5
                ${window.location.pathname === "/profile/personal"
                                    ? "bg-[#F1F1F1] text-sky-700"
                                    : ""
                                } `}
                            onClick={() => navigate("personal")}
                        >
                            <p className="m-2">Personal</p>
                        </div>
                        <div
                            onClick={() => navigate("professional-details")}
                            className={`hover:bg-[#F1F1F1] hover:text-sky-700 rounded-[5px] font-bold text-base cursor-pointer
                m-1 py-1 px-2.5
                md:px-8 md:text-xl md:py-2 md:1.5
                lg:px-10 lg:text-xl lg:py-1.5 lg:m-1.5
                ${window.location.pathname ===
                                    "/profile/professional-details/work-experience"
                                    ? "bg-[#F1F1F1] text-sky-700"
                                    : ""
                                }  
                ${window.location.pathname ===
                                    "/profile/professional-details/work-experience/add-more"
                                    ? "bg-[#F1F1F1] text-sky-700"
                                    : ""
                                }
                ${window.location.pathname ===
                                    "/profile/professional-details/work-experience/update"
                                    ? "bg-[#F1F1F1] text-sky-700"
                                    : ""
                                }
                ${window.location.pathname ===
                                    "/profile/professional-details/skills"
                                    ? "bg-[#F1F1F1] text-sky-700"
                                    : ""
                                }   
                ${window.location.pathname ===
                                    "/profile/professional-details/education"
                                    ? "bg-[#F1F1F1] text-sky-700"
                                    : ""
                                } 
                ${window.location.pathname ===
                                    "/profile/professional-details/education/add"
                                    ? "bg-[#F1F1F1] text-sky-700"
                                    : ""
                                }
                ${window.location.pathname ===
                                    "/profile/professional-details/education/update"
                                    ? "bg-[#F1F1F1] text-sky-700"
                                    : ""
                                }
                ${window.location.pathname ===
                                    "/profile/professional-details/certificate"
                                    ? "bg-[#F1F1F1] text-sky-700"
                                    : ""
                                }
                ${window.location.pathname ===
                                    "/profile/professional-details/certificate/add"
                                    ? "bg-[#F1F1F1] text-sky-700"
                                    : ""
                                }   
                ${window.location.pathname ===
                                    "/profile/professional-details/certificate/update"
                                    ? "bg-[#F1F1F1] text-sky-700"
                                    : ""
                                }
                ${window.location.pathname ===
                                    "/profile/professional-details/upload-resume"
                                    ? "bg-[#F1F1F1] text-sky-700"
                                    : ""
                                } 
                ${window.location.pathname ===
                                    "/profile/professional-details/upload-documents"
                                    ? "bg-[#F1F1F1] text-sky-700"
                                    : ""
                                } `}
                        >
                            <p className="m-2">Professional</p>
                        </div>
                        <div
                            onClick={() => navigate("settings")}
                            className={`hover:bg-[#F1F1F1] hover:text-sky-700 rounded-[5px] font-bold text-base cursor-pointer
                m-1 py-1 px-2.5
                md:px-8 md:text-xl md:py-2 md:1.5
                lg:px-10 lg:text-xl lg:py-1.5 lg:m-1.5
                ${window.location.pathname === "/profile/settings"
                                    ? "bg-[#F1F1F1] text-sky-700"
                                    : ""
                                }`}
                        >
                            {" "}
                            <p className="m-2">Settings</p>
                        </div>
                    </TabsListUnstyled>

                    <TabPanelUnstyled
                        className="w-full"
                        value={0}
                        onClick={(e) => setVariable(e.currentTarget.id)}
                    ></TabPanelUnstyled>

                    <TabPanelUnstyled
                        className="w-full"
                        value={1}
                        onClick={(e) => setVariable(e.currentTarget.id)}
                    ></TabPanelUnstyled>

                    <TabPanelUnstyled
                        className="w-full"
                        value={2}
                        onClick={(e) => setVariable(e.currentTarget.id)}
                    ></TabPanelUnstyled>

                    <Routes>
                        <Route path="/" element={<Navigate replace to="personal" />} />
                        <Route path="personal" element={<PersonalDetails />} />
                        <Route
                            path="professional-details/*"
                            element={<ProfessionalDetails />}
                        />
                        <Route path="settings" element={<Settings />} />
                    </Routes>
                </TabsUnstyled>
            </Box>
        </div>
    );
};

export default Profile;
