/**
 *  @typedef {object} Props
 * @property {JSX.Element} children
 * @property {string} toolTipText
 * @property {()=>void} buttonActionFunc
 */

/**
 * @param {Props} props
 * @returns {JSX.Element} render tiptap editor toolbar button
 */

export default function MenuBtnContainer({
  children,
  toolTipText,
  buttonActionFunc,
}) {
  return (
    <div className=" group relative">
      <div className="absolute bg-gray-800 text-white  top-2  text-[10px] py-1 px-2 text-center rounded-lg z-30 scale-0 group-hover:scale-100 transition-all duration-300">
        {toolTipText}
      </div>
      <button onClick={buttonActionFunc} className={`toolBar-btn`}>
        {children}
      </button>
    </div>
  );
}
