import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";

import { Box, Pagination } from "@mui/material";
import Cookies from "universal-cookie";
import Spinner from "../../components/Spinner";
import JobList from "../../components/jobs/JobList";
import NoSaved from "../../images/NoSaved.png";

const cookies = new Cookies();

const Saved = ({ input }) => {
  const [data, setData] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(true)
  const navigate = useNavigate();

  async function searchText() {
    setLoading(true);
    LoadInfo(input);
  }

  useEffect(() => {
    let timeOut = 0;

    if (input !== undefined) {
      timeOut = setTimeout(() => {
        searchText();
      }, [700]);

    }
    return () => {
      clearTimeout(timeOut);
    };
  }, [input]);

  const LoadInfo = (input, page = 1) => {
    axios
      .get(`profile/bookmark/view?keyword=${input}`, {
        headers: {
          Authorization: "Token " + cookies.get("token"),
        },
        params: {
          page,
        }
      })
      .then((res) => {
        setData(res.data.results);
        setTotalPages(Math.ceil(res.data.count / 6));
        setLoading(false)
      })
      .catch((err) => { });
  };



  // const ApplyForJob = (item) => {
  //   axios
  //     .post(
  //       `${base_url}/profile/apply`,
  //       {
  //         position: item.id,
  //       },
  //       {
  //         headers: {
  //           Authorization: "Token " + cookies.get("token"),
  //         },
  //       }
  //     )
  //     .then((res) => navigate("/applications/applied-jobs"))
  //     .catch((err) => {});
  // };


  const cardAction = (item) => {
    navigate(`/applications/job-detail/${localStorage.getItem("PositionId")}`);
  };

  return (
    <div className="m-1 md:m-2 lg:m-3">
      {data.length > 0 ? (
        <div>
          <JobList
            jobList={data}
            performAction={true}
            actionButtonText={"View Details"}
            actionButtonFunc={cardAction}
            performBookMark={false}
          />
          <Box
            sx={{ display: "flex", justifyContent: "center" }}
            marginBottom={"2rem"}
            marginTop={"2rem"}
          >
            <Pagination
              count={totalPages}
              color="primary"
              shape="rounded"
              onChange={(e, page) => {
                LoadInfo(input, page);
              }}
            />
          </Box>
        </div>
      ) : (
        <div>
          {loading ? (
            <Spinner />
          ) : (
            <div className="flex justify-center items-center m-11">
              <div className="flex flex-col justify-center items-center border-1 bg-white w-[350px] p-10 rounded-md">
                <img src={NoSaved} alt="No Jobs" />
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Saved;
