import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Button,
  Card,
  CardContent,
  IconButton,
  TextField,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { base_url } from "../../../../components/consts";

import { toast } from "react-toastify";
import Cookies from "universal-cookie";
import { isValidSkills } from "../../../../helpers/validate_skills";
import WarningModal from "../../../../components/WarningModal";
const cookies = new Cookies();

const SkillsEdit = () => {
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [count, setCount] = useState(0);
  const userId = localStorage.getItem("user");
  const [skill_name, setSkill_name] = useState("");
  const [skill_strength, setSkill_strength] = useState(0);
  const [skillData, setSkillData] = useState({});
  const [openModal, setOpenModal] = useState(false);


  useEffect(() => {
    getData();
  }, []);

  function getData() {
    axios
      .get("/profile/skills", {
        headers: {
          Authorization: "Token " + cookies.get("token"),
        },
      })
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {

      });
  }

  const handleDelete = (e, id) => {
    e.preventDefault();

    axios
      .delete(base_url + `/profile/skills/delete/${id}`, {
        headers: {
          Authorization: "Token " + cookies.get("token"),
        },
      })
      .then((res) => {
        getData();
        setOpenModal(false)

        toast.success("Skills deleted successfully", {
          position: toast.POSITION.TOP_CENTER,
          setTimeout: 2000,
        });
        // setProjectEdit(false);
        // navigate("/profile/professional-details/skills")
      })
      .catch((err) => {

        toast.warning("Some error occured", {
          position: toast.POSITION.TOP_CENTER,
          setTimeout: 2000,
        });
      });
  };

  const handleChange = (e, i) => {
    setSkillData({ ...skillData, [e.target.name]: e.target.value });
  };

  const handleUpdate = (e) => {
    let newData = data;
    for (let i = 0; i < newData.length; i++) {
      if (newData[i]["id"] == e.target.id) {
        newData[i][e.target.name] = e.target.value;
      }
      setData(newData);
    }
  };

  const handleSubmit = (e, id) => {
    e.preventDefault();

    let skills = [];
    for (let i = 0; i < count; i++) {
      let obj = {};


      obj["user"] = localStorage.getItem("user");
      obj["id"] = skillData[`id`];
      obj["skills"] = skillData[`skills`];
      if (skillData[`rating${i}`] === "undefined" || !skillData[`rating${i}`])
        obj["rating"] = "0";
      else obj["rating"] = skillData[`rating${i}`];
      if (skillData[`skills${i}`] === "undefined" || !skillData[`skills${i}`]) {

      } else obj["skills"] = skillData[`skills${i}`];
      skills.push(obj);
    }

    for (let i = 0; i < data.length; i++) {
      if (data[i]["rating"] > 10) {
        return toast.info("Rating should't be greater 10");
      }
      skills.push(data[i]);
    }



    const error = isValidSkills(skills);
    if (error) return toast.error(error);

    axios
      .put(
        base_url + "/profile/skills/update",
        {
          skills: skills,
        },
        {
          headers: {
            Authorization: "Token " + cookies.get("token"),
          },
        }
      )
      .then((res) => {
        toast.success("Skills Updated successfully", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          style: { backgroundColor: "#1ab394", color: "white" },
          icon: false,
          progressStyle: { backgroundColor: "#BAE8DE" },
        });
        navigate("/profile/professional-details/skills");
      })
      .catch((err) => {
        toast.error(`skills : ${err.response.data.errors.skills[0]}`, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          style: { backgroundColor: "#FF6347", color: "white" },
          icon: false,
          progressStyle: { backgroundColor: "#FFB1A3" },
        });
      });
  };

  for (let i = 0; i <= count; i++) {
    <div key={i} className="grid md:grid-cols-8 lg:grid-cols-12 gap-4 pt-4">
      <div className="col-span-4">
        <TextField
          label={`${data.length + i + 1}. Skill / Strength Name`}
          name={`skills`}
          className="w-52 md:w-full"
          type="text"
          id={i}
          onChange={handleChange}
          placeholder="Enter your skill / strength here..."
        // style={{ width: "80%" }}
        />
      </div>
      <div className="col-span-4">
        <TextField
          label="Skill / Strength Level (Out of 10)"
          type="number"
          className="w-52 md:w-full"
          onChange={handleChange}
          min="1"
          id={i}
          name={`rating${i}`}
          max="10"
          error={skillData[`rating${i}`] > 10}
          step="0.5"
        // style={{ width: "80%" }}
        />
      </div>
    </div>;
  }

  return (
    <div>
      <Card className="my-9 mx-3" sx={{ borderRadius: "8px" }} elevation={0}>
        <CardContent>
          <div className="m-1 md:m-3 lg:m-4">
            <div className="">
              <p className="text-sky-700 text-xl px-2 md:px-4 md:text-2xl lg:text-2xl font-bold">
                Edit Skills / Strengths
              </p>
            </div>
            {data.map((item, i) => {
              return (
                <div
                  className="grid grid-col- md:grid-cols-8 lg:grid-cols-12 gap-4 pt-4"
                  key={item.id}
                >
                  <div className="col-span-4">
                    <TextField
                      name={`skills`}
                      label={`${i + 1}. Skill / Strength Name`}
                      variant="outlined"
                      type="text"
                      className="w-56 md:w-full"
                      id={item.id}
                      defaultValue={item.skills}
                      onChange={handleUpdate}
                      placeholder="Enter your skill / strength here..."
                    />
                  </div>

                  <div className="col-span-4">
                    <TextField
                      name="rating"
                      label="Skill / Strength Level (Out of 10)"
                      type="number"
                      error={data[i]["rating"] > 10}
                      className="w-56 md:w-full"
                      id={item.id}
                      defaultValue={item.rating}
                      onChange={handleUpdate}
                    />
                  </div>

                  <div className="col-span-1 pt-1">
                    <IconButton onClick={() => setOpenModal(true)}>
                      <DeleteIcon className="text-sky-700" />
                    </IconButton>
                  </div>
                  <WarningModal
                    open={openModal}
                    close={() => setOpenModal(false)}
                    title="Delete ?"
                    content="Are you sure you want to delete ?"
                    onclickfunction={(e) => handleDelete(e, item.id)}
                    cancelText="Cancel"
                    agreeText="Delete"
                  />
                </div>
              );
            })}
            

            {Array.from({ length: count }, (_item, i) => (
              <div
                key={i}
                className="grid md:grid-cols-8 lg:grid-cols-12 gap-4 pt-4"
              >
                <div className="col-span-4">
                  <TextField
                    label={`${data.length + i + 1}. Skill / Strength Name`}
                    name={`skills${i}`}
                    className="w-52 md:w-full"
                    type="text"
                    id={i}
                    onChange={handleChange}
                    placeholder="Enter your skill / strength here..."
                  // style={{ width: "80%" }}
                  />
                </div>
                <div className="col-span-4">
                  <TextField
                    label="Skill / Strength Level (Out of 10)"
                    type="number"
                    className="w-52 md:w-full"
                    onChange={handleChange}
                    min="1"
                    id={i}
                    name={`rating${i}`}
                    max="10"
                    error={skillData[`rating${i}`] > 10}
                    step="0.5"
                  // style={{ width: "80%" }}
                  />
                </div>
                <div className="col-span-1 pt-1">
                  <IconButton
                    onClick={(e) => {
                      setCount(count > 0 && count - 1);
                    }}
                  >
                    <DeleteIcon className="text-sky-700" />
                  </IconButton>
                </div>
              </div>
            ))}

            {data.length || count > 0 ? (
              <div className="col-span-1 pt-2 mt-4">
                <Button
                  variant="contained"
                  className="text-white bg-sky-700"
                  onClick={handleSubmit}
                >
                  Save details
                </Button>
              </div>
            ) : null}

            <div className="grid grid-cols-5 space-y-4 md:grid-cols-10 lg:grid-cols-10 mt-5">
              <div className="col-span-5">
                <Button
                  variant="outlined"
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  Cancel
                </Button>
              </div>
              <div className="col-span-5 text-left md:text-right">
                <Button
                  variant="contained"
                  className="rounded-full"
                  onClick={(e) => setCount(count + 1)}
                >
                  {data.length ? "Add Another" : "Add Skills"}
                  <AddCircleOutlineIcon className="ml-1 p-1" />
                </Button>
              </div>
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default SkillsEdit;
