import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";


import { Box, Pagination } from "@mui/material";
import Cookies from "universal-cookie";
import Spinner from "../../components/Spinner";
import JobList from "../../components/jobs/JobList";
import NoJobs from "../../images/NoJobs.png";

const cookies = new Cookies();


const Recommended = ({ input }) => {

  const navigate = useNavigate();

  const [errors, setErrors] = useState("");
  const [card_details, setCard_details] = useState([])
  const [loading, setLoading] = useState(true);
  const [totalPages, setTotalPages] = useState(1);

  async function searchText() {
    setLoading(true);
    GetDetails(input);
  }

  useEffect(() => {
    let timeOut = 0;
    if (input !== undefined) {
      timeOut = setTimeout(() => {
        searchText();
      }, [700]);
    }
    return () => {
      clearTimeout(timeOut);
    };
  }, [input]);


  const GetDetails = (input, page = 1) => {
    axios
      .get(
        `profile/job-recommendation?keyword=${input}`,
        {
          headers: {
            Authorization: "Token " + cookies.get("token")
          },
          params: {
            page,
          }
        },
      )
      .then((res) => {
        setCard_details(res.data.results)
        setTotalPages(Math.ceil(res.data.count / 20));
        setLoading(false)

      })
      .catch((err) => {

        setErrors(err.response.data.message)
        setLoading(false)
      })
  }


  const cardAction = (item) => {
    navigate(`/applications/job-detail/${item.id}`);
    localStorage.setItem("JobId", item.id);
  };

  return (
    <div className="m-1 md:m-2 lg:m-3">
      {loading ? (
        <div>
          <Spinner />
        </div>
      ) : null}

      {card_details.length > 0 ? (
        <div>
          <JobList
            jobList={card_details}
            performAction={true}
            actionButtonText={"view details"}
            actionButtonFunc={cardAction}
            performBookMark={true}
            bookMarkUrl={"/profile/bookmark"}
            unBookMarkUrl={"/profile/bookmark/delete"}
          />
          <Box
            sx={{ display: "flex", justifyContent: "center" }}
            marginBottom={"2rem"}
            marginTop={"2rem"}
          >
            <Pagination
              count={totalPages}
              color="primary"
              shape="rounded"
              onChange={(e, page) => {
                GetDetails(input, page);
              }}
            />
          </Box>
        </div>
      ) : (
        <div className="flex justify-center items-center m-11">
          <div className="flex flex-col justify-center items-center border-1 bg-white w-[350px] p-10 rounded-md">
            <img src={NoJobs} alt="No Jobs" />
          </div>
        </div>
      )}
      {/* <div className="text-xl flex justify-center items-center h-[80vh]">
        {typeof card_details === "string" ? card_details : null}
      </div> */}
    </div>
  )
}

export default Recommended;