import { useEffect } from "react";
import { useNavigate } from "react-router";
import Cookies from "universal-cookie";
const cookies = new Cookies();

/**
 * This Guard prevents an authenticated user to access a specific page
 */

export default function useGuest() {
  const naviagate = useNavigate();

  useEffect(() => {
    const token = cookies.get("token");
    if (token) {
      naviagate("/dashboard");
    }
  }, [naviagate]);
}
