import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArticleIcon from "@mui/icons-material/Article";
import BusinessIcon from "@mui/icons-material/Business";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import LocalAtmIcon from "@mui/icons-material/LocalAtm";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { Card, CardContent, Pagination } from "@mui/material";
import Box from "@mui/material/Box";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import axios from "axios";
import React, { useEffect, useState } from "react";
import Cookies from "universal-cookie";
import Spinner from "../../components/Spinner";
import { base_url } from "../../components/consts";
import JobList from "../../components/jobs/JobList";
import picture from "../My Profile/Personal Details/4041088.png";
import facebook from "../My Profile/Personal Details/Facebook_logo.png";
import google from "../My Profile/Personal Details/Google_logo.png";
import NoJobs from "../../images/NoJobs.png"

const cookies = new Cookies();

const AppliedJobs = ({ input }) => {
  const [active, setActive] = useState("btn1");
  const [data, setData] = useState([]);
  const [data2, setData2] = useState([]);
  const [info, setInfo] = useState([]);
  const [id, setId] = useState("");
  const [status, setStatus] = useState("");
  const [loading, setLoading] = useState(true);
  const [totalPages, setTotalPages] = useState(1);

  async function searchText() {
    setLoading(true);
    GetDetails(input);
  }

  useEffect(() => {
    let timeOut = 0;

    if (input !== undefined) {

      timeOut = setTimeout(() => {
        searchText();
      }, [700]);
    }

    return () => {
      clearTimeout(timeOut);
    };
  }, [input]);

  const GetDetails = (input, page = 1) => {
    axios
      .get(`profile/appliedjobs?keyword=${input}`, {
        headers: {
          Authorization: "Token " + cookies.get("token"),
        },
        params: {
          page,
        }
      })
      .then((res) => {
        setData(res.data.results);
        setTotalPages(Math.ceil(res.data.count / 20));
        setLoading(false);
      })
      .catch((err) => {

      });
  };


  const TrackApplication = (id) => {
    axios
      .get(`${base_url}/profile/appliedjobs/${id}`, {
        headers: {
          Authorization: "Token " + cookies.get("token"),
        },
      })
      .then((res) => setInfo(res.data))
      .catch((err) => { });
  };

  const GetCompanyDetails = (company) => {
    axios
      .get(`${base_url}/profile/companydetails/${company}`, {
        headers: {
          Authorization: "Token " + cookies.get("token"),
        },
      })
      .then((res) => {
        setData2(res.data);
      })
      .catch((err) => { });
  };

  const steps = [
    {
      label: "Applied",
    },
    {
      label: "In-Review",
      description:
        "HR is reviewing your application please be patience we will update your status if you have schedule interview. ",
    },
    {
      label: "Interview",
    },
    {
      label: "Selected",
    },
  ];

  const tractApplication = (item) => {
    setActive("btn2");
    TrackApplication(item.id);
    setId(item.id);
    setStatus(item.status);
  };
  return (
    <div>
      {loading ? (
        <div>
          <Spinner />
        </div>
      ) : null}
      {active === "btn1" ? (
        <div>
          {data.length > 0 ? (
            <div>
              <JobList
                jobList={data}
                performAction={true}
                actionButtonText={"Track"}
                actionButtonFunc={tractApplication}
                performBookMark={false}
              />
              <Box
                sx={{ display: "flex", justifyContent: "center" }}
                marginBottom={"2rem"}
                marginTop={"2rem"}
              >
                <Pagination
                  count={totalPages}
                  color="primary"
                  shape="rounded"
                  onChange={(e, page) => {
                    GetDetails(input, page);
                  }}
                />
              </Box>
            </div>
          ) : (
            <div>
              {loading ? (
                <Spinner />
              ) : (
                <div className="flex justify-center items-center m-11">
                  <div className="flex flex-col justify-center items-center border-1 bg-white w-[350px] p-10 rounded-md">
                    <img src={NoJobs} alt="No Jobs" />
                  </div>
                </div>
              )}
            </div>
          )}

        </div>
      ) : (
        <></>
      )}

      {active === "btn2" ? (
        <div>
          <Card elevation={0} className="mt-3 mb-10 md:mx-1 lg:mx-3 text-lg">
            <CardContent>
              <div>
                <div>
                  <p
                    className="text-[#0865B6] rounded-md  hover:bg-[#F1F1F1] p-1 text-lg font-semibold w-20"
                    onClick={(e) => setActive("btn1")}
                  >
                    <ArrowBackIcon
                      className="mx-1 mb-1"
                      style={{ fontSize: "large" }}
                    />
                    Back
                  </p>
                </div>
              </div>

              {info.map((item) => (
                <div>
                  <div
                    className="py-2 grid
                                        grid-cols-6
                                        md:grid-cols-10
                                        lg:grid-cols-10 lg:px-4"
                    key={item.id}
                  >
                    <div
                      className="p-2
                                            col-span-2
                                            md:col-span-1
                                            lg:col-span-1"
                    >
                      <img
                        className="rounded-full w-20 md:w-24 lg:w-24"
                        src={picture}
                        alt=""
                      />
                    </div>

                    <div
                      className="space-y-1
                                        col-span-4
                                        md:col-span-7
                                        lg:col-span-7"
                    >
                      <div
                        className="pt-4 pl-4 font-semibold
                                            sm:text-xl
                                            md:text-xl
                                            lg:text-2xl"
                      >
                        <p>
                          {item.position_name
                            ? item.position_name
                            : "not available"}
                        </p>
                      </div>

                      <div
                        className="pl-4
                                            text-sm
                                            md:text-base
                                            lg:text-xl"
                      >
                        <p>
                          {item.company_name
                            ? item.company_name
                            : "Company name not available"}
                        </p>
                      </div>
                    </div>

                    {item.company !== null && (
                      <div
                        className="self-center cursor-pointer
                                            col-start-2 col-end-5 ml-4
                                            md:col-span-2
                                            lg:col-span-2 mt-3"
                        align="center"
                        onClick={(e) => {
                          setActive("btn3");
                          GetCompanyDetails(`${item.company}`);
                        }}
                      >
                        <p
                          className="py-2 text-[#0865B6] font-semibold rounded-md hover:bg-[#F1F1F1]
                                             text-sm
                                             md:text-base
                                             lg:text-lg"
                        >
                          Company Details
                        </p>
                      </div>
                    )}
                  </div>

                  <div
                    className="py-2 px-2 grid space-y-2 text-base
                                        sm:grid-cols-1
                                        md:grid-cols-2 md:space-y-2 md:mx-5
                                        lg:grid-cols-12 lg:mx-10 lg:text-lg"
                  >
                    <div className="lg:col-span-2">
                      <p>
                        <BusinessCenterIcon className="text-[#A9A9A9] mb-1 mr-2" />
                        {item.experience}
                      </p>
                    </div>
                    <div className="lg:col-span-3">
                      <p>
                        <LocalAtmIcon className="text-[#A9A9A9] mb-1 mr-2" />
                        {item.stipend}
                      </p>
                    </div>
                    <div className="lg:col-span-3">
                      <p>
                        <BusinessIcon className="text-[#A9A9A9] mb-1 mr-2" />
                        {item.employment_type}
                      </p>
                    </div>
                    <div className="lg:col-span-4">
                      <p>
                        <ArticleIcon className="text-[#A9A9A9] mb-1 mr-2" />
                        {item.criteria}
                      </p>
                    </div>
                  </div>

                  <div
                    className="px-2 py-1 grid text-base space-y-2
                                        sm:grid-cols-1
                                        md:grid-cols-2 md:mx-5 md:py-2
                                        lg:grid-cols-2 lg:mx-10 lg:py-2"
                  >
                    <div className="col-span-1 md:col-span-3 lg:col-span-2 xl:col-span-4">
                      <p>
                        <LocationOnIcon className="text-[#A9A9A9] mb-1 mr-2" />
                        {item.location}
                      </p>
                    </div>
                    <div>
                      <p>
                        <LeaderboardIcon className="text-[#A9A9A9] mb-1 mr-2" />
                        {item.skills}
                      </p>
                    </div>
                  </div>

                  <div className="pt-2 pb-2 pr-5 pl-5">
                    <div>
                      <Box sx={{ maxWidth: 400 }} className="py-2 pl-5">
                        <p className=" px-2 py-4 text-[#FFAB00] lg:text-2xl">
                          {item.status}
                        </p>

                        <Stepper
                          activeStep={
                            item.status === "Applied"
                              ? 0
                              : item.status === "In-Review"
                                ? 1
                                : item.status === "Interview"
                                  ? 2
                                  : 3
                          }
                          orientation="vertical"
                        >
                          {steps.map((step, index) => (
                            <Step key={step.label}>
                              <StepLabel>{step.label}</StepLabel>
                            </Step>
                          ))}
                        </Stepper>
                      </Box>
                    </div>
                  </div>
                </div>
              ))}
            </CardContent>
          </Card>
        </div>
      ) : (
        <></>
      )}

      {active === "btn3" ? (
        <div>
          <Card elevation={0} className="mx-1 mt-3 mb-10">
            <CardContent>
              <div>
                <p
                  className="text-[#0865B6] rounded-md w-28  hover:bg-[#F1F1F1] py-1 px-3 text-lg font-semibold cursor-pointer"
                  onClick={(e) => setActive("btn2")}
                >
                  <ArrowBackIcon
                    className="ml-1 mr-1 mb-1"
                    style={{ fontSize: "large" }}
                  />
                  Back
                </p>
              </div>

              {data2.map((item) => (
                <div
                  className="py-2 grid px-1 col-span-8 md:px-2 md:grid-cols-10 lg:px-5"
                  key={item.id}
                >
                  <div className="col-span-8 md:col-span-1" align="center">
                    <img
                      className="rounded-full h-16 lg:h-20"
                      src={picture}
                      alt=""
                    />
                  </div>

                  <div
                    className="py-2 px-1
                                        text-base col-span-8
                                        md:text-lg md:col-span-9 md:px-4
                                        lg:text-xl lg:col-span-9"
                  >
                    <div className="py-2 px-4 text-base md:text-xl lg:text-2xl font-semibold">
                      <p>{item.name}</p>
                    </div>

                    <div className="py-2 px-4 grid grid-cols-1 md:grid-cols-2">
                      <div className="p-2">
                        <p className="text-[#A9A9A9]">CEO</p>
                        <p>{item.company_ceo}</p>
                      </div>
                      <div className="p-2">
                        <p className="text-[#A9A9A9]">Founder</p>
                        <p>{item.company_founder}</p>
                      </div>
                    </div>

                    <div className="py-2 px-4 grid grid-cols-1 md:grid-cols-2 ">
                      <div className="p-2">
                        <p className="text-[#A9A9A9]">Established In</p>
                        <p>{item.established_in}</p>
                      </div>
                      <div className="p-2">
                        <p className="text-[#A9A9A9]">Employees</p>
                        <p>{item.company_employee}</p>
                      </div>
                    </div>

                    <div className="py-2 px-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2">
                      <div className="p-2">
                        <p className="text-[#A9A9A9]">Working Sector</p>
                        <p>{item.working_sector}</p>
                      </div>
                      <div className="p-2">
                        <p className="text-[#A9A9A9]">Location</p>
                        <p>{item.address}</p>
                      </div>
                    </div>

                    <div className="py-2 px-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2">
                      <div className="p-2">
                        <p className="text-[#A9A9A9]">Company Description</p>
                        <p>{item.company_description}</p>
                      </div>
                    </div>

                    <div className="py-2 px-4 grid-cols-2">
                      <div className="pl-2">
                        <p className="text-[#A9A9A9]">Company Social Media</p>
                      </div>
                    </div>

                    <div className=" mx-8 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4">
                      <div className="p-2 border rounded-md">
                        <img
                          className="h-7"
                          src={google}
                          style={{ float: "left" }}
                          alt=""
                        />
                        <a
                          href={item.company_url}
                          className="p-3 font-semibold cursor-pointer"
                          style={{ display: "inline" }}
                        >
                          {item.company_url}
                        </a>
                      </div>
                      <div className="p-2 border rounded-md cursor-pointer">
                        <img
                          className="h-7"
                          src={facebook}
                          style={{ float: "left" }}
                          alt=""
                        />
                        <a
                          href={item.facebook_url}
                          className="p-3 font-semibold"
                          style={{ display: "inline" }}
                        >
                          {item.facebook_url}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </CardContent>
          </Card>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default AppliedJobs;