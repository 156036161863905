export function isValidSkills(data) {
  let validNames = true;
  let validRatings = true;

  for (let i = 0; i < data.length; i++) {
    const skill = data[i];
    if (!skill.skills) validNames = false;
    if (!skill.rating) validRatings = false;
  }

  const valid = validNames && validRatings;

  if (valid) return null;
  else return "Skill name and rating can't be empty";
}
